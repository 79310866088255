import {
  useContext,
  useEffect,
  useState,
} from 'react'
import type { SubscriptionDetails, Plan } from 'types'
import {
	createSubscribeSession,
  getMySubscription,
  changeSubscriptionPlan
} from 'services'
import { SubscriptionContext } from 'context'
import { message } from 'antd'
import { SUB_ACTION_TYPE_ENUMS } from 'config'
export const useSubscription = () => {
  const { state, dispatch } = useContext(SubscriptionContext)
  const { sub: mySubPlan, plans: subPlans } = state
  const [selectedOption, setSelectedOption] = useState<'monthly' | 'yearly'>('monthly')
  const [loadingPlan, setLoadingPlan] = useState<string | null>(null)
  const handleOptionChange = (option: 'monthly' | 'yearly') => {
    setSelectedOption(option)
  }
function getUserSubscription() {
      try {
        setTimeout(async () => {
            const data: SubscriptionDetails = await getMySubscription()
        if (data) {
            dispatch({ type: SUB_ACTION_TYPE_ENUMS.EXISTS, sub: data })
        }
          }, 5000)
        
      } catch (error) {
        if (error instanceof Error) {
            void message.error(error.message)
          }else{
            void message.error(
                'Failed to get subscription details'
              )
          }
      }
  }

  const handleSubscribe = async ({
    plan,
    cadence
  }: {
    plan: Plan
    cadence: 'monthly' | 'yearly'
  }) => {
    try {
      setLoadingPlan(plan.name)
      const response = await createSubscribeSession({ plan, cadence })
      if (!response.location) {
        void message.info(response.message)
        getUserSubscription()
      } else {
        window.location = response.location
      }
    } catch (error) {
      console.error('Failed to create subscription session:', error)
      void message.error(
        'Failed to create subscription session. Please try again.'
      )
    } finally {
      setLoadingPlan(null)
    }
  }

  const handleChangePlan= async ({
    plan,
    cadence
  }: {
    plan: Plan
    cadence: 'monthly' | 'yearly'
  }) => {
    try {
      setLoadingPlan(plan.name)
      const response = await changeSubscriptionPlan({ plan, cadence })
        if (!response.location) {
          void message.info(response.message)
          getUserSubscription()
        } else {
          window.location = response.location
        }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      }else{
        void message.error('Error updating subscription')
      }
    } finally {
      setLoadingPlan(null)
    }
  }

//   useEffect(() => {
//     // getUserSubscription()
//     subscriptionPlans()
//   }, [])

  useEffect(() => {
    void getUserSubscription()
  }, [])
 
  return {
    mySubPlan,
    subPlans,
	handleSubscribe,
	loadingPlan,
  selectedOption,
  handleOptionChange,
  handleChangePlan
  }
}
