import { useState } from 'react'
import { notification } from 'antd'
import { storeSalesforceSecrets } from 'services'
import { SalesforceVisibilityState } from 'types'

interface FormState {
  consumerKey: string
  consumerSecret: string
  password: string
  userName: string
}

const useSaveSalesforceKeys = () => {
  const [form, setForm] = useState<FormState>({
    consumerKey: '',
    consumerSecret: '',
    password: '',
    userName: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    userName: false,
    password: false,
    consumerKey: false,
    consumerSecret: false,
  })
  const togglePasswordVisibility = (field: keyof SalesforceVisibilityState) => {
    setIsPasswordVisible(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }))
  }

  const saveSalesforceKeys = async () => {
    if (form.consumerKey === '' || form.consumerSecret === '' || form.password === '' || form.userName === '') {
      notification.error({
        message: 'Missing fields',
        description: 'Provide required fields',
      })
      return
    }

    setIsLoading(true)
    try {
      const response = await storeSalesforceSecrets(form)
      console.log(response)
      notification.info({
        message: '',
        description: response.message,
      })
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Error',
        description: 'An error occurred while saving Salesforce keys',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return { form, setIsPasswordVisible, togglePasswordVisibility,  isPasswordVisible, setForm, isLoading, saveSalesforceKeys }
}

export default useSaveSalesforceKeys
