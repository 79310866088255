import React from 'react'

// Type for the Markdown parsing result
type ParsedElement = {
  type: string;
  content: string | React.ReactNode;
  level?: number; // For headings
};

// Interface for the component props
interface MarkdownViewProps {
  content: string;
}

const MarkdownView: React.FC<MarkdownViewProps> = ({ content }) => {
  // Parsing stage: Identify the Markdown element type
  const parseContent = (text: string): ParsedElement[] => {
    const lines = text.split('\n')

    return lines.map(line => {
      // Detect headings (e.g., ## Heading)
      if (/^#{1,6}\s/.test(line)) {
        const level = line.match(/^#{1,6}/)?.[0].length || 1
        const headingText = line.replace(/^#{1,6}\s/, '')
        return { type: 'heading', content: headingText, level }
      }

      // Detect horizontal rules (---, ***, ___)
      if (/^(\-{3,}|\*{3,}|_{3,})$/.test(line)) {
        return { type: 'horizontalRule', content: '' }
      }

      // Detect blockquotes (e.g., > Quote)
      if (/^>\s/.test(line)) {
        const quoteText = line.replace(/^>\s/, '')
        return { type: 'blockquote', content: quoteText }
      }

      // Detect code blocks (``` code ```)
      if (line.startsWith('```') && line.endsWith('```')) {
        const codeContent = line.slice(3, -3).trim()
        return { type: 'code', content: codeContent }
      }

      // Detect inline code (`code`)
      if (/`[^`]+`/.test(line)) {
        return {
          type: 'inlineCode',
          content: line.split(/(`[^`]+`)/).map((part, index) =>
            part.startsWith('`') && part.endsWith('`') ? (
              <code key={index}>{part.slice(1, -1)}</code>
            ) : (
              part
            )
          ),
        }
      }

      // Detect bold and italic text
      if (/\*\*\*[^*]+\*\*\*/.test(line)) {
        const content = line.replace(/\*\*\*([^*]+)\*\*\*/g, '<strong><em>$1</em></strong>')
        return { type: 'text', content }
      }
      if (/\*\*[^*]+\*\*/.test(line)) {
        const content = line.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>')
        return { type: 'text', content }
      }
      if (/\*[^*]+\*/.test(line)) {
        const content = line.replace(/\*([^*]+)\*/g, '<em>$1</em>')
        return { type: 'text', content }
      }

      // Detect ordered lists (1. Item)
      if (/^\d+\.\s/.test(line)) {
        const listItem = line.replace(/^\d+\.\s/, '')
        return { type: 'orderedList', content: listItem }
      }

      // Detect unordered lists (- or * list item)
      if (/^(\s*[-*]\s+)/.test(line)) {
        const listItem = line.replace(/^[-*]\s+/, '')
        return { type: 'unorderedList', content: listItem }
      }

      // Detect strikethrough (~~text~~)
      if (/~~[^~]+~~/.test(line)) {
        const content = line.replace(/~~([^~]+)~~/g, '<del>$1</del>')
        return { type: 'text', content }
      }

      // Detect links [text](url)
      if (/\[([^\]]+)\]\(([^)]+)\)/.test(line)) {
        const match = line.match(/\[([^\]]+)\]\(([^)]+)\)/)
        if (match) {
          return {
            type: 'link',
            content: (
              <a href={match[2]} target="_blank" rel="noopener noreferrer">
                {match[1]}
              </a>
            ),
          }
        }
      }

      // Detect images ![alt](url)
      if (/!\[([^\]]+)\]\(([^)]+)\)/.test(line)) {
        const match = line.match(/!\[([^\]]+)\]\(([^)]+)\)/)
        if (match) {
          return {
            type: 'image',
            content: <img src={match[2]} alt={match[1]} />
          }
        }
      }

      // Default to plain text
      return { type: 'text', content: line }
    })
  }

  // Transformation stage: Convert parsed elements to React components
  const transformElements = (parsed: ParsedElement[]) => {
    return parsed.map((element, index) => {
      switch (element.type) {
        case 'heading':
          return React.createElement(
            `h${element.level}`,
            { key: index },
            element.content
          )
        case 'horizontalRule':
          return <hr key={index} />
        case 'blockquote':
          return <blockquote key={index}>{element.content}</blockquote>
        case 'code':
          return (
            <pre className="code-block" key={index}>
              <code>{element.content}</code>
            </pre>
          )
        case 'inlineCode':
          return <p key={index}>{element.content}</p>
        case 'unorderedList':
          return (
            <ul key={index}>
              <li>{element.content}</li>
            </ul>
          )
        case 'orderedList':
          return (
            <ol key={index}>
              <li>{element.content}</li>
            </ol>
          )
        case 'link':
        case 'image':
          return <p key={index}>{element.content}</p>
        case 'text':
        default:
          return <p key={index} dangerouslySetInnerHTML={{ __html: String(element.content) }} />
      }
    })
  }

  // Render stage: Combine parsing and transformation
  const parsedElements = parseContent(content)
  const transformedElements = transformElements(parsedElements)

  return <div className="markdown-view">{transformedElements}</div>
}

export default MarkdownView
