import { Modal, Button, Group, Loader } from '@mantine/core'
import { ReactElement } from 'react'
import { primary_color } from 'styles/colors'

interface Modal {
  openModal: boolean
  closeModal: () => void
  title: ReactElement
  content: string
  onConfirm: () => void
  onCancel: () => void
  loadDeleting: boolean
  confirmButtonText: string
}

export default function WarningModal ({
	openModal,
	closeModal,
	title,
	content,
	onConfirm,
	onCancel,
	loadDeleting,
	confirmButtonText
}: Modal) {
	// const [opened, { open, close }] = useDisclosure(false);
	return (
		<>
			<Modal
				// overlayProps={{
				// 	backgroundOpacity: 0.55,
				// 	blur: 2
				// }}
				zIndex={9999}
				opened={openModal}
				onClose={closeModal}
				title={title}
				centered
			>
				{content}

				<Group justify="end" mt={30}>
					<Button onClick={onCancel} variant="outline" color={primary_color}>
            Cancel
					</Button>
					<Button onClick={onConfirm} variant="fill" bg={'red'}>
						{loadDeleting ? <Loader size={'xs'} color='#fff' /> : confirmButtonText}
					</Button>
				</Group>
			</Modal>
		</>
	)
}
