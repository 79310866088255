import React, { useState, useEffect } from 'react'
import { Card, Input, Button, Spin, message } from 'antd'
import { MailOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons'
import logo from 'assets/logo/bibot-favicon-black.png'
import {
	resetPassword, type ResetPasswordOutput,
	confirmResetPassword
} from 'aws-amplify/auth'

const PasswordReset: React.FC = () => {
	const [username, setUsername] = useState<string>('')
	const [confirmationCode, setConfirmationCode] = useState<string>('')
	const [newPassword, setNewPassword] = useState<string>('')
	const [stage, setStage] = useState<number>(0) // 0 = request code, 1 = enter code and new password
	const [loading, setLoading] = useState(false)

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)

	useEffect(() => {
		const handleResize = () => { setWindowWidth(window.innerWidth) }
		window.addEventListener('resize', handleResize)
		return () => { window.removeEventListener('resize', handleResize) }
	}, [])

	const isMobile = windowWidth <= 768

	const responsiveStyles = {
		card: isMobile ? { width: '90%', margin: '50px auto' } : { width: 350, margin: '100px auto' },
		logo: isMobile ? { width: '70%' } : { width: 250 }
	}

	// async function handleResetPassword () {
	//   try {
	//     setLoading(true)
	//     await Auth.forgotPassword(username)
	//     void message.success('Password reset email sent!')
	//     setStage(1)
	//   } catch (error: any) {
	//     console.error(error)
	//     void message.error(error?.message || 'Error sending password reset email.')
	//   } finally {
	//     setLoading(false)
	//   }
	// }

	// async function handleSetNewPassword () {
	//   try {
	//     setLoading(true)
	//     await Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
	//     void message.success('Password changed successfully!')
	//     setStage(0) // Reset state for next use
	//     setUsername('')
	//     setVerificationCode('')
	//     setNewPassword('')
	//     navigate(ROUTES.LOGIN)
	//   } catch (error: any) {
	//     console.error(error)
	//     void message.error(error?.message || 'Error setting new password.')
	//   } finally {
	//     setLoading(false)
	//   }
	// }

	async function handleResetPassword () {
		try {
			setLoading(true)
			setStage(1)
			const output = await resetPassword({ username })
			handleResetPasswordNextSteps(output)
		} finally {
			setLoading(false)
		}
	}

	function handleResetPasswordNextSteps (output: ResetPasswordOutput) {
		const { nextStep } = output
		switch (nextStep.resetPasswordStep) {
		case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
			void message.info(`Confirmation code was sent to ${nextStep.codeDeliveryDetails.deliveryMedium}`)
			// Collect the confirmation code from the user and pass to confirmResetPassword.
			break
		case 'DONE':
			break
		}
	}

	// {
	//   username,
	//   confirmationCode,
	//   newPassword
	// }: ConfirmResetPasswordInput
	async function handleConfirmResetPassword () {
		try {
			setLoading(true)
			setStage(0)
			await confirmResetPassword({ username, confirmationCode, newPassword })
		} finally {
			setLoading(false)
		}
	}

	return (
		<Spin spinning={loading}>
			<Card className='login-container' style={{ ...responsiveStyles.card, textAlign: 'center' }}>
				<div style={{ marginBottom: 50 }}>
					<img src={logo} alt="Logo" style={{ ...responsiveStyles.logo }} />
				</div>
				{stage === 0
					? (
						<>
							<Input
								id='username'
								prefix={<MailOutlined />}
								placeholder="Email"
								style={{ marginBottom: 20 }}
								value={username}
								onChange={e => { setUsername(e.target.value) }}
							/>
							<Button className='button' type="primary" block onClick={handleResetPassword}>
                Reset Password
							</Button>
						</>
					)
					: (
						<>
							<Input
								id='confirmationCode'
								prefix={<KeyOutlined />}
								placeholder="Verification Code"
								style={{ marginBottom: 20 }}
								value={confirmationCode}
								onChange={e => { setConfirmationCode(e.target.value) }}
							/>
							<Input
								id='newPassword'
								prefix={<LockOutlined />}
								type="password"
								placeholder="New Password"
								style={{ marginBottom: 20 }}
								value={newPassword}
								onChange={e => { setNewPassword(e.target.value) }}
							/>
							<Button className='button' type="primary" block onClick={handleConfirmResetPassword}>
                Set New Password
							</Button>
						</>
					)}
			</Card>
		</Spin>
	)
}

export default PasswordReset
