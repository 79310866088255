import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'components/pages/Auth/ProtectedRoute'
import { ROUTES } from 'config'
import PasswordReset from 'components/pages/Auth/PasswordReset'
import { VerifyEmailPage } from 'components/pages/Auth/VerifyEmailPage'
import NotFound from 'components/utils/NotFoundPage'
import ErrorPage from 'components/utils/ErrorPage'
import RegisterPage from 'components/pages/Auth/RegisterPage'
import SubscriptionSessionResult from 'components/pages/Subscription/SubscriptionSessionResult'
import PaymentMethod from 'components/pages/Subscription/PaymentMethodResults'
// import LandingPage from 'components/pages/LandingPage/views/landing-page'
// import Pricing from 'components/pages/LandingPage/components/pricing'
import AdminPortal from 'components/pages/AdminPortal/AdminPortal'
// import Home from 'components/core/landingPage/views/home'

const AppRoutes: React.FC = () => (
	<Routes>
		{/* <Route path={ROUTES.HOME} element={<LandingPage />} /> */}
		{/* <Route path={ROUTES.PRICING} element={<Pricing />} />
		<Route path={ROUTES.DEVELOPER} element={<p>Developer</p>} /> */}
		<Route path={ROUTES.LOGIN} element={<RegisterPage initiatePage='signIn'/>} />
		<Route path={ROUTES.REGISTER} element={<RegisterPage initiatePage='signUp'/>} />
		<Route path={ROUTES.RESET_PASSWORD} element={<PasswordReset />} />
		<Route path={ROUTES.ERROR} element={<ErrorPage />} />
		<Route path={ROUTES.SUBSCRIPTION} element={<SubscriptionSessionResult />} />
		<Route path={ROUTES.PAYMENT_METHOD} element={<PaymentMethod />} />
		<Route
			path={ROUTES.VERIFY_EMAIL}
			element={
				<ProtectedRoute >
					<VerifyEmailPage />
				</ProtectedRoute>
			}
		/>
		<Route path={ROUTES.NOTFOUND} element={<NotFound />} />
		<Route
			path={ROUTES.HOME}
			element={
				<ProtectedRoute >
					<AdminPortal/>
				</ProtectedRoute>
			}
		/>
	</Routes>
)

export { AppRoutes }
