import { ActionIcon, Alert, Button, Input, Loader, Paper, Title, rem } from '@mantine/core'
import { TimeInput } from '@mantine/dates'
import { IconClock, IconEye, IconEyeOff } from '@tabler/icons-react'
import { notification } from 'antd'
import React, { ChangeEvent, useRef, useState } from 'react'
import { storeSlackSecrets } from 'services'
import { primary_color } from 'styles/colors'
import { SlackVisibilityState, slackDataProps } from 'types'

export default function Slack () {
    const refStartTime = useRef<HTMLInputElement>(null)
    const refEndTime = useRef<HTMLInputElement>(null)
    const isProd=process.env.REACT_APP_ENV==='prod'

    const pickerControlStart = (
      <ActionIcon variant="subtle" color="gray" onClick={() => refStartTime.current?.showPicker()}>
        <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    )
  
    const pickerControlEnd = (
      <ActionIcon variant="subtle" color="gray" onClick={() => refEndTime.current?.showPicker()}>
        <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    )
  
  
    const getTime = ()=> {
      const startTime = refStartTime.current?.value || 0
      const endTime = refEndTime.current?.value || 0
  
      if (startTime && endTime) {
        const start = new Date(`1970-01-01T${startTime}:00`)
        const end = new Date(`1970-01-01T${endTime}:00`)
        if (start > end) {
          notification.info({
            message: '',
            description: 'Start time cannot be greater than end time'
          })
          return
        }
      } else {
        notification.info({
          message: '',
          description: 'Please select both start and end times'
        })
      }
    }
    const [isLoading, setIsLoading] = useState(false)
  
    const [form, setForm] = useState<slackDataProps>({
      signingSecret: '',
      botToken: '',
      hook: '',
      channelName: '',
    })
  
    const handleChange = (e: ChangeEvent<HTMLInputElement>)=> {
      e.preventDefault()
      const { name, value } = e.target
      setForm({...form, [name]: value})
    }
  
    const saveSlackKeys = async() => {
     if(form.botToken === '' || form.channelName === '' || form.hook === '' || form.signingSecret === ''){
      notification.error({
        message: 'Missing fields',
        description: 'Provide required fields'
      })
      return
     }
     setIsLoading(true)
     try{
      const response = await storeSlackSecrets(form)
      notification.info({
        message: '',
        description: response.message
      })
      console.log(response)
     }catch(error){
      notification.error({
        message: 'Error',
        description: 'An unexpected error occurred'
      })
     }finally{
     setIsLoading(false)
     }
  
    }
    const [isPasswordVisible, setIsPasswordVisible] = useState({
      signingSecret: false,
      botToken: false,
      hook: false,
      channelName: false,
    })
  
  
    const togglePasswordVisibility = (field: keyof SlackVisibilityState) => {
      setIsPasswordVisible(prevState => ({
        ...prevState,
        [field]: !prevState[field],
      }))
    }
  
    return (
      <Paper style={{ width: '100%', flexDirection: 'column' }} mt={20} p={30} withBorder>
        {isProd ? null : 
        <>
        <Title order={4}>Hours to send requests to customer support (slack)</Title>
      <Alert color={primary_color} mt={10} mb={20}>This is effective from Monday to Friday</Alert>
  
      <div style={{ display: 'flex', justifyContent: 'flex-start'}}>
      <TimeInput m={10} variant='default' label="Select preferred time" ref={refStartTime} leftSection={pickerControlStart} />
      <TimeInput m={10} variant='default' label="Select preferred time" onClick={() => refEndTime.current?.showPicker()} ref={refEndTime} leftSection={pickerControlEnd} />
      </div>
      <Button onClick={()=> getTime()} variant='filled' color={primary_color} m={10}>Save</Button>
        </>}

      
  
  
      <Title mt={20} mb={20} order={4}>Keys</Title>
      <Input type={isPasswordVisible.signingSecret ? 'text' : 'password'} mb={20} placeholder='Signing Secret' value={form.signingSecret} name='signingSecret'  rightSectionPointerEvents="all" onChange={handleChange} rightSection = {
        <button onClick={()=> togglePasswordVisibility('signingSecret')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            {isPasswordVisible.signingSecret ? <IconEye /> : <IconEyeOff />}
          </button>}/>
  
      <Input type={isPasswordVisible.botToken ? 'text' : 'password'} mb={20} placeholder='Bot_Token' value={form.botToken}  name='botToken' onChange={handleChange}  rightSectionPointerEvents="all" rightSection={
        <button onClick={()=> togglePasswordVisibility('botToken')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        {isPasswordVisible.botToken ? <IconEye /> : <IconEyeOff />}    
      </button>
      }/>    
      <Input type={isPasswordVisible.hook ? 'text' : 'password'} mb={20} placeholder='Hook_Url' value={form.hook} name='hook' onChange={handleChange} rightSectionPointerEvents="all" rightSection={
      <button onClick={()=> togglePasswordVisibility('hook')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
      {isPasswordVisible.hook ? <IconEye /> : <IconEyeOff />}    
    </button> }/> 
  
      <Input type={isPasswordVisible.channelName ? 'text' : 'password'} mb={20} placeholder='channel Name' value={form.channelName} name='channelName' onChange={handleChange} rightSectionPointerEvents="all" rightSection={
        <button onClick={()=> togglePasswordVisibility('channelName')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        {isPasswordVisible.channelName ? <IconEye /> : <IconEyeOff />}    
      </button>
      }/>    
      <Button disabled={isLoading} onClick={saveSlackKeys} variant='filled' color={primary_color} >{isLoading ? <Loader size={'sm'} color={primary_color} />: 'Save'}</Button>
  
      </Paper>
    )
  }
