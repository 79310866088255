import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'config/constants'
import { UserContext } from 'context'

interface ProtectedRouteProps {
  children: React.ReactNode
  requireAdmin?: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
	const userContext = useContext(UserContext)
	const userData = userContext?.state
	const isAuthenticated = userData?.isAuthenticated

	if (!isAuthenticated) {
		return <Navigate to={ROUTES.LOGIN} />
	}

	return <>{children}</>
}

export default ProtectedRoute
