import { Code, Paper, Text, Title } from '@mantine/core'
import { secondary_color } from 'styles/colors'
// import  { useContext } from 'react'
// import { UserContext } from 'context'

export default function WordpressIntegration() {

  return (
    <>
      <Paper
        withBorder
        p={20}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Title order={4} mb={20}>Integration Steps for Wordpress</Title>
        <Text>BiBot Chatbot allows you to easily integrate the BiBot AI chatbot into your WordPress site. The plugin is highly customizable, providing a range of settings for seamless integration with your existing WordPress setup.</Text>
          <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 1:</strong> Download BiBot.zip file:</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>Click to download the zip file here: 👉  <a href='https://github.com/chrisdjin/public_wordpress_bibot_plugin' target='_blank' rel='noreferrer' style={{  fontWeight: 'bold'}}>  bibot.zip</a></Text>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 2:</strong> Install the Plugin:</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Title order={5} p={5}>You can install the plugin by either:</Title>
            <ul>
              <li><Text>Uploading the <Code style={{ fontSize: 14}} color={secondary_color} c='#000'>BiBot.zip</Code> file directly via the &quot;Install Plugin&quot; page in the WordPress admin dashboard.</Text></li>
              <li><Text>Or, manually uploading the unzipped <Code style={{ fontSize: 14}} color={secondary_color} c='#000'>BiBot.zip</Code> folder to the <Code style={{ fontSize: 14}} color={secondary_color} c='#000'>/wp-content/plugins/</Code> directory via FTP or your hosting control panel.</Text></li>
            </ul>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 3:</strong> Activate the Plugin:</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>Go to the &ldquo;Plugins&ldquo; menu in WordPress and activate the BiBot Chatbot plugin.</Text>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 4:</strong> Configure the Plugin:</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>Navigate to &ldquo;Settings&ldquo; &gt; &ldquo;BiBot Chatbot&ldquo; in the WordPress admin dashboard to configure the plugin settings according to your needs.</Text>
          </Paper>
        </Paper>
        
       
      </Paper>
    </>
  )
}
