import React, { useEffect, useState } from 'react'
import DashboardCard from 'components/pages/Dashboard/DashboardCard'
import { Paper, Title, Text, Card, Skeleton, Loader } from '@mantine/core'
import {  IconMessages, IconRefresh } from '@tabler/icons-react'
import { getStatistics } from 'services'
import { StatisticsP } from 'types'
import Jira from '../../../assets/icons/jira.png'
import Salesforce from '../../../assets/icons/salesforce.png'
import Slack from '../../../assets/icons/slack.png'
import { LineChart } from '@mantine/charts'


export default function Statistics() {
 const [statsData, setStatsData] = useState<StatisticsP>({ 
  faq_questions: [],
  kb_usage_fail: 0,
  kb_usage_success: 0,
  jira_usage: 0,
  sf_usage: 0,
  mim_usage_success: 0,
  kb_counts: 0,
  chat_usage: {
    percentage: '0',
    used: '0',
    allocated: '0'
   }, 
  kb_usage_history: []
})
  const [loading, setLoading] = useState(false)
  
  useEffect(()=> {
    setLoading(true)
    getStatistics().then((data: StatisticsP)=> { 
      setStatsData(data)
    data.faq_questions.sort((a, b) => parseInt(b.count) - parseInt(a.count)) ///sorting FAQ's for each FAQ with highest count on top
    setLoading(false)
    })
  }, [])
  const { faq_questions, jira_usage, sf_usage, mim_usage_success, kb_usage_history} = statsData

  return (
 <>
    <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: 10, alignItems: 'center'}}>
    <Title order={4}>Dashboard Overview</Title>
     <div style={{ display: 'flex'}}>
      {/* <Text mr={5}>Refresh</Text> */}
      {loading ? (
        <Loader style={{ marginLeft: 10, cursor: 'pointer'}} size={20} color='#000' />
      ) : (
        <IconRefresh 
         style={{ marginLeft: 10, cursor: 'pointer'}}
          onClick={() => {
            setLoading(true)
            getStatistics().then((data: StatisticsP) => {
              setStatsData(data)
              setLoading(false)
            })
          }}
        />
      )}
     </div>
    </div>
    <DashboardCard {...statsData}/>
    <div className='mimCount'>
   <Paper bg={''} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} className='mimCountCard'>
      <Card w={400} radius={10} withBorder p={50} style={{flexGrow: 1, margin: '0 10px'}}>
        <Card.Section>
        <div style={{ display: 'flex', alignItems: 'center'}}>
        <img width={40} src={Slack} style={{ marginRight: 10}} />
          <Title size={26} c="#474747" m={5}>{mim_usage_success}</Title>
        </div>
         
          <Text mt={5} fw={'bold'} >MIM Count</Text>
          <small>Chats forwarded to support team </small>
        </Card.Section>
      </Card>

      <Card w={400} radius={10} withBorder p={50} style={{flexGrow: 1, margin: '0 10px'}}>
        <Card.Section>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <img width={40} src={Jira} style={{ marginRight: 10}} />
          <Title size={26} c="#474747" m={5}>{jira_usage ? jira_usage: 0}</Title>
        </div>
          <Text mt={5}  fw={'bold'} >JIRA  Usage</Text>
          <small>Request forwarded to jira</small>
        </Card.Section>
      </Card>

      <Card w={400} radius={10} withBorder p={50} style={{flexGrow: 1, margin: '0 10px'}}>
        <Card.Section>
        <div style={{ display: 'flex', alignItems: 'center'}}>
        <img width={45} src={Salesforce} style={{ marginRight: 10}} />
          <Title size={26}  c="#474747" m={5}>{sf_usage ? sf_usage: 0}</Title>
        </div>
          <Text mt={5} fw={'bold'} >Salesforce  Usage  </Text>
          <small>Salesforce usage count</small>
        </Card.Section>
      </Card>

     
    </Paper>
  </div>

  <div className='FAQlist'>
  <Paper withBorder className='FAQListCard' w={'100%'}>
  <LineChart
   withLegend
   yAxisLabel='Count'
   xAxisLabel='Date'
      h={350}
      data={kb_usage_history ? kb_usage_history : []}
      series={[{ name: 'success', label: 'Success', color: 'green.6' }, 
        { name: 'failed', label: 'Failed', color: 'red.4' }
      ]}
      dataKey="date"
      strokeWidth={3}
      curveType="monotone"
      // yAxisProps={{ domain: [-25, 40] }}
      valueFormatter={(value) => `${value}`}
    />
  </Paper>
  </div>

  <div className='FAQlist'>
     <Paper className='FAQListCard' w={'100%'}>
       <Title order={3} m={20}> <IconMessages size={30}/>&nbsp; Frequently Asked Questions (FAQ) </Title>
       {loading ? <>
        <Skeleton height={24} radius="sm" />
        <Skeleton height={24} mt={8} radius="sm" />
        <Skeleton height={24} mt={8} width="70%" radius="sm" />
       </> : faq_questions.map((question, index)=> {
            return (
                <Paper bg={'#ededed'} m={10} h={40} key={index} style={{ display: 'flex', alignItems: 'center'}}>
                    <Paper bg={question.count <= '50%'? '#f4e1b3' : '#c9eccf'} p={10}  h={40} w={question.count} style={{ display: 'flex', alignItems: 'center',}}>
                        </Paper>
                      <Text style={{ position: 'absolute', display: 'flex', marginLeft: 20}}> <Text fw={'bold'}> {question.count}</Text> <Text style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} ml={30}>{question.question} </Text> </Text>  
                    </Paper>
            )
          })}

         
         </Paper>
    </div>
 </>

  )
}