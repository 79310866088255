import React, { useEffect, useState } from 'react'
import {
	Title,
	Paper,
	Input,
	Text,
	Avatar,
	Space,
	ColorPicker,
	Button,
	Indicator,
	Alert,
	Card,
	Switch
} from '@mantine/core'
import '../../../styles/appConfig.css'
import { type AppconfigType } from 'types'
import { chat_Bubble_Banner, get_ChatBubble_Config, saveConfigData } from 'services'
import Spinner from 'components/utils/spinner'
import { IconAlertCircle } from '@tabler/icons-react'
import { notification } from 'antd'
import botDefault from '../../../assets/gen/bot.avif'
import { primary_color } from 'styles/colors'
import { SendMessageIcon } from 'components/icon/sendMessage'

export default function AppSettings () {
	const [color, setColor] = useState<string>('#00152a')
	const [windowTitle, setWindowTitle] = useState<string>('')
	const [isLoading, setIsLoading] = useState(false)
	const [botImage, setBotImage] = useState<string>(botDefault)
	const [file, setFile] = useState<File | null>(null)
	const [tier, setTier] = useState('')

	const [checked, setChecked] = useState(true)

	const appConfigData: AppconfigType = {
		appTitle: windowTitle,
		file_name: file?.name,
		windowColor: color
	}
	
	const handleImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const file = e.target.files && e.target.files[0]
		if (file) {
			const url = URL.createObjectURL(file)
			setBotImage(url)
			setFile(file)
		}
	}

	const saveChatBubbleConfig = async () => {
		if (!appConfigData.appTitle || !appConfigData.file_name || !appConfigData.windowColor) {
			notification.error({
				message: '',
				description: 'Please fill all the fields (Image, Title & Color)'
			})
			return
		}
		setIsLoading(true)
		await saveConfigData(appConfigData, file).then((res) => {
			notification.info({
				message: '',
				description: res
			})
			setIsLoading(false)
		}).catch((error) => {
			notification.info({
				message: '',
				description: error
			})
			setIsLoading(false)
		})
	}

	const getChatBubbleConfig = async () => {
		try{
			const res = await get_ChatBubble_Config()
			if(res){
				setColor(res?.color)
				setWindowTitle(res.title)
				setBotImage(res.logo_url)
				setTier(res.tier)
				setChecked(res.powered_by_bibot)
			}
		}catch(err){
			notification.info({
				message: '',
				description: 'Error'
			})
		}
	}

	const handleToggleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.currentTarget.checked)
		const toggle = await chat_Bubble_Banner(e.currentTarget.checked)
		notification.info({
			message: toggle.message,
			description: 'Powered by Bibot banner'
		})
	}

   useEffect(()=> {
	getChatBubbleConfig()
   }, [])


	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'flex-start',
				flexDirection: 'column',
				alignItems: 'flex-start'
			}}
		>
			<Title order={3}>Bibot Chat Window </Title>
			<Alert mt={5} icon={<IconAlertCircle size={16} />} color={primary_color}> Configure bibot&apos;s chat window to your preference
			</Alert>
			<Paper mt={20} p={40} withBorder style={{ width: '100%' }}>
				<div className="mainContent">
					<div className="firstSection">
						<Title order={5}>Select your chatbot image</Title>
						<label htmlFor="file-upload" className="inputfileButton">
							<Avatar
								itemRef="file-upload"
								style={{ border: '4px solid #dedede' }}
								src={botImage}
								size={100}
								mb={10}
							/>
						</label>
						<input
							id="file-upload"
							type="file"
							accept="image/png, image/jpg, image/gif, image/jpeg"
							onChange={handleImage}
						/>
						<Space h={10} />
						<Title order={5}>Chatbot name</Title>
						<Input
							placeholder="Provide your chatbot name"
							onChange={(e) => { setWindowTitle(e.target.value) }}
							value={windowTitle}
							name="windowTitle"
						/>
						<Title order={4} mb={10} mt={30}>
							Select preferred color for Bibot window
						</Title>
						<ColorPicker
							format="rgba"
							value={color}
							onChange={setColor}
							style={{ width: '100%' }}
						/>
					<Button
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							disabled={isLoading}
							onClick={async () => saveChatBubbleConfig()}
							mt={50}
							mb={50}
							variant="filled"
							color={primary_color}
						>
							{isLoading ? <Spinner /> : 'Save configuration'}
						</Button>

						{/* powered by BiBot watermark toggle */}
						<Switch
						checked={checked}
						disabled={tier !== 'Free' || tier !== null ? false : true}
						onChange={(e) => handleToggleChange(e)}
						color={primary_color}
						label="Powered by BiBot watermark"
						style={{ cursor: 'pointer', marginBottom: 40}}
						/>
						

					</div>
					
					<div className="previewSection">
					<Title order={4}>Chat Window Preview</Title>
						<Card mt={10} shadow="sm" padding="" radius="md" withBorder>
							<div
								className="ChatBotHeader"
								style={{
									backgroundColor: color,
									display: 'flex',
									alignItems: 'center',
									paddingLeft: '30px',
									height: '75px',
									borderTopLeftRadius: '6px',
									borderTopRightRadius: '6px'
								}}
							>
								<Indicator
									inline
									size={12}
									offset={7}
									position="bottom-end"
									color="green"
								>
									<Avatar size={55} color="white" src={botImage} alt="profile" />
								</Indicator>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										justifyContent: 'flex-start',
										textAlign: 'left',
										color: '#fff',
										paddingLeft: '10px',
										width: '100%'
									}}
								>
									<Title order={4} style={{ color: '#fff' }}>
										{!windowTitle ? 'Title' : windowTitle}
									</Title>
								<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
								<Text style={{ fontSize: '12px', lineHeight: '0.5' }}>
										Online
									</Text>
									<Text style={{ fontSize: '6px',  paddingRight: 10 }}>{!windowTitle ? 'Bot' : windowTitle} can make mistake </Text>
								</div>
								</div>
							</div>
							
							<div
								className="content"
								style={{ backgroundColor: '#fff', height: '440px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
							>
								<div
									style={{
										marginInline: '20px',
										paddingTop: '20px',
										display: 'flex',
										flexDirection: 'column'
									}}
								>
									<div style={{ display: 'flex', alignItems: 'center'}}>
									<Avatar size={30} color="white" src={botImage} alt="profile" />
									<small style={{ marginLeft: 5, color: '#000' }}>
										{!windowTitle ? 'Title' : windowTitle}
									</small>
									</div>

									<div
										className="message"
										style={{
											backgroundColor: '#dedede',
											textAlign: 'left',
											marginTop: '5px',
											padding: 10,
											width:  250,
											borderTopRightRadius: 20,
											borderBottomRightRadius: 20,
											borderBottomLeftRadius: 20,
											marginLeft: 20,
											fontSize: 14
										}}
									>
										Heya!! I need a name, color and a logo
									</div>
									
								</div>

			<div>

			<div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderTop: '1px solid #d7d7d7',
              padding: 10,
              // borderBottomLeftRadius: 10,
              // borderBottomRightRadius:10,
              backgroundColor: '#fff'
            }}
          >
            <input
              style={{
                outline: 'none',
                flexGrow: 1,
                border: 'none',
                backgroundColor: 'transparent',
                padding: '5px 10px',
                marginRight: 10,
                fontSize: 14
              }}
              autoFocus={true}
              type='text'
              value={''}
              onChange={()=> {}}
              onKeyDown={()=> {}}
              placeholder='Type your message here'
            />
            <button
              disabled={isLoading ?? true}
              style={{
                backgroundColor: 'transparent',
                color: '#dedede',
                border: 'none',
                cursor: 'pointer'
              }}
              onClick={() => {}}
            >
              <SendMessageIcon
                color={color}
              />
            </button>
          </div>
								
								{checked && <div style={{ backgroundColor: '#f9f9f9', textAlign: 'center'}}>
										<small style={{ fontSize: 10, color: '#9ba6b2'}}>Powered by <span style={{ color: '#1f4760', fontWeight: 'bold'}}>BIBOT</span></small>
										</div>}
			</div>
								
							</div>
						</Card>
					</div>
				</div>
				{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				
				</div> */}
			</Paper>
		</div>
	)
}
