import {  Paper, Text, Title } from '@mantine/core'
import React, { useState }  from 'react'
import { primary_color } from 'styles/colors'
import Jira from './Jira'
import SalesForce from './Salesforce'
import Slack from './Slack'

export default function Settings() {
  const [currentTab, setCurrentTab]= useState('Slack')

  const tabs = [
    {title: 'Slack'},
    {title: 'Salesforce'},
    {title: 'Jira'}
  ]

  const renderComponent = () => {
    switch (currentTab) {
      case 'Slack':
        return <Slack />
      case 'Salesforce':
        return <SalesForce />
      case 'Jira':
       return <Jira />
      
      default:
      return <Slack />
    }
  }

  return (
   <>
     <Title order={4}>Third-Party Integration</Title>
        <Paper withBorder style={{ width: '100%', display: 'flex', height:'100%', alignItems: 'center', borderRadius: 8, justifyContent: 'space-between'}} pl={10} pr={10} bg={'transparent'} p={5} mt={10}>
       <div style={{ display: 'flex'}}>
       {tabs.map((item, key)=> {
            return (
              <Text key={key} style={{ cursor: 'pointer', color: currentTab === item.title? primary_color : '#bababa' }} onClick={()=> setCurrentTab(`${item.title}`)} fw={'bold'} m={10}>{item.title}</Text>
            )
          })}
       </div>
        </Paper>

   {renderComponent()}
   </>
  )
}


