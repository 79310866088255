import { useState } from 'react'
import { Code, Paper, Text, Title, TextInput, Button } from '@mantine/core'
import { primary_color, secondary_color } from 'styles/colors'
import { storeQWhatsAppSecrets } from 'services'
import { message } from 'antd'
import { EnhancedEncryptionOutlined, ContentCopy } from '@mui/icons-material'
import { IconEye, IconEyeOff } from '@tabler/icons-react'

export default function WhatsAppIntegration() {
  const [appSecret, setAppSecret] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [phoneNumberId, setPhoneNumberId] = useState('')
  const [loading, setLoading] = useState(false)
  const [isAppSecretVisible, setIsAppSecretVisible] = useState(false)
  const [isAccessTokenVisible, setIsAccessTokenVisible] = useState(false)
  const [isPhoneIdNumber, setIsPhoneIdNumber] = useState(false)

  const webhookUrl = 'https://inference.bibot.app/v0/inference/q-whatsapp'
  const verificationToken = 'BIBOT'

  const handleSave = async () => {
    setLoading(true)
    if (!appSecret) {
      message.error('Please enter the WhatsApp App Secret')
      setLoading(false)
      return
    }
    if (!accessToken) {
      message.error('Please enter the Access Token')
      setLoading(false)
      return
    }
    if (!phoneNumberId) {
      message.error('Please enter the Phone Number ID')
      setLoading(false)
      return
    }
    const payload = {
      app_secret: appSecret,
      access_token: accessToken,
      phone_number_id: phoneNumberId,
    }

    try {
      console.log('Saving WhatsApp Integration:', payload)
      const res = await storeQWhatsAppSecrets(payload)
      message.info(res)
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      } else {
        message.error('Error saving WhatsApp credentials, please try again later.')
      }
    } finally {
      setLoading(false)
    }
  }

  const copyWebhookInfo = () => {
    const webhookInfo = `Webhook URL: ${webhookUrl}\nVerification Token: ${verificationToken}`
    navigator.clipboard.writeText(webhookInfo)
    message.success('Webhook information copied to clipboard!')
  }

  return (
    <>
      <Paper
        withBorder
        p={20}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Title order={4} mb={20}>WhatsApp Integration</Title>
        <Text>Follow the steps below to integrate <strong>BiBot</strong> into <strong>WhatsApp</strong> and retrieve the necessary credentials.</Text>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 1:</strong> Set Up a WhatsApp Business Account</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>1. Go to the <a href="https://business.facebook.com" target="_blank" rel="noreferrer" style={{ fontWeight: 'bold' }}>Meta Business Suite</a> and log in with your Facebook account.</Text>
            <Text>2. In the Business Settings, create a new business account if you don&apos;t have one already.</Text>
            <Text>3. In Business Settings, navigate to Accounts &gt; WhatsApp Accounts and click Add to create a new WhatsApp Business Account.</Text>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 2:</strong> Create a WhatsApp App in Meta for Developers</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>1. Go to the <a href="https://developers.facebook.com/apps" target="_blank" rel="noreferrer" style={{ fontWeight: 'bold' }}>Meta for Developers</a> Dashboard and click &quot;Create App&quot;.</Text>
            <Text>2. Choose the &quot;Business&quot; app type and click &quot;Next&quot;.</Text>
            <Text>3. Provide an App Name, Contact Email, and select the Business Account you created earlier. Click &quot;Create App&quot;.</Text>
            <Text>4. In the app dashboard, add the WhatsApp product to your app.</Text>
            <Text>5. In the app dashboard, go to App Settings &gt; Basic Settings and copy the &quot;App Secret&quot;. <strong>Paste this in step 6 below</strong></Text>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 3:</strong> Generate a System User and Access Token</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>1. In Business Settings, go to Users &gt; System Users and click &quot;Add&quot; to create a new system user with the role of Admin.</Text>
            <Text>2. Assign the WhatsApp Business Account to the system user with full control.</Text>
            <Text>3. Generate a new token for the system user and select the following permissions: WhatsApp Business Management and WhatsApp Business Messaging. <strong>Paste this in step 6 below</strong></Text>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 4:</strong> Register a Phone Number</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>1. In the WhatsApp section of the app dashboard, go to &quot;Getting Started&quot;.</Text>
            <Text>2. Add and verify a phone number for the WhatsApp Business Account.</Text>
            <Text>3. After verification, retrieve the Phone Number ID from the WhatsApp configuration. <strong>Paste this in step 6 below</strong></Text>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 5:</strong> Configure the Webhook</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>1. In the app dashboard, go to WhatsApp &gt; Configuration and configure the webhook.</Text>
            <Text>2. Set the Callback URL to <Code style={{ fontSize: 14 }} color={secondary_color} c='#000'>{webhookUrl}</Code> and the Verification Token to <Code style={{ fontSize: 14 }} color={secondary_color} c='#000'>{verificationToken}</Code>.</Text>
            <Button
              onClick={copyWebhookInfo}
              color={primary_color}
              style={{
                marginTop: '10px'
              }}
              leftSection={<ContentCopy fontSize="small" />}
            >
              Copy Webhook Info
            </Button>
          </Paper>
        </Paper>

        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', display: 'flex', color: '#000', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 6:</strong> Enter WhatsApp Credentials</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>Use the fields below to input your <strong>App Secret</strong>, <strong>Access Token</strong>, and <strong>Phone Number ID</strong>.</Text>

            <TextInput
              label="App Secret"
              placeholder="Enter your WhatsApp App Secret"
              value={appSecret}
              type={isAppSecretVisible ? 'text' : 'password'}
              onChange={(e) => setAppSecret(e.target.value)}
              rightSection={
                <button onClick={() => setIsAppSecretVisible(!isAppSecretVisible)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {isAppSecretVisible ? <IconEye /> : <IconEyeOff />}
                </button>
              }
              mt={10}
              mb={10}
              required
            />
            <TextInput
              label="Access Token"
              placeholder="Enter your Access Token"
              value={accessToken}
              type={isAccessTokenVisible ? 'text': 'password'}
              onChange={(e) => setAccessToken(e.target.value)}
              mt={10}
              mb={10}
              required
              rightSection={
                <button onClick={() => setIsAccessTokenVisible(!isAccessTokenVisible)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {isAccessTokenVisible ? <IconEye /> : <IconEyeOff />}
                </button>
              }
            />
            <TextInput
              label="Phone Number ID"
              placeholder="Enter your Phone Number ID"
              value={phoneNumberId}
              type={isPhoneIdNumber ? 'text' : 'password'}
              onChange={(e) => setPhoneNumberId(e.target.value)}
              rightSection={
                <button onClick={() => setIsPhoneIdNumber(!isPhoneIdNumber)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {isPhoneIdNumber ? <IconEye /> : <IconEyeOff />}
                </button>
              }
              mt={10}
              mb={10}
              required
            />

            <Button
              onClick={handleSave}
              color={primary_color}
              mt={20}
              loading={loading}
              disabled={loading}
              leftSection={<EnhancedEncryptionOutlined fontSize="small" />}
            >
              Save Integration
            </Button>
          </Paper>
        </Paper>
      </Paper>
    </>
  )
}
