import { IconPlus, IconMessages, IconChevronsRight } from '@tabler/icons-react'
import {
  Paper,
  Accordion,
  Title,
  Text,
  Box,
  Tooltip,
  Loader,
  Skeleton,
  Button,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { getChatHistory, getChatSessions } from 'services'
import {
  chatDataProps,
  chatHistoryHeaderType,
  chatHistoryParamType,
} from 'types'
import logo from 'assets/logo/bibot-high-resolution-logo-black-transparent.png'
import { IconUserCircle } from '@tabler/icons-react'
import { primary_color } from 'styles/colors'
import MarkdownView from './MarkdownView'
export default function ChatHistory() {
  const [chatHistoryData, setChatHistoryData] = useState<
    chatDataProps | undefined
  >()
  const [chats, setChats] = useState<chatDataProps | undefined>()
  const [chatLoading, setChatLoading] = useState(false)
  const [nextChatLoading, setNextChatLoading] = useState(false)

  // Function to format the timestamp
  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp)
    return (
      date.toLocaleDateString() +
      ' ' +
      date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    )
  }

  const loadChats = async (
    params: chatHistoryParamType,
    header: chatHistoryHeaderType
  ) => {
    setChatLoading(true)
    setChats({
      chat_history: [],
      next_token: '',
      session_id: '',
    })
    try {
      const data = await getChatHistory(params, header)
      setChats(data)
      setChatLoading(false)
    } catch (error) {
      console.error('Failed to load chat history', error)
      setChatLoading(false)
    }
  }

  const nextChat = async (param: chatHistoryParamType) => {
    if (!chats?.next_token) return
    setNextChatLoading(true)
    try {
      const response = await getChatHistory(param, {
        'X-Continuation-Token': chats.next_token,
      })
      // Update state with new chat history
      setChats((prev) => {
        if (!prev) return response // If previous state is undefined, use the new response
        return {
          ...prev,
          chat_history: [
            ...(prev.chat_history || []),
            ...(response.chat_history || []),
          ],
          next_token: response.next_token,
        }
      })
      setNextChatLoading(false)
    } catch (err) {
      console.error('Failed to fetch more chat history', err)
      setNextChatLoading(false)
    }
  }

  const isUUIDv4 = (id: string) => {
    const uuidV4Regex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    return uuidV4Regex.test(id)
  }

  const items = chatHistoryData
    ? Object.keys(chatHistoryData).map((sessionId) => (
        <Accordion.Item key={sessionId} value={sessionId}>
          <Accordion.Control
            onClick={() =>
              loadChats(
                { session_id: sessionId },
                { 'X-Continuation-Token': '' }
              )
            }
          >
            <strong>
              {isUUIDv4(sessionId) ? 'User Chat Session' : sessionId}
            </strong>
          </Accordion.Control>
          <Accordion.Panel>
            {chatLoading ? (
              <Text mt={30} style={{ display: 'flex', alignItems: 'center' }}>
                <Loader size={'sm'} color="#C8C8C8" mr={10} /> Please wait...
              </Text>
            ) : (
              (chats?.chat_history || []).map((entry, index) => (
                <div key={index} style={{ marginBottom: '1em' }}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Text
                      style={{
                        marginRight: '10px',
                        marginBottom: '0.5em',
                        backgroundColor: '#C8C8C8',
                        color: 'black',
                        borderRadius: '10px',
                        padding: '10px',
                        maxWidth: '70%',
                        alignSelf: 'flex-start',
                      }}
                    >
                      {entry.inquiry}
                    </Text>
                    <IconUserCircle stroke={1} size={40} color="#5E599A" />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '0.5em',
                    }}
                  >
                    <img src={logo} style={{ width: '45px', height: '45px' }} />
                    <Box
                      style={{
                        marginLeft: '10px',
                        maxWidth: '70%',
                        alignSelf: 'flex-end',
                        position: 'relative',
                      }}
                    >
                      <Text
                        style={{
                          backgroundColor: '#F0F0F0',
                          borderRadius: '10px',
                          padding: '10px',
                        }}
                      >
                        <MarkdownView content={entry.response} />
                       
                      </Text>
                      {entry.mim && (
                        <Tooltip
                          label="Answered by your Customer Support"
                          position="top"
                          withArrow
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: '0px',
                              right: '0px',
                              width: '10px',
                              height: '10px',
                              backgroundColor: 'red',
                              borderRadius: '50%',
                            }}
                          ></div>
                        </Tooltip>
                      )}
                      <Text
                        style={{
                          fontSize: '0.8em',
                          color: '#999',
                          marginTop: '5px',
                        }}
                      >
                        {formatTimestamp(`${entry.timestamp}`)}
                      </Text>
                    </Box>
                  </Box>
                </div>
              ))
            )}
            {nextChatLoading ? <Skeleton h={45} m={25} /> : null}
            {chats?.next_token ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                }}
              >
                <Button
                  variant="light"
                  c={primary_color}
                  disabled={nextChatLoading}
                  style={{ display: 'flex' }}
                  onClick={() => nextChat({ session_id: sessionId })}
                >
                  <Text style={{ fontWeight: 'bold' }}>Next</Text>{' '}
                  <IconChevronsRight />
                </Button>
              </div>
            ) : null}
          </Accordion.Panel>
        </Accordion.Item>
      ))
    : null

  useEffect(() => {
    getChatSessions().then((data: chatDataProps) => {
      setChatHistoryData(data)
    })
  }, [])

  // Return the Accordion component with the items
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Paper w={'100%'} m={10} p={20}>
        <Title order={3} m={20}>
          <IconMessages size={30} />
          &nbsp; Chat History
        </Title>
        <Accordion
          defaultValue=""
          chevron={<IconPlus size={16} />}
          chevronPosition="right"
        >
          {items || (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Loader ml={50} mr={10} size={'sm'} color="#C8C8C8" /> Please
              wait...
            </div>
          )}
        </Accordion>
      </Paper>
    </div>
  )
}
