export enum statusCode {
  ok = 200
  , created = 201
  , accepted = 202

  // 3xx codes
  , unmodified = 304

  // 4xx Client Error
  , sucMsg = 'success'
  , badReq = 400
  , unauthorized = 401
  , paymentReq = 402
  , forbidden = 403
  , notFound = 404
  , methodNotAllowed = 405
  , notAcceptable = 406
  , pxyAuthReq = 407
  , reqTimeOut = 408
  , conflict = 409
  , gone = 410
  , lengthReq = 411
  , preConFailed = 412
  , payloadTooLarge = 413
  , urlTooLong = 414
  , unsupportedMediaType = 415
  , rangeNotSatisfiable = 416
  , expectationFailed = 417

  , misdirectedReq = 421
  , unprocessableEntity = 422
  , locked = 423
  , failedDependency = 424
  , tooLarge = 425
  , upgradeRequired = 426
  , preconReq = 428
  , tooManyReq = 429
  , reqHeaderTooLarge = 431
  , unavailableForLegalReasons = 451

  // 5xx Server Error
  , internalServerError = 500
  , notImplemented = 501
  , badGateway = 502
  , svcUnavalable = 503
  , gtwayTimeout = 504
  , httpVNotSupported = 505
  , networkAuthReq = 511
}
