import { Button, Paper, Title } from '@mantine/core'
import { useLocation, useNavigate } from 'react-router-dom'
import Success from '../../../assets/lottie/success.json'
import Lottie from 'lottie-react'
import { primary_color } from 'styles/colors'
import { IconCircleX, IconCreditCard } from '@tabler/icons-react'
import { ROUTES } from 'config'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function PaymentMethod() {
  const query = useQuery()
  const success = query.get('success')
  const cancel = query.get('canceled')
  const navigate = useNavigate()
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
     <Paper w={'80%'} h={'70%'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
      {success === 'true' ? <>
      <Lottie animationData={Success} loop={false} style={{width: 250, height: 250}}/>
      <Title order={2}> Payment Method Added Successful </Title>
      <Title order={2} style={{ fontWeight: 'normal'}}>Check your inbox for the receipt of your payment. Thank you</Title>
      <Button
      onClick={()=> navigate(ROUTES.HOME, {replace: true})}
      variant="outline"
      color={primary_color}
       mt={40}>Done</Button>
      </> :
      success === 'false' ?
      <>
      <IconCircleX size={100} color='red' style={{marginBottom: 20}}/>
      <Title order={3}>Payment method unsuccessful. Please try again</Title>
      <Button
      onClick={()=> navigate(ROUTES.HOME, {replace: true})}
      variant="outline"
      color={primary_color}
      mt={40}>Back to Home</Button>
      </> 
      : 
      cancel === 'true' ? 
      <>
      <IconCreditCard size={100} color='red'style={{ marginBottom: 20}} />
       <Title order={3}>You canceled payment method</Title>
      <Button
      onClick={()=> navigate(ROUTES.HOME, {replace: true})}
      variant="outline"
      color={primary_color}
       mt={40}>Back to Home</Button>
      </>
      :
      <>
         <Title order={2}>Subscription status unknown</Title>
      <Button
      onClick={()=> navigate(ROUTES.HOME, {replace: true})}
      variant="outline"
      color={primary_color}
       mt={40}>Done</Button>
      </>
   
      }
     </Paper>
    </div>
  )
}
