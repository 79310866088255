import { Notification } from '@mantine/core'
import { type ReactElement } from 'react'
import { primary_color } from 'styles/colors'

interface notifyType{
	visible: boolean, content: string, loading?: boolean, title: string, icon?: ReactElement  
}

export default function Notify ({ visible, content, loading, title, icon }: notifyType) {
	return (
		<div style={{ position: 'relative', display: visible ? 'flex' : 'none', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
			<Notification withCloseButton={false} icon={icon} style={{ position: 'fixed' }} loading={loading} color={primary_color} title={title}>
				{content}
			</Notification>
		</div>

	)
}
