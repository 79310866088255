import React from 'react'
import {
  Table,
  Checkbox,
  Button,
  Modal,
  TextInput,
  Group,
  ActionIcon,
  Paper,
  Skeleton,
  Loader,
  Badge
} from '@mantine/core'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { useTeams } from 'hooks'
import { primary_color, secondary_color } from 'styles/colors'

const UserManagementPage: React.FC = () => {
  const {
    handleOpenModal,
    selectedUsers,
    classes,
    modalType,
    handleCloseModal,
    formValues,
    handleInputChange,
    handleCreateUpdateUser,
    handleDeleteUser,
    handleDeactivateUser,
    handleActivateUser,
    handleSelectUser,
    users,
    cx,
    isLoading,
    isProcessing
  } = useTeams()
  
  const userRows = users.map((user) => (
    <tr key={user.id} className={cx({ [classes.selectedRow]: selectedUsers.includes(user.id) })}>
      <td className={classes.td}>
        <Checkbox checked={selectedUsers.includes(user.id)} onChange={() => handleSelectUser(user.id)} />
      </td>
      <td className={classes.td}>{user.given_name}</td>
      <td className={classes.td}>{user.family_name}</td>
      <td className={classes.td}>{user.email}</td>
      <td className={classes.td}>{user.Active ? <Badge size='sm' color='green'>Active</Badge>: <Badge color='red'>Inactive</Badge>}</td>
      <td className={classes.td}>{user.Confirmed ? <Badge size='sm' color='green'>Confirmed</Badge>: <Badge color='red'>Not confirmed</Badge>}</td>
      <td className={cx(classes.td, classes.actions)}>
        <Group>
          <ActionIcon style={{ backgroundColor: primary_color }} onClick={() => handleOpenModal('update', user)}>
            <IconEdit size={16} />
          </ActionIcon>
          <ActionIcon color='red' onClick={() => handleOpenModal('delete', user)}>
            <IconTrash size={16} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ))

  return (
    <Paper style={{ width: '100%' }} p={30} withBorder>
      <Group mb="md">
        <Button color={primary_color} onClick={() => handleOpenModal('create')}>Create User</Button>
        <Button
          color="red"
          onClick={() => handleOpenModal('delete')}
          disabled={!selectedUsers.length }
        >
          Delete Selected Users
        </Button>
        <Button
          color={primary_color}
          onClick={() => handleOpenModal('deactivate')}
          disabled={!selectedUsers.length}
        >
          Deactivate Selected Users
        </Button>
        <Button
          color={primary_color}
          onClick={() => handleOpenModal('activate')}
          disabled={!selectedUsers.length}
        >
          Activate Selected Users
        </Button>
      </Group>
      
      <Table style={{ marginTop: 40 }} striped highlightOnHover>
        <thead style={{ backgroundColor: secondary_color, color: '#000', textAlign: 'left', height: 45, left: 20 }}>
          <tr>
            <th>&nbsp;&nbsp;&nbsp;&nbsp;Select</th>
            <th>Given Name</th>
            <th>Family Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Invitation</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            // Skeleton Loader when data is loading
            <>
              <tr>
                <td colSpan={8}>
                  <Skeleton style={{marginTop: 20}} height={40} />
                  <Skeleton height={40} mt="xs" />
                  <Skeleton height={40} mt="xs" />
                  <Skeleton height={40} mt="xs" />
                  <Skeleton height={40} mt="xs" />
                </td>
              </tr>
            </>
          ) : (
            userRows
          )}
        </tbody>
      </Table>

      <Modal
        zIndex={9999}
        opened={modalType === 'create'}
        onClose={handleCloseModal}
        title={`${modalType === 'create' ? 'Create' : 'Update'} User`}
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <TextInput
          label="Given Name"
          value={formValues.given_name}
          onChange={handleInputChange('given_name')}
          required
        />
        <TextInput
          label="Family Name"
          value={formValues.family_name}
          onChange={handleInputChange('family_name')}
          required
        />
        <TextInput
          label="Email"
          value={formValues.email}
          onChange={handleInputChange('email')}
          required
        />
        <Group mt="md">
          <Button color={primary_color} onClick={handleCreateUpdateUser}>
            {modalType === 'create' ? (isProcessing ?  <Loader color='#fff' size={18} /> : 'Create') : (isProcessing ? <Loader color='#fff' size={18} /> : 'Update')}
          </Button>
        </Group>
      </Modal>

      <Modal
        zIndex={9999}
        opened={modalType === 'update'}
        onClose={handleCloseModal}
        title={`${modalType === 'create' ? 'Create' : 'Update'} User`}
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <TextInput
          label="Given Name"
          value={formValues.given_name}
          onChange={handleInputChange('given_name')}
          required
        />
        <TextInput
          label="Family Name"
          value={formValues.family_name}
          onChange={handleInputChange('family_name')}
          required
        />
        <Group mt="md">
          <Button color={primary_color} onClick={handleCreateUpdateUser}>
            {modalType === 'create' ? (isProcessing ?  <Loader color='#fff' size={18} /> : 'Create') : (isProcessing ? <Loader color='#fff' size={18} /> : 'Update')}
          </Button>
        </Group>
      </Modal>

      <Modal
        zIndex={9999}
        opened={modalType === 'delete'}
        onClose={handleCloseModal}
        title="Delete User"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div>Are you sure you want to delete the selected users?</div>
        <Group mt="md" justify='end'>
          <Button color={primary_color} variant='outline' onClick={handleCloseModal}>Cancel</Button>
          <Button color="red" onClick={handleDeleteUser}>
           {isProcessing ? <Loader color='#fff' size={18} /> : 'Delete'} 
          </Button>
        </Group>
      </Modal>

      <Modal
        zIndex={9999}
        opened={modalType === 'deactivate'}
        onClose={handleCloseModal}
        title="Deactivate User"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div>Are you sure you want to deactivate the selected users?</div>
        <Group mt="md" justify='end'>
          <Button color={primary_color} variant='outline' onClick={handleCloseModal}>Cancel</Button>
          <Button color={primary_color} onClick={handleDeactivateUser}> {isProcessing ?  <Loader color='#fff' size={18} /> : 'Deactivate'}</Button>
        </Group>
      </Modal>

      <Modal
        zIndex={9999}
        opened={modalType === 'activate'}
        onClose={handleCloseModal}
        title="Activate User"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div>Are you sure you want to activate the selected users?</div>
        <Group mt="md" justify='end'>
          <Button color={primary_color} variant='outline' onClick={handleCloseModal}>Cancel</Button>
          <Button color={primary_color} onClick={handleActivateUser}>{isProcessing ? <Loader color='#fff' size={18} /> : 'Activate'} </Button>
        </Group>
      </Modal>
    </Paper>
  )
}

export default UserManagementPage
