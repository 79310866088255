const env = process.env.REACT_APP_ENV

const isProd = env === 'prod'

export const APP_DATA = {
  APP_NAME: isProd ? 'Bibot' : 'Bibot-Dev'
}

const baseUrls = {
  core: isProd ? 'https://api.bibot.app/v0/' : 'https://api.dev.bibot.app/v0/',
  training: isProd ? 'https://training.bibot.app/v0' : 'https://training.dev.bibot.app/v0',
  statistics: isProd ? 'https://statistics.bibot.app/v0' : 'https://statistics.dev.bibot.app/v0',
  inference: isProd ? 'https://inference.bibot.app/v0' : 'https://inference.dev.bibot.app/v0',
  subscriptions: isProd ? 'https://subscription.bibot.app/v0' : 'https://subscription.dev.bibot.app/v0',
  lp: isProd ? 'https://lp.bibot.app/v0' : 'https://lp.dev.bibot.app/v0',
  teams: isProd ? 'https://teams.bibot.app/v0' : 'https://teams.dev.bibot.app/v0',
  secrets: isProd ? 'https://secrets.bibot.app/v0' : 'https://secrets.dev.bibot.app/v0'
}

export const endpoints = {
	get core () {
		return baseUrls.core
	},
	get training () {
		return baseUrls.training
	},
  get statistics() {
    return baseUrls.statistics
  },
  get inference () {
		return baseUrls.inference
	},
  get subscriptions () {
		return baseUrls.subscriptions
	},
  get lp () {
		return baseUrls.lp
	},
  get teams () {
		return baseUrls.teams
	},
  get secrets () {
		return baseUrls.secrets
	}
  
} as const

export enum domain {
  app = '/app',
  core = '/core',
  training = '/training',
  bibot = '/bibot',
  inference = '/inference',
  subscriptions = '/subscriptions',
  statistics = '/statistics',
  lp = '/lp',
  teams = '/teams',
  get_secrets = '/get-secrets',
  store_secrets = '/store-secrets',
}


export enum resources {
  contact = '/contact',
  form = '/form',
  ampConfig = '/amp-config',
  usr = '/usr',
  register = '/register',
  verifyEmail = '/verify-email',
  resetPwd = '/reset-pwd',
  verify = '/verify',
  chatBubble = '/chat-bubble-config',
  predefined = '/predefined',
  predefinedQ = '/predefined-q',
  updatePredefinedQ = '/update-predefined-q',
  deletePredefinedQ = '/delete-predefined-q',
  updateKB = '/update-kb',
  deleteKB = '/delete-kb',
  embed = '/embed',
  transcribe = '/transcribe',
  checkout = '/checkout',
  update = '/update',
  myPlan = '/my-plan',
  plans = '/plans',
  list = '/list-kb',
  rawKb= '/raw-kb',
  subscription = '/subscription',
  chat_history = '/list-chat-history',
  chat_sessions = '/list-chat-sessions',
  listStatistics = '/dashboard-statistics',
  data = '/data',
  scrape='/scrape',
  createUser = '/create-user',
  listUsers = '/list-users',
  deactivateUsers = '/deactivate-users',
  deleteSelectedUsers = '/delete-selected-users',
  updateUserAttributes = '/update-user',
  activateUsers = '/activate-users',
  lm = 'lm',
  secrets = '/rsa',
  jira = '/jira',
  whatsapp = '/q-whatsapp',
  salesforce = '/salesforce',
  slack = '/slack',
  qSlacker = '/q-slacker',
  qTeams = '/q-teams',
  chatBubbleBanner= '/chat-bubble-banner',
  paymentInfo = '/my-payment-info',
  unanswered_q = '/unanswered-q',
  updateCard= '/update-payment-method',
  cancelSubscription = '/cancel-subscription',
  defaultCard = '/set-default-payment-method',
  createPaymentMethod = '/create-payment-method',
  detachCard = '/detach-payment-method'
}