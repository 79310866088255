import { useState } from 'react'
import { Code, Paper, Text, Title, TextInput, Button } from '@mantine/core'
import { primary_color, secondary_color } from 'styles/colors'
import { storeQTeamsSecrets } from 'services'
import { message } from 'antd'
import { EnhancedEncryptionOutlined } from '@mui/icons-material'
import { IconEye, IconEyeOff } from '@tabler/icons-react'

export default function TeamsBotFrameworkIntegration() {
  const [appPassword, setAppPassword] = useState('')
  const [appId, setAppId] = useState('')
  const [loading, setLoading] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const handleSave = async () => {
    setLoading(true)
    if (!appPassword || !appId) {
      message.error('Please enter both App ID and Password')
      setLoading(false)
      return
    }
    const payload = {
      appId,
      appPassword,
    }
    try {
      console.log('Saving Teams App ID and Password:', payload)
      const res = await storeQTeamsSecrets(payload)
      message.info(res)
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      } else {
        message.error('Error saving Teams Bot Framework information, please try again later.')
      }
    } finally {
      setLoading(false)
    }
  }

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(prev => !prev)
  }

  return (
    <>
      <Paper
        withBorder
        p={20}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Title order={4} mb={20}>Teams Bot Framework Integration</Title>
        <Text>Follow the steps below to set up the integration. For detailed guidance, refer to the linked documentation.</Text>

        {/* Step 1 */}
        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 1:</strong> Register an Azure App</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>
              Go to the Azure Active Directory and register a new app. You will receive an App ID and an App Secret after completing the registration.
              Use these credentials in later steps. For further details, check out
              <a href="https://learn.microsoft.com/en-us/entra/identity-platform/quickstart-register-app?tabs=certificate" target="_blank" rel="noreferrer" style={{ fontWeight: 'bold' }}> this guide</a>.
            </Text>
          </Paper>
        </Paper>

        {/* Step 2 */}
        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 2:</strong> Register a Bot in Azure Bot Service</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>
              With the App ID from Step 1, register a bot in the Azure Bot Service. Select the appropriate tier based on your requirements. If needed, follow 
              <a href="https://learn.microsoft.com/en-us/azure/bot-service/abs-quickstart?view=azure-bot-service-4.0&tabs=multitenant" target="_blank" rel="noreferrer" style={{ fontWeight: 'bold' }}> this tutorial</a> for a detailed guide.
            </Text>
          </Paper>
        </Paper>

        {/* Step 3 */}
        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 3:</strong> Update Teams App Manifest</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>
              Modify the <Code>BiBot.zip</Code> file (found in the app&apos;s public folder) with the App ID from Step 1. After updating the manifest file with the correct details, re-zip it and use it to create a custom Teams App.
            </Text>
          </Paper>
        </Paper>

        {/* Step 4 */}
        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 4:</strong> Save App ID and Password</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <TextInput
              label="Azure Registered App ID"
              placeholder="Enter the App ID generated by Azure App Registration"
              value={appId}
              onChange={(e) => setAppId(e.target.value)}
              mt={10}
              mb={10}
              required
            />
            <TextInput
              label="App Secret"
              placeholder="Enter the App Secret"
              value={appPassword}
              onChange={(e) => setAppPassword(e.target.value)}
              type={isPasswordVisible ? 'text' : 'password'} // toggle input type
              rightSection={
                <button onClick={togglePasswordVisibility} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {isPasswordVisible ? <IconEye /> : <IconEyeOff />}
                </button>
              }
              mt={10}
              mb={10}
              required
            />
            <Button
              onClick={handleSave}
              color={primary_color}
              mt={20}
              loading={loading}
              disabled={loading}
              leftSection={<EnhancedEncryptionOutlined fontSize="small" />}
            >
              Save Bot Framework Secrets
            </Button>
          </Paper>
        </Paper>

        {/* Step 5 */}
        <Paper
          bg={'#f8f8f8'}
          mt={30}
          p={10}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 5:</strong> Integrate Bot into Teams</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'space-between' }}
          >
            <Text>
              Finally, integrate your bot into Microsoft Teams. You can use the App ID from Step 1 along with the updated manifest file to deploy the bot in group chats, individual chats, or Teams channels.
            </Text>
          </Paper>
        </Paper>
      </Paper>
    </>
  )
}
