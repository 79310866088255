import { useState } from 'react'
import { Code, Paper, Text, Title, TextInput, Button } from '@mantine/core'
import { primary_color, secondary_color } from 'styles/colors'
import { storeQSlackSecrets } from 'services'
import { message } from 'antd'
import { EnhancedEncryptionOutlined, ContentCopy } from '@mui/icons-material'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import { SlackSecretVisible } from 'types'

export default function SlackAppIntegration() {

  const [isPasswordVisible, setIsPasswordVisible] = useState<SlackSecretVisible>({
    signingSecret: false,
    slackToken: false,
    slackAppId: false,
  })
  const [slackToken, setSlackToken] = useState('')
  const [slackAppId, setSlackAppId] = useState('')
  const [signingSecret, setSigningSecret] = useState('')
  const [loading, setLoading] = useState(false)

  const manifestText = `{
    "display_information": {
      "name": "BiBot"
    },
    "features": {
      "bot_user": {
        "display_name": "BiBot",
        "always_online": true
      }
    },
    "oauth_config": {
      "scopes": {
        "bot": [
          "app_mentions:read",
          "chat:write",
          "users:read"
        ]
      }
    },
    "settings": {
      "event_subscriptions": {
        "request_url": "https://inference.bibot.app/v0/inference/q-slacker",
        "bot_events": [
          "app_mention"
        ]
      },
      "org_deploy_enabled": false,
      "socket_mode_enabled": false,
      "token_rotation_enabled": false
    }
  }`

  const togglePasswordVisibility = (field: keyof SlackSecretVisible) => {
    setIsPasswordVisible(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }))
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(manifestText)
    message.success('Manifest copied to clipboard!')
  }

  const handleSave = async () => {
    setLoading(true)
    if (!slackToken) {
      message.error('Please enter the Slack OAuth Token')
      setLoading(false)
      return
    }
    if (!signingSecret) {
      message.error('Please enter the signing secret')
      setLoading(false)
      return
    }
    if (!slackAppId) {
      message.error('Please enter the Slack App ID')
      setLoading(false)
      return
    }

    const payload = {
      slack_token: slackToken,
      signing_secret: signingSecret,
      api_app_id: slackAppId,
    }

    try {
      console.log('Saving Slack Integration:', payload)
      const res = await storeQSlackSecrets(payload)
      message.info(res)
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error saving Slack app credentials, please try again later.'
      message.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Paper
        withBorder
        p={20}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Title order={4} mb={20}>Slack App Integration with Incoming Webhook</Title>
        <Text>Follow the steps below to create a Slack App and retrieve the necessary credentials to integrate it into your system.</Text>

        <Paper
          bg="#f8f8f8"
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 1:</strong> Create a Slack App Using the Manifest:</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
          >
            <Text>
              Go to the <a href="https://api.slack.com/apps" target="_blank" rel="noreferrer" style={{ fontWeight: 'bold' }}>Slack API Dashboard</a> and click <Code style={{ fontSize: 14 }} color={secondary_color} c="#000">&quot;Create New App&quot;</Code>. Choose the option to create the app using a manifest and paste the following:
            </Text>
            <div style={{ position: 'relative', marginTop: '10px' }}>
              <Paper
                bg="#f0f0f0"
                p={10}
                mt={10}
                style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', fontSize: '14px' }}
              >
                {manifestText}
              </Paper>

              <Button
                onClick={copyToClipboard}
                color={primary_color}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  zIndex: 1,
                }}
                leftSection={<ContentCopy fontSize="small" />}
              >
                Copy Manifest
              </Button>
            </div>
            <Text mt={10}>
              <strong>Explanation of the OAuth Scopes:</strong>
              <ul>
                <li><strong>app_mentions:read</strong>: Allows the bot to see when someone mentions it in messages.</li>
                <li><strong>chat:write</strong>: Lets the bot send messages in channels.</li>
                <li><strong>users:read</strong>: Lets the bot access information about users, like their names.</li>
              </ul>
            </Text>
          </Paper>
        </Paper>

        <Paper
          bg="#f8f8f8"
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 2:</strong> Retrieve OAuth Token and Signing Secret:</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
          >
            <Text>
              Once the app is created, go to the <Code style={{ fontSize: 14 }} color={secondary_color} c="#000">OAuth & Permissions</Code> section to retrieve the <strong>OAuth Token</strong>. You may have to install the app into your workspace to get the token. Then navigate to <Code style={{ fontSize: 14 }} color={secondary_color} c="#000">Basic Information</Code> to get the <strong>App ID</strong> and the <strong>Signing Secret</strong> under the App Credentials section.
            </Text>
          </Paper>
        </Paper>

        <Paper
          bg="#f8f8f8"
          mt={30}
          p={10}
          style={{ width: '100%', color: '#000', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 3:</strong> Enter Slack Token and Signing Secret:</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{flexDirection: 'column', textAlign: 'left' }}
          >
            <Text>
              Use the fields below to input your <strong>Slack Token</strong> and <strong>Signing Secret</strong> for saving into your system.
            </Text>

            <TextInput
              label="Slack App ID"
              placeholder="Enter your Slack App ID"
              value={slackAppId}
              type={isPasswordVisible.slackAppId ? 'text': 'password'}
              onChange={(e) => setSlackAppId(e.target.value)}
              rightSection={
                <button onClick={() => togglePasswordVisibility('slackAppId')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {isPasswordVisible.slackAppId ? <IconEye /> : <IconEyeOff />}
                </button>
              }
              mt={10}
              mb={10}
              required
            />
            <TextInput
              label="Slack Token"
              placeholder="Enter your Slack Token"
              type={isPasswordVisible.slackToken ? 'text': 'password'}
              value={slackToken}
              onChange={(e) => setSlackToken(e.target.value)}
              rightSection={
                <button onClick={() => togglePasswordVisibility('slackToken')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {isPasswordVisible.slackToken ? <IconEye /> : <IconEyeOff />}
                </button>
              }
              mt={10}
              mb={10}
              required
            />
            <TextInput
              label="Signing Secret"
              placeholder="Enter your Signing Secret"
              value={signingSecret}
              onChange={(e) => setSigningSecret(e.target.value)}
              type={isPasswordVisible.signingSecret ? 'text': 'password'}
              rightSection={
                <button onClick={() => togglePasswordVisibility('signingSecret')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {isPasswordVisible.signingSecret ? <IconEye /> : <IconEyeOff />}
                </button>
              }
              mt={10}
              mb={10}
              required
            />
            
            <Button
              onClick={handleSave}
              color={primary_color}
              mt={20}
              loading={loading}
              disabled={loading}
              leftSection={<EnhancedEncryptionOutlined fontSize="small" />}
            >
              Save Integration
            </Button>
          </Paper>
        </Paper>
      </Paper>
    </>
  )
}
