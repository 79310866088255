import React, { createContext, useCallback, useEffect, useReducer } from 'react'
import { SubscriptionContextProps, SubscriptionDetails, SubscriptionPlans, SubscriptionProviderProps, SubscriptionState } from 'types'
import { SUB_ACTION_TYPE_ENUMS } from 'config'
import { getSubscriptionPlans, getMySubscription } from 'services'
import { subReducers } from 'reducers'

const dummyDispatch = () => {} // A no-op function.

const defaultContextValue: SubscriptionContextProps = {
  state: { exists: false, plans: [] },
  dispatch: dummyDispatch
}

const SubscriptionContext = createContext<SubscriptionContextProps>(defaultContextValue)

const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const initialState: SubscriptionState = {
    exists: false,
    plans: []
  }

  const handleGetSubscriptionPlans = useCallback(async () => {
    const serviceSubscriptionPlansData: SubscriptionPlans[] = await getSubscriptionPlans()
    if (serviceSubscriptionPlansData) {
      dispatch({ type: SUB_ACTION_TYPE_ENUMS.GOT_PLANS, plans: serviceSubscriptionPlansData })
    }
  }, [])

  const handleGetSubscription = useCallback(async () => {
    const serviceSubscriptionData: SubscriptionDetails | null = await getMySubscription()
    if (serviceSubscriptionData) {
      dispatch({ type: SUB_ACTION_TYPE_ENUMS.EXISTS, sub: serviceSubscriptionData })
    }
  }, [])

  const [state, dispatch] = useReducer(subReducers, initialState)

  useEffect(() => {
    void handleGetSubscriptionPlans()
    void handleGetSubscription()
  }, [handleGetSubscription])

  return (
    <SubscriptionContext.Provider value={{ state, dispatch }}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export { SubscriptionContext, SubscriptionProvider }