import { Paper, Title, Text, Badge } from '@mantine/core'
import {
  IconDiscountCheckFilled,
} from '@tabler/icons-react'
import SubscriptionCard from './SubscriptionCard'
import { timestampDisplay } from 'utils'
import { useSubscription } from 'hooks'
import UpdateSubscriptionCard from './UpdateSubscriptionCard'
import { useContext, useState } from 'react'
import { SubscriptionContext } from 'context'
import WarningModal from 'components/utils/modal'
import { cancelSubscription } from 'services'
import { notification } from 'antd'
import CreditCard from '../../utils/creditCard'
export default function Subscription() {
    const { state } = useContext(SubscriptionContext)
  const {subPlans, mySubPlan} = useSubscription()
  const [openModal, setOpenModal] = useState(false) 
  const [canceling, setCanceling]= useState(false)
  
  const handleCancelSubscription = () => {
    setOpenModal(true)
  }

  const cancelUserSubscription = async() => {
    setCanceling(true)
    try{
      const response = await cancelSubscription()
      notification.info({message: response.message})
      setOpenModal(false)
    setCanceling(true)
    }
    catch(err) {
      setOpenModal(false)
    setCanceling(true)
        return err
    }
  }

  return (
    <div className="mainContainer">
      <WarningModal confirmButtonText='Yes' openModal={openModal} closeModal={()=> setOpenModal(false)} title={<Title c={'#000'} order={5}>Warning</Title>} onConfirm={cancelUserSubscription} onCancel={()=> setOpenModal(false)} content={'Are you sure u want to cancel your subscription'} loadDeleting={canceling} />
      <Title order={3}>Choose your plan</Title>
      <Paper p={30} mt={20} w={'100%'}>
      {mySubPlan && mySubPlan?.tier && mySubPlan?.current_period_start && mySubPlan?.current_period_end && (<>
       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
       <Paper
        style={{ width: '65%', minHeight: 140, backgroundColor: '#f0f0f0' }}
        p={20}
        withBorder
      >
       
        <Text c="dimmed">CURRENT PLAN </Text>
        <div style={{ display: 'flex', alignItems: 'center', marginBlock: 6 }}>
          <IconDiscountCheckFilled
            color="green"
            size={30}
            style={{ marginRight: 5 }}
          />{' '}
          <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
            {(`${mySubPlan?.tier}`).toUpperCase()}
          </Text>
        </div>
        {mySubPlan?.cadence && (
        <Text 
          style={{ 
            fontSize: 14, 
            marginBottom: 5, 
            color: '#6c757d'  // dimmed color
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Duration: </span> 
          <span style={{ fontStyle: 'italic', color: '#007bff' }}>
            {mySubPlan.cadence.toUpperCase()}
          </span>
        </Text>
      )}
        {mySubPlan?.current_period_start && mySubPlan?.current_period_end && (
        <Text 
          style={{ 
            fontSize: 14, 
            marginBottom: 5, 
            color: '#6c757d'  // dimmed color
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Billing Period: </span> 
          <span style={{ fontStyle: 'italic', color: '#007bff' }}>
            {timestampDisplay(mySubPlan.current_period_start)} - {timestampDisplay(mySubPlan.current_period_end)}
          </span>
        </Text>
      )}
      
        <Badge
          size="md"
          variant="gradient"
          gradient={{ from: mySubPlan?.status == 'active' ? 'lime' : 'red', to: mySubPlan?.status == 'active' ? 'green' : 'red', deg: 90 }}
        >
          {mySubPlan?.status}
        </Badge>
                  {/* Horizontally place a note that says, to cancel subscription, simply email us on bibot@thecloudsecllc.com from the email the user used to register */}
                  <div style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
  {state.sub?.canceled ? 
  <Text style={{ fontSize: 12, color: '#dc3545' }}>
  Your subscription is canceled. You will loss access to our chat services by the end of the current period. Please resubscribe.
</Text> :
  (mySubPlan?.status === 'active' ? (
    <>
    <Text style={{ cursor: 'pointer', fontSize: 13}} onClick={handleCancelSubscription} c={'red'} mt={10}>Cancel subscription</Text>
    {/* <Text style={{ fontSize: 12, color: '#dc3545' }}>
      To cancel the subscription, simply email us at <a href="mailto:bibot@thecloudsecllc.com">bibot@thecloudsecllc.com</a> from the email you used to register.
    </Text> */}
    </>
    
  ) : (
    <Text style={{ fontSize: 12, color: '#dc3545' }}>
      Please select a subscription tier from below.
    </Text>
  ))}
</div>
      </Paper>
      <CreditCard />
       </div>
</>)}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {subPlans && (
          subPlans.map((plan, index: number) => {
            return mySubPlan && mySubPlan.tier && mySubPlan.status == 'active' ? (
              <UpdateSubscriptionCard
                key={index}
                plan={plan}
                currentPlanName={mySubPlan.tier}
              />
            ) : (
                <SubscriptionCard plan={plan}/>
            )
          }))}
      </div>
      </Paper>
      <div style={{ marginTop: 20 }}>
        <Text size="sm">
          By subscribing, you agree to our <a href="/bibot-sub-terms-and-conditions.html" target="_blank" rel="noopener noreferrer">Subscription Terms and Conditions</a>.
        </Text>
      </div>
    </div>
  )
}

