const truncateText = (text: string) => {
    if (text.length > 25) {
      return `${text.substring(0, 23)}...`
    }
    return text
  }

  export {
    truncateText
  }
  