import { Button, Select, Text, SelectProps, Skeleton, Loader, Badge, Modal, Title, Tooltip, NumberInput, Group, TextInput } from '@mantine/core'
import '../../styles/creditCard.css'
import usePaymentInfo from 'hooks/subscription/usePaymentInfo'
import { useState, useEffect } from 'react'
import { IconCheck, IconChevronDown, IconPlus, IconX } from '@tabler/icons-react'
import { createPaymentMethod, detachCard, setDefaultCard, updateCard } from 'services'
import { notification } from 'antd'
import { cardDataProp, defaultCardProp } from 'types'
import visa from '../../assets/cardLogo/visa.png'
import mastercard from '../../assets/cardLogo/mastercard.png'
import amex from '../../assets/cardLogo/amex.png'
import discover from '../../assets/cardLogo/discover.png'
import unionPay from '../../assets/cardLogo/unionPay.svg.png'
import { primary_color } from 'styles/colors'
import { useDisclosure } from '@mantine/hooks'
import WarningModal from './modal'

const CreditCard = () => {
  const { data, loading, refresh } = usePaymentInfo()
  const [accountNum, setAccNum] = useState<string | null>(null)
  const [updating, setUpdating] = useState(false)
  const [addingCard, setAddingCard] = useState(false)
  const [detachLoading, setDetachLoading] = useState(false)
  const [defaultCardLoader, setDefaultCardLoader] = useState(false)
  const [opened, { open, close }] = useDisclosure(false)
  const [openModal, setOpenModal] = useState(false)
  const [showMoreOptions, setShowMoreOptions] = useState(false)
  const [expMonth, setExpMonth] = useState<string | number>(new Date().getMonth() + 1)
  const [expYear, setExpYear] = useState<string | number>(new Date().getFullYear())
  const [selectedCard, setSelectedCard] = useState<cardDataProp>({
    id: accountNum,
    type: '',
    brand: '',
    last4: '',
    billing_details: {
      address: {
        state: '',
        line1: '',
        line2: '',
        city: '',
        postal_code: '',
        country: '',
      },
      name: '', 
      email: '',
      phone: ''
    },
    exp_month: '',
    exp_year: '',
  })
  const [FiledError, setFieldError] = useState({
    country: false,
    email: false
  })

  const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name.startsWith('billing_details.address.')) {
      const addressKey = name.split('billing_details.address.')[1]
      setSelectedCard(prev => ({
        ...prev,
        billing_details: {
          ...prev.billing_details,
          address: {
            ...prev.billing_details.address,
            [addressKey]: value
          }
        }
      }))
    } else {
      setSelectedCard(prev => ({
        ...prev,
        billing_details: {
          ...prev.billing_details,
          [name]: value
        }
      }))
    }

     if (name === 'email'){
       const isEmailValid = isValidEmail(value)
      setFieldError(prev => ({ ...prev, email: !isEmailValid }))
    }
    else if (name === 'billing_details.address.country') {
      const isCountryValid = value.trim() !== '' && value.length === 2
      setFieldError(prev => ({ ...prev, country: !isCountryValid }))
    }
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const defaultCard = data.find(card => card.default_payment_method === true)
      if (defaultCard) {
        setAccNum(defaultCard.id)
      }
    }
  }, [data])

  useEffect(() => {
    if (accountNum) {
      const selectedCardData = data?.find(card => card.id === accountNum)
      if (selectedCardData) {
        setExpMonth(selectedCardData.exp_month)
        setExpYear(selectedCardData.exp_year)
        setSelectedCard({
          id: selectedCardData?.id,
          type: selectedCardData?.type,
          brand: selectedCardData?.brand,
          last4: selectedCardData?.last4,
          billing_details: {
            address: {
              state: selectedCardData.billing_details.address.state || '',
              line1: selectedCardData.billing_details.address.line1 || '',
              line2: selectedCardData.billing_details.address.line2 || '',
              city: selectedCardData.billing_details.address.city || '',
              postal_code: selectedCardData.billing_details.address.postal_code || '',
              country: selectedCardData.billing_details.address.country || '',
            },
            name: selectedCardData.billing_details.name,
            email: selectedCardData.billing_details.email,
            phone: selectedCardData.billing_details.phone
          },
          exp_month: selectedCardData.exp_month,
          exp_year: selectedCardData.exp_year,
        })
      }
    }
  }, [accountNum, data])

  const renderCardType = (brand: string) => {
    switch (brand) {
      case 'visa':
        return <img width={60} src={visa} />
      case 'mastercard':
        return <img width={60} src={mastercard} />
      case 'amex':
        return <img width={80} src={amex} />
      case 'unionpay':
        return <img width={60} src={unionPay} />
      case 'discover':
        return <img width={80} src={discover} />
      default:
        return brand.toUpperCase()
    }
  }

  if (loading) {
    return <Skeleton w={430} h={230} visible={loading} />
  }

  const addCard = async() => {
    setAddingCard(true)
    try {
      const res = await createPaymentMethod()
      notification.info({
        message: res.message
      })
      setAddingCard(false)
      window.location.replace(res.location)
    } catch (err) {
      setAddingCard(false)
      console.error(err)
    }
  }

  if (!data || data.length === 0) {
    return  <div className="creditCard">
      <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
        <Text>No card available</Text>
        <Button mt={10} color={primary_color} disabled={addingCard} onClick={addCard}>{addingCard ? <Loader size={'xs'} color='#000' />  : 'Add card'} </Button>
      </div>
    </div>
  }

  const cardOptions = data.map(info => ({
    value: info.id,
    label: `**** **** **** ${info.last4}`,
  }))

  const iconProps = {
    stroke: 1.5,
    color: 'currentColor',
    opacity: 0.6,
    size: 18,
  }

  const renderSelectOption: SelectProps['renderOption'] = ({ option, checked }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      {option.label}
      {checked && <IconCheck style={{ marginInlineStart: 'auto' }} {...iconProps} />}
      <IconX onClick={() => setOpenModal(true)} style={{ marginInlineStart: 'auto', color: 'red' }} {...iconProps} />
    </div>
  )

  const makeDefaultCard = async (id: string, default_payment_method: boolean ) => {
    if (!accountNum) return
    setDefaultCardLoader(true)
    const cardData: defaultCardProp = {
      id: id,
      default_payment_method: default_payment_method
    }
    try {
      const response = await setDefaultCard(cardData)
      notification.info({
        message: response.message
      })
      await refresh()
      setDefaultCardLoader(false)
    } catch (err) {
      setDefaultCardLoader(false)
      console.error(err)
    }
  }

  const updateSubCard = async () => {
    if (!accountNum  || !isValidEmail(selectedCard.billing_details.email) || selectedCard.billing_details.email === '' || selectedCard.billing_details.phone === '' || selectedCard.billing_details.name === '' || selectedCard.billing_details.address.state === '' || selectedCard.billing_details.address.line1 === ''
    || selectedCard.billing_details.address.city === '' || selectedCard.billing_details.address.postal_code === '' || selectedCard.billing_details.address.country === '' || !/^[A-Za-z\s]+$/.test(selectedCard.billing_details.name) || expYear === new Date().getFullYear() && expMonth !== new Date().getMonth() + 1 ) {
      return
    }
    setUpdating(true)
    try {
      // Clean the address object by removing keys with empty values
      const cleanAddress = Object.fromEntries(
        Object.entries(selectedCard.billing_details.address).filter(([, value]) => 
          value !== null && value !== undefined && value !== ''
        )
      )
      const filteredPayload = {
        id: selectedCard.id,
        type: selectedCard.type,
        brand: selectedCard.brand,
        last4: selectedCard.last4,
        exp_month: expMonth,
        exp_year: expYear,
        billing_details: {
          address: cleanAddress,
          name: selectedCard.billing_details.name,
          email: selectedCard.billing_details.email,
          phone: selectedCard.billing_details.phone
        }
      }
      const response = await updateCard(filteredPayload)
      notification.info({
        message: response.message
      })
      refresh()
    } catch (err) {
      console.error(err)
    } finally {
      setUpdating(false)
      close()
    }
  }

  const detachPaymentMethod = async () => {
    if (!accountNum || accountNum.length <= 0) return
    setDetachLoading(true)
    try {
      const res = await detachCard({ id: accountNum })
      notification.info({
        message: res.message
      })
     refresh()
      setOpenModal(false)
    } catch (err) {
      console.error(err)
      setOpenModal(false)
    } finally {
      setDetachLoading(false)
    }
  }

  return (
    <>
      <WarningModal 
        confirmButtonText='Yes' 
        openModal={openModal} 
        closeModal={() => setOpenModal(false)} 
        title={<Title c={'#000'} order={5}>Warning</Title>} 
        onConfirm={detachPaymentMethod} 
        onCancel={() => setOpenModal(false)} 
        content={'Payment method will be deleted and will not be available for future use. Are you sure you want to proceed?'} 
        loadDeleting={detachLoading} 
      />
      <Modal centered opened={opened} zIndex={9999} onClose={close} title={<Title c={'#000'} order={4}>Edit Card</Title>}>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <NumberInput
           error={expYear === new Date().getFullYear() && expMonth !== new Date().getMonth() + 1}
            value={expMonth}
            label="Month"
            placeholder="MM"
            min={1}
            max={12}
            step={1}
            onChange={value => setExpMonth(value)}
          />
          <NumberInput
            value={expYear}
            label="Year"
            placeholder="YYYY"
            min={new Date().getFullYear()}
            max={new Date().getFullYear() + 10}
            step={1}
            onChange={value => setExpYear(value)}
          />
        </div>

        <Group mt={15} style={{ cursor: 'pointer'}}>
          <IconChevronDown color='blue' /> 
          <small style={{ color: 'blue'}} onClick={() => setShowMoreOptions(!showMoreOptions)}> More options</small>
        </Group>

        {showMoreOptions && 
        <div style={{ marginTop: 20}}>
          <TextInput
          error={ selectedCard.billing_details.name === '' || !/^[A-Za-z\s]+$/.test(selectedCard.billing_details.name)}
            name="name"
            value={selectedCard.billing_details.name}
            mb={10}
            placeholder='Cardholder name'
            label='Cardholder Name'
            onChange={handleChange}
          />
          <TextInput
          error={selectedCard.billing_details.address.line1 === ''}
            name="billing_details.address.line1"
            value={selectedCard.billing_details.address.line1}
            mb={10}
            placeholder='Street(line1)'
            label='Street Line 1'
            onChange={handleChange}
          />
          <TextInput
            name="billing_details.address.line2"
            value={selectedCard.billing_details.address.line2}
            mb={10}
            placeholder='Street(line2)'
            label='Street Line 2'
            onChange={handleChange}
          />
          <TextInput
           error={selectedCard.billing_details.address.city === ''}
            name="billing_details.address.city"
            value={selectedCard.billing_details.address.city}
            mb={10}
            placeholder='City'
            label='City'
            onChange={handleChange}
          />
          <TextInput
           error={selectedCard.billing_details.address.postal_code === ''}
            name="billing_details.address.postal_code"
            value={selectedCard.billing_details.address.postal_code}
            mb={10}
            placeholder='Zip/Postal'
            label='Zip/Postal'
            onChange={handleChange}
          />
          <TextInput
            error={selectedCard.billing_details.address.state === ''}
            name="billing_details.address.state"
            value={selectedCard.billing_details.address.state}
            mb={10}
            placeholder='State/Province'
            label='State/Province'
            onChange={handleChange}
          />
          <TextInput
            error={FiledError.country}
            name="billing_details.address.country"
            value={selectedCard.billing_details.address.country}
            mb={10}
            placeholder='Country'
            label='Country (2-character alphanumeric country code e.g US, EG, or GB)'
            onChange={handleChange}
          />
          <TextInput
            error={FiledError.email}
            name="email"
            value={selectedCard.billing_details.email}
            mb={10}
            placeholder='Email'
            label='Email'
            onChange={handleChange}
          />
          <TextInput
            error={selectedCard.billing_details.phone === ''}
            name="phone"
            type='number'
            value={selectedCard.billing_details.phone}
            mb={10}
            placeholder='Phone'
            label='Phone'
            onChange={handleChange}
          />
        </div>}

        <Group justify='flex-end'>
          <Button mt={20} onClick={updateSubCard} disabled={updating} variant='solid' bg={primary_color}>
            {updating ? <Loader size={'xs'} color='#fff' /> : 'Update'}
          </Button>
        </Group>
      </Modal>
      <div className="creditCard">
        <div className="card-content">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              allowDeselect={false}
              disabled={addingCard}
              leftSection={addingCard ? <Loader size={'xs'} color='#000' /> : <Tooltip label="Add Payment Method"><IconPlus size={25} style={{ cursor: 'pointer', margin: 5 }} onClick={addCard} /></Tooltip>}
              size="xs"
              label="Select card"
              placeholder="Pick card"
              value={accountNum}
              onChange={value => setAccNum(value)}
              data={cardOptions}
              renderOption={renderSelectOption}
            />
            {data.map(info => (
              accountNum === info.id && (
                <div style={{ fontWeight: 'bolder' }} key={info.id}>{renderCardType(info.brand)}</div>
              )
            ))}
          </div>
          {data.map(info => (
            accountNum === info.id && (
              <div key={info.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Text fw={'bold'} mt={30}>
                    **** **** **** {info.last4}
                  </Text>
                  <div style={{ width: '210px' }}>
                    <Text style={{ fontSize: 10 }} mt={30}>
                      Card Holder name
                    </Text>
                    <Text className='truncate' fw='bold'>{info.billing_details.name}</Text>
                  </div>
                </div>
                <div>
                  <Text mt={30}>Exp {info.exp_month}/{info.exp_year}</Text>
                  <Button onClick={open} size='xs' variant='default' color='#000'>Update card</Button>
                  <div>
                    {info.default_payment_method 
                      ? <Badge variant='light' size='xs' c={'#fff'} style={{ cursor: 'pointer', fontSize: 12 }} mt={30}>Default Card</Badge> 
                      : <Button variant='light' color={primary_color} size='md' c={'#fff'} onClick={() => makeDefaultCard(info.id, info.default_payment_method)} style={{ cursor: 'pointer', fontSize: 12 }} mt={10}>
                        {defaultCardLoader ? <Loader size={'xs'} color='#fff' /> : 'Make Default'}
                      </Button>
                    }
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    </>
  )
}

export default CreditCard

