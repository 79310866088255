import axios, { type AxiosInstance } from 'axios'
import { getIdToken } from 'utils'
import { endpoints } from 'config/endpointEnums'

const coreBaseURL = endpoints.core
const trainingBaseURL = endpoints.training
const statisticsBaseURL = endpoints.statistics
const inferenceBaseURL = endpoints.inference
const lpBaseURL = endpoints.lp
const subscriptionBaseURL = endpoints.subscriptions
const teamsBaseURL = endpoints.teams
const secretsBaseURL = endpoints.secrets
export const createCoreAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
//   const { addNotification } = useNotifications()
	const instance = axios.create({
		baseURL: coreBaseURL,
		timeout: 60000, // 1 minutes max
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.request.use(async (config) => {
		try {
			const idToken = await getIdToken()
			config.headers.Authorization = idToken
		} catch (error) {
			console.error('Error attaching token', error)
		}
		return config
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}

export const createLpAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
	const instance = axios.create({
		baseURL: lpBaseURL,
		timeout: 60000, // 1 minutes max
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}

export const createTrainingAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
	//   const { addNotification } = useNotifications()
	const instance = axios.create({
		baseURL: trainingBaseURL,
		timeout: 60000, // 1 minutes max
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.request.use(async (config) => {
		try {
			const idToken = await getIdToken()
			config.headers.Authorization = idToken
		} catch (error) {
			console.error('Error attaching token', error)
		}
		return config
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}

export const createStatisticsAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
	//   const { addNotification } = useNotifications()
	const instance = axios.create({
		baseURL: statisticsBaseURL,
		timeout: 60000, // 1 minutes max
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.request.use(async (config) => {
		try {
			const idToken = await getIdToken()
			config.headers.Authorization = idToken
		} catch (error) {
			console.error('Error attaching token', error)
		}
		return config
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}

export const createInferenceAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
	//   const { addNotification } = useNotifications()
	const instance = axios.create({
		baseURL: inferenceBaseURL,
		timeout: 60000, // 1 minutes max
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}


export const createSubscriptionAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
	//   const { addNotification } = useNotifications()
	const instance = axios.create({
		baseURL: subscriptionBaseURL,
		timeout: 60000, // 1 minutes max
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.request.use(async (config) => {
		try {
			const idToken = await getIdToken()
			config.headers.Authorization = idToken
		} catch (error) {
			console.error('Error attaching token', error)
		}
		return config
	})
	
	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}

export const createTeamsAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
	const instance = axios.create({
		baseURL: teamsBaseURL,
		timeout: 60000,
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.request.use(async (config) => {
		try {
			const idToken = await getIdToken()
			config.headers.Authorization = idToken
		} catch (error) {
			console.error('Error attaching token', error)
		}
		return config
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}



export const createSecretsAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
	const instance = axios.create({
		baseURL: secretsBaseURL,
		timeout: 60000,
		headers: {
			'Content-Type': 'application/json',
			...headers
		},
		...params
	})

	instance.interceptors.request.use(async (config) => {
		try {
			const idToken = await getIdToken()
			config.headers.Authorization = idToken
		} catch (error) {
			console.error('Error attaching token', error)
		}
		return config
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let errMsg = 'An unknown error occurred'
			if (error.response) {
				errMsg = error.response.data.message
			} else if (error.request) {
				errMsg = 'No response received from the server.'
			} else {
				errMsg = `Error setting up the request: ${error.message}`
			}
			return await Promise.reject(new Error(errMsg))
		}
	)

	return instance
}