import { USER_ACTION_TYPE_ENUMS } from 'config'
import type { UserAction, UserState } from 'types'

export const userReducer = (state: UserState, action: UserAction): UserState => {
    switch (action.type) {
        case USER_ACTION_TYPE_ENUMS.AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: true,
                user: action.user,
                loading: false, 
            }
        case USER_ACTION_TYPE_ENUMS.SIGN_OUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false,
            }
        case USER_ACTION_TYPE_ENUMS.AUTHENTICATION_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false, 
                error: action.error,
            }
        case USER_ACTION_TYPE_ENUMS.SET_JWT:
            return {
                ...state,
                jwt: action.jwt,
                loading: false,
            }
        case USER_ACTION_TYPE_ENUMS.START_LOADING: 
            return {
                ...state,
                loading: true,
            }
		case USER_ACTION_TYPE_ENUMS.USER_NOT_FOUND: // New case for no user found
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false, // Stop loading if no user is found
            }
        default:
            return state
    }
}

