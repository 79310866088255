import { Button, Input, Loader, Paper, Title } from '@mantine/core'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import { notification } from 'antd'
import React, { ChangeEvent, useState } from 'react'
import { storeJiraSecrets } from 'services'
import { primary_color } from 'styles/colors'
import { JiraVisibilityState, jiraDataProps } from 'types'

export default function Jira () {
    const [form, setForm] = useState<jiraDataProps>({
      JiraUsername: '',
      JiraAPIToken: '',
      JiraURL: '',
      project: '',
      issueType: ''
    })
  
    const handleChange = (e: ChangeEvent<HTMLInputElement>)=> {
      e.preventDefault()
      const { name, value } = e.target
      setForm({...form, [name]: value})
    }
  
    const [isPasswordVisible, setIsPasswordVisible] = useState({
      JiraUsername: false,
      JiraAPIToken: false,
      JiraURL: false,
      project: false,
      issueType: false,
    })
  
    const togglePasswordVisibility = (field: keyof JiraVisibilityState) => {
      setIsPasswordVisible(prevState => ({
        ...prevState,
        [field]: !prevState[field],
      }))
    }
  
    const [isLoading, setIsLoading] = useState(false)
  
    const saveJiraKeys = async () => {
      if(form.JiraAPIToken === '' || form.JiraURL === '' || form.JiraUsername === '' || form.issueType === '' || form.project === ''){
        notification.error({
                  message: 'Missing fields',
                  description: 'Provide required fields'
              })
        return
      }
      setIsLoading(true)
      try{
        const response = await storeJiraSecrets(form)
          notification.info({
            message: '',
            description: response.message
          })     
        } 
      catch (error){
        notification.error({
          message: 'Error',
          description: 'An unexpected error occurred'
        })
      }finally {
        setIsLoading(false)
      }
     }
     
    return (
      <Paper style={{ width: '100%', flexDirection: 'column' }} mt={20} p={30} withBorder>
    <Title mt={20} mb={20} order={4}>Keys</Title>
      <Input type={isPasswordVisible.JiraUsername ? 'text' : 'password'} mb={20} placeholder='Jira Username' name='JiraUsername' value={form.JiraUsername} onChange={handleChange} rightSectionPointerEvents='all' rightSection={
         <button onClick={()=> togglePasswordVisibility('JiraUsername')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
         {isPasswordVisible.JiraUsername ? <IconEye /> : <IconEyeOff />}
       </button>
      }/>
      <Input type={isPasswordVisible.JiraAPIToken ? 'text' : 'password'}  mb={20} placeholder='Jira Token' name='JiraAPIToken' value={form.JiraAPIToken} onChange={handleChange} rightSectionPointerEvents='all' rightSection={
         <button onClick={()=> togglePasswordVisibility('JiraAPIToken')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
         {isPasswordVisible.JiraAPIToken ? <IconEye /> : <IconEyeOff />}
       </button>
      }/>
      <Input type={isPasswordVisible.JiraURL ? 'text' : 'password'} mb={20} placeholder='Jira url' name='JiraURL' value={form.JiraURL} onChange={handleChange} rightSectionPointerEvents='all' rightSection={
         <button onClick={()=> togglePasswordVisibility('JiraURL')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
         {isPasswordVisible.JiraURL ? <IconEye /> : <IconEyeOff />}
       </button>
      }/>  
      <Input type={isPasswordVisible.project ? 'text' : 'password'} mb={20} placeholder='Project' name='project' value={form.project} onChange={handleChange} rightSectionPointerEvents='all' rightSection={
         <button onClick={()=> togglePasswordVisibility('project')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
         {isPasswordVisible.project ? <IconEye /> : <IconEyeOff />}
       </button>
      }/>  
      <Input type={isPasswordVisible.issueType ? 'text' : 'password'}  mb={20} placeholder='Issue Type' name='issueType' value={form.issueType} onChange={handleChange} rightSectionPointerEvents='all' rightSection={
         <button onClick={()=> togglePasswordVisibility('issueType')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
         {isPasswordVisible.issueType ? <IconEye /> : <IconEyeOff />}
       </button>
      }/>  
      <Button disabled={isLoading} onClick={saveJiraKeys} variant='filled' color={primary_color} >{isLoading ? <Loader size={'sm'} color={primary_color} />: 'Save'} </Button>
      </Paper>
    )
  }
