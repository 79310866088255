import { getAmpConfig } from 'services'

async function loadAmpConfig () {
	const envConfig = await getAmpConfig()

	const ampConfig = {
		Auth: {
			Cognito: {
				userPoolId: envConfig.userPoolId,
				userPoolClientId: envConfig.userPoolWebClientId,
				oauth: {
					domain: envConfig.domain,
					scope: [
						'phone',
						'email',
						'profile',
						'openid',
						'aws.cognito.signin.user.admin'
					],
					redirectSignIn: envConfig.redirectSignIn,
					redirectSignOut: envConfig.redirectSignOut,
					responseType: 'code'
				}
			}
		}
	}

	return ampConfig
}

export { loadAmpConfig }
