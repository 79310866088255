import { Badge, Paper, Text, Title } from '@mantine/core'
import { PieChart } from '@mantine/charts'
import { IconFile } from '@tabler/icons-react'
import { StatisticsP } from 'types'
import { primary_color } from 'styles/colors'
import { GaugeComponent } from 'react-gauge-component'

export default function DashboardCard( statsData :StatisticsP) {
  const data = [
    { name: 'Successful', value: statsData.kb_usage_success, color: 'green' },
    { name: 'Unsuccessful', value: statsData.kb_usage_fail, color: 'yellow' }
  ]

  const cardData = [
    {
      title: 'KB Usage',
      chat: <PieChart style={{ marginInline: 20, minWidth: 250}} withLabels withTooltip size={130} data={data} labelsType="percent"/>,
      chatrep: ['successful', 'unsuccessful']
    },
    {
      title: 'Chat',
      chat:  <GaugeComponent
      type="semicircle"
      id='gauge-gradient'
      arc={{
        gradient: true,
        width: 0.15, 
        padding: 0,
        subArcs: [
          {
            limit: 15,
            color: '#5BE12C',
            showTick: true
          },
          {
            limit: 37,
            color: '#5BE12C',
            showTick: true
          },
          {
            limit: 58,
            color: '#5BE12C',
            showTick: true
          },
          {
            limit: 75,
            color: '#F5CD19',
            showTick: true
          },
          { color: '#EA4228' }
        ]
      }}
      style={{ marginTop: 10 }}
      value={statsData.chat_usage?.percentage ? parseFloat(statsData.chat_usage?.percentage) : 0}
      pointer={{type: 'arrow', elastic: true}}
    />,
      count: <>
         <Text> <span style={{ fontWeight: 'bold', fontSize: 14}}>Used:</span> {statsData.chat_usage?.used && statsData.chat_usage?.allocated ? statsData.chat_usage.used : ''}</Text>
         <Text> <span style={{ fontWeight: 'bold', fontSize: 14}}>Allocated:</span> {statsData.chat_usage?.allocated ? statsData.chat_usage.allocated : ''}</Text>
       </>,
      // usage: statsData.mim_usage_success,
      // description: 'Used over allocated'
    },
    {
      title: 'Documents',
      icon: <IconFile size={50} color={primary_color} />,
      count: `${statsData.kb_counts}`,
      description: 'Uploaded KB training documents'
    }
  ]

  const getColor = (rep: string) => {
    if (rep === 'successful') return 'green'
    if (rep === 'unsuccessful') return 'yellow'
  }

  const badgeStyle = {
    width: '120px', // fixed width for badges
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5px'
  }

  return (
    <div className='statsMain'>
      {cardData.map((item, key) => {
        return (
          <Paper withBorder className='statsCard' mb={10} m={10} key={key} h={190} bg='#fff'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ marginTop: 30, paddingLeft: 20, width:  '100%' }}>
                <Text size='lg' fw={'bold'} mb={10}> {item.title}</Text>
                {item.chatrep?.map((rep, key) => {
                  return (
                    <div key={key}>
                      <Badge p={8} color={getColor(rep)} style={badgeStyle}>
                       <Text size='12px' fw={'bold'} >{rep}</Text> 
                      </Badge>
                    </div>
                  )
                })}
               
                {item.count && <Title  style={{
  fontSize: 'clamp(16px, 5vw, 26px)'
  }} size={40} c="#000">{item.count}</Title>}
                {item.description && <Badge variant='default' size='sm' c={primary_color} mt={20}>  
                 {item.description}
                </Badge>}
              </div>

              <div style={{ display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                {item.icon && <div style={{ marginTop: 20, paddingRight: 20 }}>
                  {item.icon}
                </div>}
                {item.chat}
                {/* {item.usage && <Badge color={primary_color} mr={10}>
                <Text size='12px' fw={'bold'} >
                successful: {item.usage}</Text> 
                  </Badge>} */}
              </div>
            </div>
          </Paper>
        )
      })}
    </div>
  )
}
