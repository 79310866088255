import { useEffect, useState } from 'react'
import { getPaymentInfo } from 'services'
import { UsePaymentInfoState } from 'types'


export default function usePaymentInfo() {
  const [cardInfo, setCardInfo] = useState<UsePaymentInfoState>({
    data: undefined,
    error: null,
    loading: true
  })

  const fetchPaymentInfo = async () => {
    try {
      const data = await getPaymentInfo()

      if (Array.isArray(data)) {
        setCardInfo({
          data,
          error: null,
          loading: false
        })
      } else {
        setCardInfo({
          data: undefined,
          error: 'Unexpected response format',
          loading: false
        })
      }
    } catch (error) {
      setCardInfo({
        data: undefined,
        error: error instanceof Error ? error.message : 'An unexpected error occurred',
        loading: false
      })
    }
  }

  useEffect(() => {
    fetchPaymentInfo()
  }, [])

  return  {
    ...cardInfo,
    refresh: fetchPaymentInfo
  }
}
