export const ROUTES = {
	HOME: '/',
	// ADMIN_DASHBOARD: ROUTES.HOME,
	// DEVELOPER: '/developer',
	LOGIN: '/login',
	REGISTER: '/register',
	VERIFY_EMAIL: '/verify-email',
	RESET_PASSWORD: '/reset-password',
	NOTFOUND: '*',
	ERROR: '/error',
	SUBSCRIPTION: '/subscription-session-results',
	// PRICING: '/pricing',
	PAYMENT_METHOD: '/payment-method-creation-results',
}

export const planNames = ['Freemium','Basic Tier','Advanced Tier','Premium Tier']