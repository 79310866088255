
import '../../../styles/apploader.css'
import logo from 'assets/logo/bibot-high-resolution-logo-black-transparent.png'

export default function Loader () {
	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
			<div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img src={logo} />
    </div>
		</div>
	)
}
