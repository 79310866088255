import { useState, ChangeEvent, useEffect } from 'react'
import { createStyles } from '@mantine/emotion'
import { TeamUserType } from 'types'
import {
  activateUsers,
  createTeamMember,
  deactivateUser,
  deleteTeamMember,
  listTeamMembers,
  updateTeamMemberAttributes,
} from 'services'
import { message } from 'antd'

const useStyles = createStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: theme.spacing.md,
  },
  th: {
    backgroundColor: theme.colors.gray[1],
    padding: theme.spacing.sm,
    borderBottom: `2px solid ${theme.colors.gray[3]}`,
    textAlign: 'left',
  },
  td: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  actions: {
    display: 'flex',
    gap: theme.spacing.xs,
  },
  headerRow: {
    backgroundColor: theme.colors.gray[2],
  },
  selectedRow: {
    backgroundColor: theme.colors.blue[0],
  },
}))

export const useTeams = () => {
  const { classes, cx } = useStyles()
  const [users, setUsers] = useState<TeamUserType[]>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [modalType, setModalType] = useState<'create' | 'update' | 'delete' | 'deactivate' | 'activate' | null>(null)
  const [currentUser, setCurrentUser] = useState<TeamUserType | null>(null)
  const [formValues, setFormValues] = useState<TeamUserType>({
    id: 0,
    sub: '',
    given_name: '',
    family_name: '',
    Confirmed: '',
    Active: '',
    email: '',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    listTeamMembers()
      .then((data) => {
        setUsers(data)
        setIsLoading(false)
      })
      .catch((error) => {
        message.error(error.message || 'Error fetching team members')
        setIsLoading(false)
      })
  }, [])

  const handleSelectUser = (id: number): void => {
    setSelectedUsers((prev) =>
      prev.includes(id) ? prev.filter((userId) => userId !== id) : [...prev, id]
    )
  }

  const handleOpenModal = (type: 'create' | 'update' | 'delete' | 'deactivate' | 'activate', user: TeamUserType | null = null): void => {
    setModalType(type)
    setCurrentUser(user)
    if (user) {
      setFormValues({
        id: user.id,
        sub: user.sub,
        given_name: user.given_name,
        family_name: user.family_name,
        Confirmed: '',
        Active: '',
        email: user.email,
      })
    } else {
      setFormValues({ id: Date.now(), sub: '', given_name: '', family_name: '', Confirmed: '',
      Active: '', email: '' })
    }
  }

  const handleCloseModal = (): void => {
    setModalType(null)
    setCurrentUser(null)
  }

  const handleCreateUpdateUser = (): void => {
    setIsProcessing(true)
    if (modalType === 'create') {
      createTeamMember(formValues)
        .then((res) => {
          message.success(res)
          setIsLoading(true)
          listTeamMembers()
      .then((data) => {
        setUsers(data)
        setIsLoading(false)
      })
      .catch((error) => {
        message.error(error.message || 'Error fetching team members')
        setIsLoading(false)
      })
          handleCloseModal()
        })
        .catch((error) => {
          message.error(error.message || 'Error creating team member')
        })
        .finally(() => setIsProcessing(false))
    } else if (modalType === 'update' && currentUser) {
      updateTeamMemberAttributes({ ...currentUser, ...formValues })
        .then((res) => {
          listTeamMembers()
          .then((data) => {
            setUsers(data)
            setSelectedUsers([])
            handleCloseModal()
            message.success(res)
          })
          .catch((error) => {
            message.error(error.message || 'Error fetching updated team members')
          })
          
        })
        .catch((error) => {
          message.error(error.message || 'Error updating team member')
        })
        .finally(() => setIsProcessing(false))
    }
  }

  const handleDeleteUser = (): void => {
    setIsProcessing(true)
    const usersToDelete = users.filter((user) => selectedUsers.includes(user.id))
    if (usersToDelete.length === 0 && modalType === 'delete'){
      if (!currentUser) {
        handleCloseModal()
        message.error('Please select a user to delete')
      } else {
        usersToDelete.push(currentUser)
      }
    }
    deleteTeamMember(usersToDelete)
      .then((res) => {
        listTeamMembers()
        .then((data) => {
          setUsers(data)
          setSelectedUsers([])
          handleCloseModal()
        })
        .catch((error) => {
          message.error(error.message || 'Error fetching updated team members')
        })
        message.info(res)

      })
      .catch((error) => {
        message.error(error.message || 'Error deleting team member')
      })
      .finally(() => setIsProcessing(false))
  }

  const handleDeactivateUser = (): void => {
    setIsProcessing(true)
    const usersToDeactivate = users.filter((user) => selectedUsers.includes(user.id))
    deactivateUser(usersToDeactivate)
      .then((res) => {
        listTeamMembers()
        .then((data) => {
          setUsers(data)
          setSelectedUsers([])
          handleCloseModal()
        })
        .catch((error) => {
          message.error(error.message || 'Error fetching updated team members')
        })
        message.info(res)
        handleCloseModal()
      })
      .catch((error) => {
        message.error(error.message || 'Error deactivating team member')
      })
      .finally(() => setIsProcessing(false))
  }

  const handleActivateUser = (): void => {
    setIsProcessing(true)
    const usersToActivate = users.filter((user) => selectedUsers.includes(user.id))
    activateUsers(usersToActivate)
      .then((res) => {
        listTeamMembers()
        .then((data) => {
          setUsers(data)
          setSelectedUsers([])
          handleCloseModal()
        })
        .catch((error) => {
          message.error(error.message || 'Error fetching updated team members')
        })
        message.info(res)
        handleCloseModal()
      })
      .catch((error) => {
        message.error(error.message || 'Error activating team member')
      })
      .finally(() => setIsProcessing(false))
  }

  const handleInputChange = (field: 'given_name' | 'family_name' | 'email') => (event: ChangeEvent<HTMLInputElement>): void => {
    setFormValues({ ...formValues, [field]: event.currentTarget.value })
  }

  return {
    handleOpenModal,
    selectedUsers,
    classes,
    handleSelectUser,
    modalType,
    handleCloseModal,
    formValues,
    handleInputChange,
    handleCreateUpdateUser,
    handleDeleteUser,
    handleDeactivateUser,
    handleActivateUser,
    users,
    cx,
    isLoading,
    isProcessing
  }
}
