import { SUB_ACTION_TYPE_ENUMS } from 'config'
import type { SubscriptionAction, SubscriptionState } from 'types'

export const subReducers = (state: SubscriptionState, action: SubscriptionAction): SubscriptionState => {
	switch (action.type) {
	case SUB_ACTION_TYPE_ENUMS.EXISTS:
		return {
			...state,
			exists: true,
			sub: action.sub
		}
	case SUB_ACTION_TYPE_ENUMS.NO_SUB_EXIST:
		return {
			...state,
			exists: false,
			sub: null
		}
    case SUB_ACTION_TYPE_ENUMS.GOT_PLANS:
        return {
            ...state,
            plans: action.plans
        }
	default:
		return state
	}
}
