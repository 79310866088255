import { fetchUserAttributes } from 'aws-amplify/auth'
import { domain, resources } from 'config'
import { createCoreAxiosInstance } from 'hooks'
import type {
  USER_DT,
  AmpConfig,
  AppconfigType,
  // docInfoType
} from 'types'

const coreAxiosInstance = createCoreAxiosInstance()

async function getAmpConfig(): Promise<AmpConfig> {
  try {
    const path = `${domain.app}${resources.ampConfig}`
    const response = await coreAxiosInstance.get<AmpConfig>(path)
    return response.data
  } catch (error) {
    console.error('Error fetching subscription:', error)
    return {
      userPoolId: '',
      userPoolWebClientId: '',
      domain: '',
      redirectSignIn: '',
      redirectSignOut: '',
    }
  }
}

async function saveConfigData(
  configData: AppconfigType,
  file: File | null
): Promise<string> {
  try {
    const path = `${domain.app}${resources.chatBubble}`
    const response = await coreAxiosInstance.post<{ url: string }>(
      path,
      configData
    )
    if (!file) {
      throw new Error('File is missing')
    }
    await uploadLogoToS3UsingPreSignedUr(response.data.url, file)
    return 'Your chatbot config saved successfully'
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error deleting file:', error)
      return error.message
    } else {
      return 'Error saving settings'
    }
  }
}

async function get_ChatBubble_Config() {
  try {
    const path = `${domain.app}${resources.chatBubble}`
    const response = await coreAxiosInstance.get(path)
    return response.data
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    }
  }
}

async function chat_Bubble_Banner(check: boolean) {
  const data = { powered_by_bibot: check }
  try {
    const path = `${domain.app}${resources.chatBubbleBanner}`
    const response = await coreAxiosInstance.post(
      path,
      data
    )
    return response.data
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    }
  }
}


// This is returning nothing, just OK status
async function uploadLogoToS3UsingPreSignedUr(
  pre_signed_url: string,
  file: File
): Promise<void> {
  try {
    await fetch(pre_signed_url, {
      method: 'PUT',
      body: file,
    })
  } catch (err) { /* empty */ }
}

async function getUser(): Promise<USER_DT | null> {
  try {
    const cognitoUser = await fetchUserAttributes()
    const userAttributes = cognitoUser
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const convertUserTypes = (attributes: Record<string, any>): USER_DT => {
      return {
        usr_id: attributes.sub ?? '',
        profile: attributes.profile ?? '',
        avatarUrl: attributes.profilePicture ?? '',
        given_name: attributes.given_name ?? '',
        family_name: attributes.family_name ?? '',
        email: attributes.email ?? '',
        email_verified: attributes.email_verified ?? false,
        notifications: attributes.notifications ?? [],
      }
    }

    // Ensure that userAttributes is not undefined before trying to convert
    if (userAttributes) {
      const convertedData = convertUserTypes(userAttributes)
      return convertedData
    } else {
      return null // Return null if userAttributes is undefined
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export { getAmpConfig, getUser, saveConfigData, get_ChatBubble_Config, chat_Bubble_Banner }
