import { domain, resources } from 'config'
import { createTeamsAxiosInstance } from 'hooks'
import { TeamUserType } from 'types'

const teamsAxiosInstance = createTeamsAxiosInstance()

async function activateUsers(payload: TeamUserType[]): Promise<string>{
  try {
    const path = `${domain.teams}${resources.activateUsers}`
    const response = await teamsAxiosInstance.post<{message: string}>(path, payload)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return  error.message
    }else{
      return 'Error reactivating user(s)'
    }
  }
}

async function createTeamMember(payload: TeamUserType): Promise<string>{
  try {
    const path = `${domain.teams}${resources.createUser}`
    const response = await teamsAxiosInstance.post<{message: string}>(path, payload)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return  error.message
    }else{
      return 'Error reactivating user(s)'
    }
  }
}

async function deactivateUser(payload: TeamUserType[]): Promise<string>{
  try {
    const path = `${domain.teams}${resources.deactivateUsers}`
    const response = await teamsAxiosInstance.post<{message: string}>(path, payload)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return  error.message
    }else{
      return 'Error reactivating user(s)'
    }
  }
}

async function deleteTeamMember(payload: TeamUserType[]): Promise<string>{
  try {
    const path = `${domain.teams}${resources.deleteSelectedUsers}`
    const response = await teamsAxiosInstance.post<{message: string}>(path, payload)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return  error.message
    }else{
      return 'Error reactivating user(s)'
    }
  }
}

async function listTeamMembers(): Promise<TeamUserType[]>{
  try {
    const path = `${domain.teams}${resources.listUsers}`
    const response = await teamsAxiosInstance.get<{users: TeamUserType[]}>(path)
    return response.data.users
  } catch (error) {
    if (error instanceof Error) {
      return  []
    }else{
      return []
    }
  }
}

async function updateTeamMemberAttributes(payload: TeamUserType): Promise<string>{
  try {
    const data = {
      given_name: payload.given_name,
      family_name: payload.family_name,
      email: payload.email,
      sub: payload.sub
    }
    const path = `${domain.teams}${resources.updateUserAttributes}`
    const response = await teamsAxiosInstance.post<{message: string}>(path, data)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return  error.message
    }else{
      return 'Error reactivating user(s)'
    }
  }
}



export {
  activateUsers,
  createTeamMember,
  deactivateUser,
  deleteTeamMember,
  listTeamMembers,
  updateTeamMemberAttributes
}
