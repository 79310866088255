import { signOut } from 'services'
import {
  AppShell,
  Group,
  Burger,
  Paper,
  Title,
  Avatar,
  UnstyledButton,
  Text,
  Menu,
  Collapse,
  Badge
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useContext, useState, forwardRef, useEffect } from 'react'
import '../../../styles/adminPortal.css'
import {
  // IconBell,
  IconCreditCard,
  IconFileUpload,
  IconSettings,
  IconChevronDown,
  IconPoint,
  IconMessage,
  IconLayoutDashboard,
  IconAdjustments,
  IconPlugConnected,
  IconUsers,
} from '@tabler/icons-react'
import { UserContext } from 'context'
import logo from 'assets/logo/bibot-high-resolution-logo-black-transparent.png'
import { primary_color, secondary_color } from 'styles/colors'
import { useSubscription } from 'hooks'
import NotFound from 'components/utils/NotFoundPage'
import Settings from '../3PIntegration/3rd_party_integration'
import AppSettings from '../AppConfiguration/AppSettings'
import AppIntegration from '../AppIntegration/App_Integration'
import ChatHistory from '../ChatHistory/ChatHistory'
import Statistics from '../Dashboard/Statistics'
import Subscription from '../Subscription/Subscription'
import UserManagementPage from '../Teams/UserManagementPage'
import PredefinedQuestions from '../Training/PredefinedQuestions'
import Training from '../Training/Training'
import UnAnsweredQ from '../Training/UnAnsweredQ'
import Loader from '../Auth/Loader'

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string | null;
  name: string;
  email: string;
  icon?: React.ReactNode;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, icon, ...others }, ref) => (
    <UnstyledButton
      ref={ref}
      style={{
        color: 'var(--mantine-color-text)',
        borderRadius: 'var(--mantine-radius-sm)',
      }}
      {...others}
    >
      <Group>
        <Avatar color={primary_color} src={image} radius="xl" />
        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500} className="useremail">
            {name}
          </Text>
          <Text c="dimmed" size="xs" className="useremail">
            {email}
          </Text>
        </div>
        {icon || <IconChevronDown size="1rem" />}
      </Group>
    </UnstyledButton>
  )
)

// Setting the display name
UserButton.displayName = 'UserButton'

const AdminPortal = () => {
  const [opened, { toggle }] = useDisclosure()
  const [selectedKey, setSelectedKey] = useState<string>('Dashboard')
  const { state } = useContext(UserContext)
  const { user } = state
  const [openCollapse, setOpenCollapse] = useState(false)
  const { mySubPlan } = useSubscription()



  const handleMenuItemClick = (componentName: string) => {
    if (componentName === 'Training') {
      setOpenCollapse(!openCollapse)
    }
    setSelectedKey(componentName)
    toggle()
  }

  const menuItem = [
    {
      title: 'Dashboard',
      icon: (
        <IconLayoutDashboard
          color={selectedKey === 'Dashboard' ? primary_color : '#000'}
        />
      ),
    },
    {
      title: 'App Configuration',
      icon: (
        <IconAdjustments
          color={selectedKey === 'App Configuration' ? primary_color : '#000'}
        />
      ),
    },
    {
      title: 'App Integration',
      icon: (
        <IconPlugConnected
          color={selectedKey === 'App Integration' ? primary_color : '#000'}
        />
      ),
    },
    {
      title: '3P Integration',
      icon: (
        <IconSettings
          color={selectedKey === 'Settings' ? primary_color : '#000'}
        />
      ),
    },
    {
      title: 'Training',
      icon: (
        <IconFileUpload
          color={selectedKey === 'Training' ? primary_color : '#000'}
        />
      ),
    },
    {
      title: 'Chat History',
      icon: (
        <IconMessage
          color={selectedKey === 'Chat History' ? primary_color : '#000'}
        />
      ),
    },
   
  ]
  
  // Conditionally add 'Subscription' menu item
  if (user?.usr_id === user?.profile) {
    menuItem.push({
      title: 'Subscription',
      icon: (
        <IconCreditCard
          color={selectedKey === 'Subscription' ? primary_color : '#000'}
        />
      ),
    })
    menuItem.push(
    {
      title: 'Teams',
      icon: (
        <IconUsers
          color={selectedKey === 'Teams' ? primary_color : '#000'}
        />
      ),
    }
    )
  }
  

  useEffect(() => {
    if (mySubPlan?.status === 'active') {
      setSelectedKey('Dashboard')
  } else if (mySubPlan?.status !== 'active' && user?.usr_id !== user?.profile) {
      setSelectedKey('Dashboard')
  } else {
      setSelectedKey('Subscription')
  }
  
 
  
    // Create the script element
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.text = `
      gtag('event', 'conversion', {
        'send_to': 'G-8BK6QNHFTE/qSlRCKv0r74ZELT5hvk9',
        'value': 1.0,
        'currency': 'USD',
        'transaction_id': ''
      });
    `

    // Append the script to the head
    document.head.appendChild(script)

    // Cleanup: remove the script when the component unmounts
    return () => {
      document.head.removeChild(script)
    }

  }, [mySubPlan?.status])
  
  const renderComponent = () => {
    switch (selectedKey) {
      case 'Dashboard':
        return <Statistics />
      case 'Teams':
        return <UserManagementPage/>
      case 'App Configuration':
        return <AppSettings />
      case 'Training':
        return <Training />
      case 'Chat History': 
      return <ChatHistory />
      case 'Subscription':
        return <Subscription />
      case '3P Integration':
        return <Settings />
      case 'App Integration':
        return <AppIntegration />
      case 'Unanswered Questions':
        return <UnAnsweredQ />
      case 'Predefined Questions':
        return <PredefinedQuestions />
      default:
        return <NotFound />
    }
  }

  return (
    <>
    {!mySubPlan?.status ? <Loader /> :  <AppShell
      header={{ height: 60 }}
      navbar={{ width: 250, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" px="md" justify="space-between">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            <Title
              order={3}
              // p="md"
              style={{ display: 'flex', paddingLeft: '10px', alignItems: 'center' }}
              className="hideOnMobile"
            >
              <img src={logo} style={{ width: '45px', height: '45px'}} /> <Text style={{marginLeft: 10, fontWeight: 'bold', fontSize: 20}}>BIBOT</Text>
            </Title>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingInline: '20px',
            }}
          >
            {/* <IconBell />  */}
           
            <Badge variant='light' color={primary_color}>
            <Text size='12px' fw={'bold'}>
            {user?.usr_id === user?.profile ? 'Admin' : 'Collaborator'}
            </Text>
            </Badge>
     
            &nbsp; &nbsp;
            <Menu withArrow>
              <Menu.Target>
                <UserButton
                  image={user ? user?.avatarUrl : ''}
                  name={user ? user?.given_name : ''}
                  email={user ? user?.email : ''}
                />
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>{user?.email}</Menu.Label>
                <Menu.Item
                  onClick={async () => {
                    try {
                      await signOut()
                    } catch (error: unknown) {
						// TODO: Lets do something with the error. Inform the user
					}
                  }}
                  color="red"
                >
                  Sign Out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar
        bg={'#fff'}  //EEEEEE
        p="sm"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          zIndex: 9999,
        }}
      >
        <div>
          <Title order={3} p="md" hiddenFrom="sm" style={{ display: 'flex' }}>
            BiBot
          </Title>
          {menuItem.map((list, index: number) => {
            return (
              <>
                <Paper
                 key={index}
                  className='menuOnHover'
                  bg={selectedKey === list.title ? secondary_color : 'none'}  //#f5f5f5
                  p="sm"
                  m={'xs'}
                  style={{ display: 'flex', cursor: 'pointer', color: mySubPlan?.status !== 'active' ? '#bebebe' : '#000' }}
                  onClick={ () => {
                    if (mySubPlan?.status !== 'active') {
                      return
                    }
                    handleMenuItemClick(list.title)
                  }}
                >
                  {list.icon} &nbsp; {list.title}
                </Paper>
                {list.title === 'Training' && (
                  <Collapse in={openCollapse}>
                    <Paper
                      className='menuOnHover'
                      bg={'none'}
                      onClick={() => {
                        setSelectedKey('Training')
                      }}
                      p="sm"
                      ml={'lg'}
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        justifyContent: 'flex-start',
                      }}
                    >
                          <IconPoint color={selectedKey === 'Training' ? primary_color : '#dedede'}/> <Text> Knowledge Base</Text>
                    </Paper>

                    <Paper
                      className='menuOnHover'
                      bg={'none'}
                      onClick={() => {
                        setSelectedKey('Predefined Questions')
                      }}
                      p="sm"
                      ml={'lg'}
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        justifyContent: 'flex-start',
                      }}
                      >
                          <IconPoint color={selectedKey === 'Predefined Questions' ? primary_color: '#dedede'}/>
                          <Text> Predefined Questions</Text>
                  
                    </Paper>


                    <Paper
                      className='menuOnHover'
                      bg={'none'}
                      onClick={() => {
                        setSelectedKey('Unanswered Questions')
                      }}
                      p="sm"
                      ml={'lg'}
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        justifyContent: 'flex-start',
                      }}
                      >
                          <IconPoint color={selectedKey === 'Unanswered Questions' ? primary_color: '#dedede'}/>
                          <Text> Unanswered Questions</Text>
                  
                    </Paper>
                  </Collapse>
                )}
              </>
            )
          })}
        </div>
      </AppShell.Navbar>
      <AppShell.Main  mt={20} bg='#f3f3f3'> {/**EEEEEE */}
        {renderComponent()}
      </AppShell.Main>
    </AppShell>}
    </>
   
  )
}
export default AdminPortal
