import { Card, Text, Button } from '@mantine/core'
// import { IconArrowBadgeRightFilled } from '@tabler/icons-react'
import { useSubscription } from 'hooks'
import { CardType } from 'types'
import { JSXElementConstructor, ReactElement } from 'react'
import {
  IconBriefcase,
  IconDiscountCheck,
  // IconHeart,
  IconSparkles,
} from '@tabler/icons-react'
import { primary_color } from 'styles/colors'

export default function SubscriptionCard({ plan }: CardType) {

  const { loadingPlan, handleSubscribe, selectedOption, handleOptionChange } =
    useSubscription()

  return (
    <Card
      withBorder
      style={{
        marginBlock: 40,
        marginInline: 5,
        width: '30%',
        minHeight: 450,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      p={25}
      bg="#EFEFEF"
    >
      <div>
        <div
          style={{
            backgroundColor: '#fff',
            display: 'flex',
            minHeight: 60,
            alignItems: 'center',
            width: 60,
            justifyContent: 'center',
            borderRadius: 100,
            marginBottom: 20,
          }}
        >
          {getTierIcon(plan.name)}
        </div>

        <Text>{plan.name}</Text>
        <div>
          <span
            style={{
              display: 'block',
              fontSize: '1rem',
              color: primary_color,
              fontWeight: 'bold',
            }}
          >
            {plan.price.monthlyPrice}
          </span>
          <span
            style={{
              display: 'block',
              fontSize: '0.5rem',
              color: primary_color,
              fontWeight: 'normal',
            }}
          >
            If Billed Yearly: {plan.price.yearlyPrice}
          </span>
        </div>
        {
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 0 }}>
            <input
              type="radio"
              value="monthly"
              checked={selectedOption == 'monthly'}
              onChange={() => handleOptionChange('monthly')}
            />{' '}
            &nbsp;<Text fw="bold">Monthly</Text> &nbsp;&nbsp;
            <input
              type="radio"
              value="yearly"
              checked={selectedOption == 'yearly'}
              onChange={() => handleOptionChange('yearly')}
            />{' '}
            &nbsp; <Text fw="bold">Yearly</Text>
          </div>
        }
      </div>

      <div>
        <div
          style={{
            width: '100%',
            border: '1px dashed #B8B6B6',
            marginBottom: 50,
          }}
        ></div>
        {plan.features.map((desc, index) => (
          <ul
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginBottom: 10,
            }}
          >
              <li><Text style={{ fontSize: 14 }}>{desc}</Text></li>
          </ul>
        ))}
        <Button
          disabled={!!loadingPlan}
          style={{ width: '100%' }}
          onClick={() => {
            handleSubscribe({ plan, cadence: selectedOption })
          }}
          className="btn"
          mt={30}
          variant="filled"
          color={primary_color}
          loading={loadingPlan === plan.name}
        >
          Choose {`${plan.name}`.split(' ')[0]}
        </Button>
      </div>
    </Card>
  )
}

const getTierIcon = (
  name: string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ReactElement<any, string | JSXElementConstructor<any>> => {
  switch (name) {
    // case 'Freemium':
    //   return <IconHeart size={40} color={primary_color} />
    case 'Basic Tier':
      return <IconDiscountCheck size={40} color={primary_color} />
    case 'Advanced Tier':
      return <IconBriefcase size={40} color={primary_color} />
    case 'Premium Tier':
      return <IconSparkles size={40} color={primary_color} />
    default:
      return <IconDiscountCheck size={40} color={primary_color} />
  }
}
// icon={getTierIcon(plan.name)}
