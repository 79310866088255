import { Button, Input, Loader, Paper, Title } from '@mantine/core'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import useSaveSalesforceKeys from 'hooks/3p-intergration/useSaveSalesforceKeys'
import React from 'react'
import { primary_color } from 'styles/colors'

export default function SalesForce () {
  const { form, setForm, isPasswordVisible, togglePasswordVisibility, isLoading, saveSalesforceKeys } = useSaveSalesforceKeys()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }))
  }
   return (
    <Paper style={{ width: '100%', flexDirection: 'column' }} mt={20} p={30} withBorder>
    <Title mt={20} mb={20} order={4}>Keys</Title>
    <Input type={isPasswordVisible.userName ? 'text' : 'password'} mb={20} placeholder='Username' name='userName' value={form.userName} onChange={handleInputChange} rightSectionPointerEvents='all' rightSection={
       <button onClick={()=> togglePasswordVisibility('userName')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
       {isPasswordVisible.userName ? <IconEye /> : <IconEyeOff />}
     </button>
    }/>
    <Input type={isPasswordVisible.password ? 'text' : 'password'} mb={20} placeholder='User Password' name='password' value={form.password} onChange={handleInputChange} rightSectionPointerEvents='all' rightSection={
       <button onClick={()=> togglePasswordVisibility('password')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
       {isPasswordVisible.password ? <IconEye /> : <IconEyeOff />}
     </button>
    }/>
      <Input type={isPasswordVisible.consumerKey ? 'text' : 'password'} mb={20} placeholder='Consumer Key' name='consumerKey' value={form.consumerKey} onChange={handleInputChange}  rightSectionPointerEvents='all' rightSection={
       <button onClick={()=> togglePasswordVisibility('consumerKey')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
       {isPasswordVisible.consumerKey ? <IconEye /> : <IconEyeOff />}
     </button>
    }/>
    <Input type={isPasswordVisible.consumerSecret ? 'text' : 'password'} mb={20} placeholder='Consumer Secret' name='consumerSecret' value={form.consumerSecret} onChange={handleInputChange} rightSectionPointerEvents='all' rightSection={
       <button onClick={()=> togglePasswordVisibility('consumerSecret')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
       {isPasswordVisible.consumerSecret ? <IconEye /> : <IconEyeOff />}
     </button>
    }/>
  
    <Button disabled={isLoading} onClick={saveSalesforceKeys} variant='filled' color={primary_color}> {isLoading ? <Loader size={'sm'} color={primary_color} />  : 'Save'}</Button>
    </Paper>
  )
}