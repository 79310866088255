import React from 'react'

interface iconColor{
    color: string | undefined
  }

function SendMessageIcon( {color}: iconColor) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='icon icon-tabler icon-tabler-send-2'
      width='30'
      height='30'
      viewBox='0 0 24 24'
      strokeWidth='1'
      stroke={color}
      fill='#e8e8e8'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z' />
      <path d='M6.5 12h14.5' />
    </svg>
  )
}

// function DefaultBotProfile() {
//   return (
//     <svg
//       xmlns='http://www.w3.org/2000/svg'
//       className='icon icon-tabler icon-tabler-robot-face'
//       width='24'
//       height='24'
//       viewBox='0 0 24 24'
//       strokeWidth='1.5'
//       stroke='#fff'
//       fill='none'
//       strokeLinecap='round'
//       strokeLinejoin='round'
//     >
//       <path stroke='none' d='M0 0h24v24H0z' fill='none' />
//       <path d='M6 5h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2z' />
//       <path d='M9 16c1 .667 2 1 3 1s2 -.333 3 -1' />
//       <path d='M9 7l-1 -4' />
//       <path d='M15 7l1 -4' />
//       <path d='M9 12v-1' />
//       <path d='M15 12v-1' />
//     </svg>
//   )
// }

// function ChatIcon() {
//   return (
//     <svg
//       xmlns='http://www.w3.org/2000/svg'
//       className='icon icon-tabler icon-tabler-message-dots'
//       width='40'
//       height='40'
//       viewBox='0 0 24 24'
//       strokeWidth='1.5'
//       stroke='#fff'
//       fill='none'
//       strokeLinecap='round'
//       strokeLinejoin='round'
//     >
//       <path stroke='none' d='M0 0h24v24H0z' fill='none' />
//       <path d='M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4' />
//       <path d='M12 11l0 .01' />
//       <path d='M8 11l0 .01' />
//       <path d='M16 11l0 .01' />
//     </svg>
//   )
// }

// function CloseIcon (){
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
//   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
//   <path d="M18 6l-12 12" />
//   <path d="M6 6l12 12" />
// </svg>
//   )
// }
export { SendMessageIcon }
