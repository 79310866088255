import {
  // Space,
  TextInput,
  Textarea,
  Title,
  Button,
  Paper,
  Text,
  Skeleton,
  Modal,
  Alert
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconAlertCircle, IconEdit, IconStar, IconTrash } from '@tabler/icons-react'
import { UserContext } from 'context'
import React, { useContext, useEffect, useState } from 'react'
import { deletePredefinedQuestions, getPredefinedQuestions, savePredefinedQuestions, updatePredefinedQuestions } from 'services'
import { PreDefinedQuestions, question } from 'types'
import '../../../styles/predefinedStyle.css'
import WarningModal from 'components/utils/modal'
import Spinner from 'components/utils/spinner'
import { notification } from 'antd'
import { primary_color } from 'styles/colors'

interface predefinedQEditType {
  question: string
  answer: string
  id: string | undefined
}
export default function PredefinedQuestions() {
  const { state } = useContext(UserContext)
  const { user } = state
  const [predefinedQ, setPredefinedQ] = useState({question: '', answer: '' })
  const [predefinedQEdit, setPredefinedQEdit] = useState<predefinedQEditType>({id: '', question: '', answer: '' })
  const [questions, setQuestions] = useState<PreDefinedQuestions[]|[]>()
  const [opened, { open, close }] = useDisclosure(false)
  const [updateLoader, setUpdateLoader]= useState(false)
  const [deleteWarningModal, setDeleteWarningModal]= useState(false)

  //   TODO: Emmanuel use a list of loadingStates instead of creating a new loadState every time as below
  // const [loadingStates, setLoadingStates] = useState<Record<any, boolean>>({})
  // setLoadingStates(prev => ({ ...prev, [record.row_key]: true }))
  const [loadQ, setLoadQ] = useState(false)
  const [loadQSave, setLoadQSave] = useState(false)


  //Function to save predefined questions
  function savePredefinedQFunc() {
    setLoadQSave(true)
    if (predefinedQ.question === '' || predefinedQ.answer === '') {
      setLoadQSave(false)
      notification.info({
				message: '',
				description: 'Field can\'t be blank'
			})
      return
    }
    savePredefinedQuestions(predefinedQ)
      .then((res) => {
        if (res) {
          setLoadQSave(false)
          notification.info({
            message: '',
            description: res.message
          })
          setPredefinedQ({question: '', answer: '' })
          getPredefinedQuestionsFunc()
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setLoadQSave(false)
      })
  }

  //Function to get predefined questions
  function getPredefinedQuestionsFunc() {
    setLoadQ(true)
    setQuestions([])
    try {
      if (user?.profile)
      {getPredefinedQuestions({client_id: user?.profile})
        .then((predefined_questions) => {
          if (predefined_questions) {
            setLoadQ(false)
            setQuestions(predefined_questions)
          }
        })}
        
    } catch (error) {
      setLoadQ(false)
    } 
  }

  //Function to handle store nnew predefined input field text change
  const handleStorePredefinedTextChnage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target
    setPredefinedQ((prevQ) => ({
      ...prevQ,
      [name]: value,
    }))
  }

  //Function to handle edit modal input field text change
  const handleEditPredefinedTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target
    setPredefinedQEdit((prevQ) => ({
      ...prevQ,
      [name]: value,
    }))
  }

  //Function triggers edit modal
  const openEditModal = (predefined: question)=> {
    open()
  setPredefinedQEdit({id: predefined.q_n_a_id, question: predefined.question, answer: predefined.answer})
  }

  //Function to update predefined question
  const updatePredefinedQ = ()=> {
    setUpdateLoader(true)
    updatePredefinedQuestions({q_n_a_id: predefinedQEdit.id, question: predefinedQEdit.question, answer: predefinedQEdit.answer})
    .then((message)=> {
      setPredefinedQEdit({id: '', question: '', answer: ''})
      setUpdateLoader(false)
      getPredefinedQuestionsFunc()
      close()
      notification.info({
        message: '',
        description: message
      })
    }).catch((error: unknown)=> {
        setUpdateLoader(false)
      close()
      if (error instanceof Error) {
        notification.info({
          message: '',
          description: error.message
        })
      } else {
        notification.info({
          message: '',
          description: 'An error occurred while updating the predefined question.'
        })
      }
    })
  }

    //Function to open warning to delete modal for predefined question
 const OpenDeletePredefinedQModal = (predefined: question) => {
  setPredefinedQEdit({id: predefined.q_n_a_id, question: predefined.question, answer: predefined.answer})
  setDeleteWarningModal(true)
 }

   //Function to delete predefined question
   const DeletePredefinedQ = () => {
    setUpdateLoader(true)
    if (predefinedQEdit.id) {
      deletePredefinedQuestions({ question: predefinedQEdit.id })
        .then((message) => {
         setUpdateLoader(false)
          setDeleteWarningModal(false)
          getPredefinedQuestionsFunc()
          notification.info({
            message: '',
            description: message
          })
        })
        .catch((err: unknown)=> {
            setUpdateLoader(false)
          close()
          if (err instanceof Error) {
            notification.error({
              message: '',
              description: err.message
            })
          } else {
            notification.error({
              message: '',
              description: 'An error occurred while deleting the predefined question.'
            })
          }
        })
        .finally(() => {
          setDeleteWarningModal(false)
         setUpdateLoader(false)
        })
    }
  }
  

  useEffect(() => {
    getPredefinedQuestionsFunc()
  }, [])

  return (
    <>
    <WarningModal
        loadDeleting={updateLoader}
        onCancel={() => {
          setDeleteWarningModal(!deleteWarningModal)
        }}
        confirmButtonText='Delete'
        onConfirm={() => {
          DeletePredefinedQ()
        }}
        title={<Title order={5} style={{ color: '#000'}}>Delete Question</Title>}
        content="Are you sure you want to delete this question?"
        openModal={deleteWarningModal}
        closeModal={() => {
          setDeleteWarningModal(!deleteWarningModal)
        }}
      />
    <Modal zIndex='99999' opened={opened} size={'lg'} centered onClose={close} title={<Title order={5} style={{color: '#000'}}>Update Predefined Question</Title>}>
    <TextInput
          name="question"
          value={predefinedQEdit.question}
          onChange={handleEditPredefinedTextChange}
          label="Questions"
          mb={10}
          placeholder="Question"
        />
        <Textarea
          name="answer"
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleEditPredefinedTextChange(event)
          }}
          value={predefinedQEdit.answer}
          autosize
          mb={20}
          minRows={10}
          label="Answer"
          placeholder="Answer"
        />
        <Button variant='filled' color={primary_color} onClick={updatePredefinedQ}>{updateLoader ? 'Updating...' : 'Update'}</Button>
      </Modal>

      <Title order={3}>Predefined Questions</Title>
      <Alert mt={5} icon={<IconAlertCircle size={16} />} color={primary_color}> Define your predefined questions with answers.<span style={{color: 'green'}}> Available to only advanced and premium clients.</span> Limits apply based on your subscription plan.
        </Alert>

        <Paper w={'100%'} className='predefinedContent' mt={20} p={30} withBorder>
    
<div className='questionAndAnswerInput'>
<TextInput
  name="question"
  value={predefinedQ.question}
  onChange={handleStorePredefinedTextChnage}
  label="Questions"
  mb={10}
  placeholder="Question"
/>
<Textarea
  name="answer"
  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleStorePredefinedTextChnage(event)
  }}
  value={predefinedQ.answer}
  autosize
  mb={20}
  minRows={10}
  label="Answer"
  placeholder="Answer"
/>

<Button
  disabled={loadQSave}
  onClick={() => {
    savePredefinedQFunc()
  }}
  variant="filled"
  style={{ width: '100%'}}
  color={primary_color}
>
  {loadQSave ? <Spinner /> : 'Save'}
</Button>
</div>



<div className='questionList'>
{/* <Space h={30} /> */}
<Paper withBorder bg={'#efefef'} h={'100%'} p="xl">
    <Title order={3}>Questions List</Title>
  {loadQ ? (
    <div style={{marginBlock: '10px'}}>
    <Skeleton mb={10} visible={loadQ} p={20}></Skeleton>
    <Skeleton  mb={10} visible={loadQ} p={20}></Skeleton>
    <Skeleton visible={loadQ} p={20}></Skeleton>
    </div>
  ) : (
    questions?.map((predefined, index) => {
      return (
        <Paper
          key={index}
          p={10}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingInline: '20px',
            marginBlock: '10px',
            cursor: 'pointer'
          }}
        >
         <div>
         <Text>{predefined.question}</Text> 
          </div>
          <div>
            <IconStar size={20} style={{ marginInline: '5px'}} />
            <IconEdit onClick={()=> openEditModal(predefined)} size={20} style={{ marginInline: '5px'}} />
            <IconTrash onClick={()=> OpenDeletePredefinedQModal(predefined)} size={20} style={{ marginInline: '5px'}} color='red'/>
          </div>
        </Paper>
      )
    })
  )}
</Paper>
</div>

        </Paper>
     
    
    </>
    
  )
}














