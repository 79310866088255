import ReactDOM from 'react-dom/client'
import { AppProvider, SubscriptionProvider, UserProvider } from 'context'
import './styles/index.css'
import App from './App'
import { reportWebVitals } from './reportWebVitals'
import { loadAmpConfig } from 'config'
import { Amplify } from 'aws-amplify'

async function configureAndStartApp (): Promise<void> {
	const ampConfig = await loadAmpConfig()
	Amplify.configure(ampConfig)

	const root = ReactDOM.createRoot(
    document.getElementById('root')!
	)
	root.render(
		<AppProvider>
			<UserProvider>
				<SubscriptionProvider>
				<App />
				</SubscriptionProvider>
			</UserProvider>
		</AppProvider>
	)
}

configureAndStartApp().catch(console.error)

reportWebVitals()
