import { Card, Text, Title, Button, Modal, Group } from '@mantine/core'
// import { IconArrowBadgeRightFilled } from '@tabler/icons-react'
import { useSubscription } from 'hooks'
import { CardType } from 'types'
import { JSXElementConstructor, ReactElement, useContext, useState } from 'react'
import {
  IconBriefcase,
  IconDiscountCheck,
  // IconHeart,
  IconSparkles,
} from '@tabler/icons-react'
import { primary_color } from 'styles/colors'
import { SubscriptionContext } from 'context'

type UpdateSubscriptionCardProps = {
  plan: CardType['plan'];
  currentPlanName: string;
};

export default function UpdateSubscriptionCard({
  plan, currentPlanName
}: UpdateSubscriptionCardProps) {
    const { state } = useContext(SubscriptionContext)
  const { loadingPlan, handleChangePlan, selectedOption, handleOptionChange, handleSubscribe } = useSubscription()
  const [modalOpened, setModalOpened] = useState(false)

  const upgradeOrDowngradeText = upgradeOrDowngrade(currentPlanName, plan.name)
  const handleButtonClick = () => {
    setModalOpened(true)
  }

  const handleConfirmChange = () => {
    handleChangePlan({ plan, cadence: selectedOption })
    setModalOpened(false)
  }

  const getRelevantPrice = () => {
    return selectedOption === 'monthly' ? plan.price.monthlyPrice : plan.price.yearlyPrice
  }

  return (
    <>
      <Card
        withBorder
        style={{
          marginBlock: 40,
          marginInline: 5,
          width: '30%',
          minHeight: 450,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        p={25}
        bg="#EFEFEF"
      >
        <div>
          <div
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              minHeight: 60,
              alignItems: 'center',
              width: 60,
              justifyContent: 'center',
              borderRadius: 100,
              marginBottom: 20,
            }}
          >
            {getTierIcon(plan.name)}
          </div>

          <Text>{plan.name}</Text>
          <Title order={2} c="#000">
            {getRelevantPrice()}
          </Title>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 0 }}>
            <input
              type="radio"
              value="monthly"
              checked={selectedOption == 'monthly'}
              onChange={() => handleOptionChange('monthly')}
            />{' '}
            &nbsp;<Text fw="bold">Monthly</Text> &nbsp;&nbsp;
            <input
              type="radio"
              value="yearly"
              checked={selectedOption == 'yearly'}
              onChange={() => handleOptionChange('yearly')}
            />{' '}
            &nbsp; <Text fw="bold">Yearly</Text>
          </div>
        </div>

        <div>
          <div
            style={{
              width: '100%',
              border: '1px dashed #B8B6B6',
              marginBottom: 50,
            }}
          ></div>
          {plan.features.map((desc, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: 10,
              }}
            >

               <ul style={{ marginBottom: 0}}>
                <li>{desc}</li>
               </ul>
            </div>
          ))}
          {!state.sub?.canceled ? (
  <Button
    disabled={upgradeOrDowngradeText === 'CURRENT PLAN' || upgradeOrDowngradeText === 'CONTACT US TO DOWNGRADE'}
    style={{ width: '100%' }}
    onClick={handleButtonClick}
    className="btn"
    mt={30}
    variant="filled"
    color={primary_color}
    loading={loadingPlan === plan.name}
  >
    {upgradeOrDowngradeText}
  </Button>
) : (
  <Button
    disabled={!!loadingPlan}
    style={{ width: '100%' }}
    onClick={() => handleSubscribe({ plan, cadence: selectedOption })}
    className="btn"
    mt={30}
    variant="filled"
    color={primary_color}
    loading={loadingPlan === plan.name}
  >
    Resubscribe
  </Button>
)}

        </div>
      </Card>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={`Confirm ${upgradeOrDowngradeText}`}
        centered
      >
        <Text>Are you sure you want to {upgradeOrDowngradeText.toLowerCase()} to the {plan.name} plan?</Text>
        <Text style={{ fontSize: 18, fontWeight: 700, marginTop: 10 }}>New price: {getRelevantPrice()}</Text>
        <Text style={{ fontSize: 18, fontWeight: 700, marginTop: 10 }}>Renewal Intervals: {selectedOption.toUpperCase()}</Text>
        <Group mt="md">
          <Button onClick={handleConfirmChange} color={primary_color}>
            Confirm
          </Button>
          <Button onClick={() => setModalOpened(false)} variant="outline">
            Cancel
          </Button>
        </Group>
      </Modal>
    </>
  )
}

const upgradeOrDowngrade = (currentPlanName: string, cardPlanName: string): 'UPGRADE' | 'CONTACT US TO DOWNGRADE' | 'CURRENT PLAN' => {
  const cardPlanNames = ['Basic Tier', 'Advanced Tier', 'Premium Tier']
  const currentPlanNames = ['basic', 'advanced', 'premium']

  const currentPlanIndex = currentPlanNames.indexOf(currentPlanName.toLowerCase())
  const cardPlanIndex = cardPlanNames.indexOf(cardPlanName)

  if (cardPlanIndex > currentPlanIndex) return 'UPGRADE'
  if (cardPlanIndex < currentPlanIndex) return 'CONTACT US TO DOWNGRADE'

  return 'CURRENT PLAN'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTierIcon = (name: string): ReactElement<any, string | JSXElementConstructor<any>> => {
  switch (name) {
    // case 'Freemium':
    //   return <IconHeart size={40} color={primary_color} />
    case 'Basic Tier':
      return <IconDiscountCheck size={40} color={primary_color} />
    case 'Advanced Tier':
      return <IconBriefcase size={40} color={primary_color} />
    case 'Premium Tier':
      return <IconSparkles size={40} color={primary_color} />
    default:
      return <IconDiscountCheck size={40} color={primary_color} />
  }
}
