// import { useEffect, useContext, useCallback, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppRoutes } from 'routes/Routes'
import './App.css'
import '@mantine/core/styles.css'
import '@mantine/charts/styles.css'
import '@mantine/dates/styles.css'
import {createTheme, MantineProvider } from '@mantine/core'
import { BiBot } from 'bibot'
// import { getIdToken } from 'utils'
// import { UserContext } from 'context'
// import { USER_ACTION_TYPE_ENUMS } from 'config'
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion'

const App: React.FC=() => {
    // const { state, dispatch }=useContext(UserContext)
    const isProd=process.env.REACT_APP_ENV==='prod'
    const clientId=isProd
        ? 'b4d8e408-3091-700d-3e46-794b7ac2049b'
        :'3f78da71-11c4-4a0b-833a-f079faa269d3'
    const test=!isProd

    const theme = createTheme({
        breakpoints: {
          xs: '30em',
          sm: '48em',
          md: '64em',
          lg: '74em',
          xl: '90em',
        },
      })

      
    // const [showBiBot, setShowBiBot]=useState(false)

    // const fetchToken=useCallback(async () => {
    //     const token=await getIdToken()
    //     if (token) {
    //         dispatch({ type: USER_ACTION_TYPE_ENUMS.SET_JWT, jwt: token })
    //     }
    // }, [state.jwt, state.isAuthenticated])

    // useEffect(() => {
    //     void fetchToken()
    // }, [fetchToken])

    // useEffect(() => {
    //     const timer=setTimeout(() => {
    //         setShowBiBot(true)
    //     }, 5000) // 3 seconds delay

    //     return () => clearTimeout(timer)
    // }, [])

    return (
        <MantineProvider theme={theme} stylesTransform={emotionTransform}>
            <MantineEmotionProvider>
                <Router>
                    <AppRoutes />
                </Router>
                <BiBot clientId={clientId} test={test} internalUserOnly={{
                            internalUseOnly: false,
                        }} />
                {/* {showBiBot&&
                    (state.jwt? (
                        <BiBot clientId={clientId} test={test} internalUserOnly={{
                            internalUseOnly: true,
                            jwt: state.jwt
                        }} />
                    ):(

                    ))} */}
            </MantineEmotionProvider>
        </MantineProvider>
    )
}

export default App
