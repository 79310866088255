import { domain, resources } from 'config'
import { createSubscriptionAxiosInstance } from 'hooks'
import { Plan, SubscriptionDetails, SubscriptionPlans, cardDataProp, defaultCardProp, detachPaymentMethodProp, paymentInfoType } from 'types'

const subscriptionAxiosInstance = createSubscriptionAxiosInstance()

async function getSubscriptionPlans(): Promise<SubscriptionPlans[]> {
  try {
    const path = `${domain.subscriptions}${resources.plans}`
    const response = await subscriptionAxiosInstance.get<SubscriptionPlans[]>(path)
    return response.data
  } catch (error) {
    console.error('Error getting subscription plans:', error)
    return []
  }
}

async function getMySubscription(): Promise<SubscriptionDetails> {
  try {
    const path = `${domain.subscriptions}${resources.myPlan}`
    const response = await subscriptionAxiosInstance.get<SubscriptionDetails>(path)
    return response.data
  } catch (error) {
    console.error('Error getting subscription plans:', error)
    return { }
  }
}


async function getPaymentInfo(){
  try {
    const path = `${domain.subscriptions}${resources.paymentInfo}`
    const response = await subscriptionAxiosInstance.get<paymentInfoType[]>(path)
    return response.data
  } catch (error) {
    console.error('Error getting payment info:', error)
      return error
  }
}

async function createSubscribeSession (data: { plan: Plan, cadence: 'monthly' | 'yearly' }): Promise<{ message: string, location: Location | null | undefined }> {
  try {
    const path = `${domain.subscriptions}${resources.checkout}`
    const response = await subscriptionAxiosInstance.post(path, data)
    const responseData = response.data
    const message: string = responseData.message
    const location: Location = responseData.location
    return { message, location }
  } catch (error: unknown) {
    if (error instanceof Error) {
      return { message: error.message, location: undefined }
    }else{
      console.error('Error creating subscription session:', error)
      return { message: 'Error creating subscription session', location: undefined }
    }
    
  }
}

async function changeSubscriptionPlan (data: { plan: Plan, cadence: 'monthly' | 'yearly' }): Promise<{ message: string, location: Location | null | undefined }>  {
  try {
    const path = `${domain.subscriptions}${resources.update}`
    const response = await subscriptionAxiosInstance.post(path, data)
    const responseData = response.data
    const message: string = responseData.message
    const location: Location = responseData.location
    return { message, location }
  } catch (error: unknown) {
    if (error instanceof Error) {
      return { message: error.message, location: undefined }
    }else{
      console.error('Error updating subscription:', error)
      return { message: 'Error updating subscription', location: undefined }
    }
  }
}

async function cancelSubscription(){
  try {
    const path = `${domain.subscriptions}${resources.cancelSubscription}`
    const response = await subscriptionAxiosInstance.post(path)
    return response.data
  } catch (error) {
    console.error('Error canceling subscription', error)
      return error
  }
}


async function setDefaultCard(data: defaultCardProp){
  try {
    const path = `${domain.subscriptions}${resources.defaultCard}`
    const response = await subscriptionAxiosInstance.post(path, data)
    return response.data
  } catch (error) {
    console.error('Error setting default payment method:', error)
      return error
  }
}


async function updateCard(cardData: cardDataProp){
  try {
    const path = `${domain.subscriptions}${resources.updateCard}`
    const response = await subscriptionAxiosInstance.post(path, cardData)
    return response.data
  } catch (error) {
    console.error('Error updating card:', error)
      return error
  }
}

async function createPaymentMethod(){
  try {
    const path = `${domain.subscriptions}${resources.createPaymentMethod}`
    const response = await subscriptionAxiosInstance.post(path)
    return response.data
  } catch (error) {
    console.error('Error creating payment method:', error)
      return error
  }
}


async function detachCard(id: detachPaymentMethodProp){
  try {
    const path = `${domain.subscriptions}${resources.detachCard}`
    const response = await subscriptionAxiosInstance.post(path, id)
    return response.data
  } catch (error) {
    console.error('Error detaching card:', error)
      return error
  }
}

export {
  getSubscriptionPlans,
  getMySubscription,
  createSubscribeSession,
  changeSubscriptionPlan,
  getPaymentInfo,
  cancelSubscription,
  setDefaultCard,
  updateCard,
  createPaymentMethod,
  detachCard
}