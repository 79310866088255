import { domain, resources } from 'config'
// import { chatData } from 'data/chathistory'
import { createStatisticsAxiosInstance } from 'hooks'
import { KbFile, KbListResponse, StatisticsP, chatDataProps } from 'types'
// import { Stat } from 'types/statistics'

const statisticsAxiosInstance=createStatisticsAxiosInstance()

async function getKbList(): Promise<KbFile[]> {
    try {
        const path=`${domain.statistics}${resources.list}`
        const response=await statisticsAxiosInstance.get<KbListResponse>(path)
        return response.data.list_of_kb_files
    } catch (error) {
        console.error('Error getting KB list:', error)
        return []
    }
}
async function getRawTextForKb(parameters: { file_name: string }): Promise<string> {
    try {
        const path=`${domain.statistics}${resources.rawKb}`
        const response=await statisticsAxiosInstance.get(path, { params: parameters })
        return response.data.raw_text
    } catch (error) {
        if (error instanceof Error) {
            console.error('Error getting file:', error)
            return error.message
        } else {
            return 'Error getting file'
        }
    }
}

async function getChatSessions(): Promise<chatDataProps> {
    try {
        const path=`${domain.statistics}${resources.chat_sessions}`
        const response=await statisticsAxiosInstance.get<chatDataProps>(path)
        return response.data
    } catch (error) {
        console.error('Error getting Chat Sessions:', error)
        return {
            chat_history: [],
            next_token: null,
            session_id: ''
        }
    }
}

async function getChatHistory(parameters: { session_id: string }, headers: { 'X-Continuation-Token': string | null }): Promise<chatDataProps> {
    try {
        const path = `${domain.statistics}${resources.chat_history}`
        const response = await statisticsAxiosInstance.get<chatDataProps>(path, {
            params: parameters,
            headers: headers,
        })
        return response.data
    } catch (error) {
        console.error('Error getting Chat History:', error)
        return {} as chatDataProps
    }
}

async function getStatistics(): Promise<StatisticsP> {
    try {
        const path=`${domain.statistics}${resources.listStatistics}`
        const response=await statisticsAxiosInstance.get<StatisticsP>(path)
        return response.data
    } catch (error) {
        console.error('Error getting Chat History:', error)
        return {
            faq_questions: [],
            kb_usage_fail: 0,
            kb_usage_success: 0,
            jira_usage: 0,
            sf_usage: 0,
            mim_usage_success: 0,
            kb_counts: 0
        }
    }
}




// const stats:Stat = {
//     chatUsage: {
//      percentage: 20,
//      count: '16/100'
//     }, 
//     mimUsage: 10 ,
//     jiraUsage: 40 ,
//     salesForceUsage: 14, 
//    kbUsageHistory: [
//      { date: 'Jan', success: 25, failed: 2 },
//      { date: 'Feb', success: 30000, failed: 0  },
//      { date: 'Mar', success: 10000, failed: 1 },
//      { date: 'Apr', success: 15000, failed: 4},
//      { date: 'May', success: 30, failed: 2 },
//      { date: 'Jun', success: 15, failed: 6 },
//      { date: 'Jul', success: 30, failed: 2 },
//      { date: 'Aug', success: 40, failed:1 },
//      { date: 'Sep', success: 15, failed: 4 },
//      { date: 'Oct', success: 20, failed: 5 },
//      { date: 'Nov', success: 10, failed: 1 },
//      { date: 'Dec', success: 10, failed: 0},
//    ]
//  }

//  async function getStat():Promise<Stat> {
  
//     try {
//         // const path=`${domain.statistics}${resources.listStatistics}`
//         // const response=await statisticsAxiosInstance.get<StatisticsP>(path)
//         return stats
//     } catch (error) {
//         console.error('Error:', error)
//         return stats
//     }
// }


export {
    getKbList,
    getChatHistory,
    getStatistics,
    getRawTextForKb,
    getChatSessions,
    // getStat
}
