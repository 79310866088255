import React, { createContext, useCallback, useEffect, useReducer } from 'react'
import { Hub } from 'aws-amplify/utils'
import type { UserState, UserProviderProps, UserContextProps, USER_DT } from 'types'
import { userReducer } from 'reducers'
import { USER_ACTION_TYPE_ENUMS } from 'config'
import { getUser } from 'services'
const dummyDispatch = () => {} // A no-op function.

const defaultContextValue: UserContextProps = {
	state: {
		isAuthenticated: false,
		loading: true
	},
	dispatch: dummyDispatch
}

const UserContext = createContext<UserContextProps>(defaultContextValue)

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const initialState: UserState = {
		isAuthenticated: false,
		loading: true 
	}

	const handleGetUser = useCallback(async () => {
		dispatch({ type: USER_ACTION_TYPE_ENUMS.START_LOADING })
		const serviceUserData: USER_DT | null = await getUser()
		if (serviceUserData) {
			dispatch({ type: USER_ACTION_TYPE_ENUMS.AUTHENTICATED, user: serviceUserData })
		}else {
            dispatch({ type: USER_ACTION_TYPE_ENUMS.USER_NOT_FOUND })
        }
	}, [])
	const [state, dispatch] = useReducer(userReducer, initialState)
	useEffect(() => {
		void handleGetUser()
	}, [handleGetUser])

	useEffect(() => {
		const removeListener = Hub.listen('auth', async ({ payload }) => {
			switch (payload.event) {
			case USER_ACTION_TYPE_ENUMS.AUTHENTICATED:
				try {
					void handleGetUser()
					break
				} catch (error) {
					break
				}
			case USER_ACTION_TYPE_ENUMS.SIGN_OUT:
				dispatch({ type: USER_ACTION_TYPE_ENUMS.SIGN_OUT, user: null })
				break
			default:
				break
			}
		})

		return () => { removeListener() }
	}, [dispatch, handleGetUser])

	return (
		<UserContext.Provider value={{ state, dispatch }}>
			{children}
		</UserContext.Provider>
	)
}

export { UserContext, UserProvider }
