import React, { useEffect, useState } from 'react'
import { Table, Badge, Text, Title, Button, Modal, Skeleton, Loader } from '@mantine/core'
import { IconEye, IconEdit, IconTrash, IconPlus, IconFile, IconUpload, IconRefresh } from '@tabler/icons-react' // Import the down arrow icon
import { primary_color } from 'styles/colors'
import { deleteKbList, getKbList, getRawTextForKb, updateKbList, uploadFileToS3UsingPreSignedUr } from 'services'
import { notification } from 'antd'
import { useDisclosure } from '@mantine/hooks'
import { KbFile } from 'types'
import Spinner from './spinner'
import WarningModal from './modal'
import { TableLoader } from './TableLoader'

interface Doc {
  last_modified?: string
  word_count?: string
  id?: number
  name?: string
  status?: string
  original_file_name?: string
  source?: string
}

interface loadTableDataProp{
    refreshTableData: boolean
}


const CollapsibleRowTable: React.FC<loadTableDataProp> = ({refreshTableData}) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({})
  const [textModalOpened, setTextModalOpened] = useState(false)
  const [rawText, setRawText] = useState('')
  const [fileToUpdate, setFileToUpdate] = useState('')
  const [warningModal, setWarningModal] = useState(false)
  const [fileToDelete, setFileToDelete] = useState('')
  const [loadingText, setLoadingText] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [opened, { open, close }] = useDisclosure(false)
  const [docUpdate, setDocUpdate] = useState<File | null>(null)
  const [updating, setUpdating] = useState(false)
  const [tableDataLoading, setTableDataLoading] = useState(false)
  const [KBlistData, setKBlistData] = useState<Doc[]>([])

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index] // Toggle the clicked row's state
    }))
  }


  const updateFile = (name: string) => {
    setFileToUpdate(name)
    // Assume open() is defined somewhere
    open() 
  }

  const deleteFileWarning = (name: string) => {
    setFileToDelete(name)
    setWarningModal(true)
  }

  const deleteFile = async () => {
    setIsDeleting(true)
    const res = await deleteKbList({ file_name: fileToDelete })
    setWarningModal(false)
    setIsDeleting(false)
    notification.info({
      message: '',
      description: res,
    })
    loadTableKbData()
    setIsDeleting(false)
  }
  

  const viewRenderedRawText = async (fileName: string) => {
    setTextModalOpened(true)
    setLoadingText(true)
    const rawTextFromFile = await getRawTextForKb({ file_name: fileName })
    setRawText(rawTextFromFile)
    setLoadingText(false)
    // setTextModalOpened(true)
  }

  const DocData = KBlistData?.map((doc, index) => (
    <React.Fragment key={index}>
      <Table.Tr>
        <Table.Td>
          <IconPlus size={18}
            style={{ cursor: 'pointer' }}
            onClick={() => toggleRow(index)}
            color={expandedRows[index] ? primary_color : 'gray'} // Change color based on expanded state
          />
        </Table.Td>
        <Table.Td>{tableDataLoading ? <TableLoader /> : doc.last_modified}</Table.Td>
        <Table.Td>{tableDataLoading ? <TableLoader /> : doc.name}</Table.Td>
        {/* <Table.Td>
          {tableDataLoading ? (
            <TableLoader />
          ) : (
            <Text size="sm" color={doc.word_count ? 'green' : 'yellow'}>
              {doc.word_count ? doc.word_count : '-'}
            </Text>
          )}
        </Table.Td> */}
        <Table.Td>
          {tableDataLoading ? (
            <TableLoader />
          ) : (
            <Badge color={doc.status === 'Completed' ? 'green' : 'yellow'}>
              <Text size='12px' fw={'bold'}>
                {doc.status ? doc.status : 'PENDING'}
              </Text>
            </Badge>
          )}
        </Table.Td>
        <Table.Td>
          {tableDataLoading ? (
            <TableLoader />
          ) : (
            <>
              {doc?.name && doc.name.endsWith('.txt') && (
                <>
                  <IconEye
                    style={{ cursor: 'pointer' }}
                    onClick={() => viewRenderedRawText(doc.name || '')}
                  />
                  &nbsp;
                </>
              )}
              <IconEdit
                style={{ cursor: 'pointer' }}
                onClick={() => updateFile(doc.name || '')}
              />
              &nbsp;
              <IconTrash
                style={{ cursor: 'pointer' }}
                onClick={() => deleteFileWarning(doc.name || '')}
                color="red"
              />
            </>
          )}
        </Table.Td>
      </Table.Tr>

      {expandedRows[index] && (
  <Table.Tr style={{ backgroundColor: '#f1f1f1'}}>
    <Table.Td colSpan={5} style={{ padding: '20px' }}>
      {/* <div>
        <Title order={4}>Details:</Title>
      </div> */}
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* <Text><span style={{ fontWeight: 'bold'}}>status:</span> {doc.status}</Text> */}
        <Text><span style={{ fontWeight: 'bold'}}>Word count:</span> {doc.word_count}</Text>
        {/* <Text><span style={{ fontWeight: 'bold'}}>Last modified:</span> {doc.last_modified}</Text> */}
        <Text><span style={{ fontWeight: 'bold'}}>Source:</span> {doc.source}</Text>
        <Text><span style={{ fontWeight: 'bold'}}>Original file name:</span> {doc.original_file_name}</Text>

      </div>
    </Table.Td>
  </Table.Tr>
)}

    </React.Fragment>
  ))


  const handleDocUpdate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const document = e.target.files && e.target.files[0]
    if (document) {
      setDocUpdate(document)
    }
  }

  function loadTableKbData() {
    setTableDataLoading(true)
    setKBlistData([])
    getKbList()
      .then((data) => {
        const list: Doc[] = data.map((doc: KbFile) => ({
          last_modified: doc.last_modified,
          name: doc.file_name,
          status: doc.status,
          word_count: doc.word_count,
          source: doc.source,
          original_file_name: doc.original_file_name
        }))
        setKBlistData(list)
        // setKBlistData((prevData) => [...prevData, ...list])
      })
      .finally(() => setTableDataLoading(false))
  }

  


    ///update existing document
    const updateDocument = async () => {
        if (!docUpdate) {
          notification.info({
            message: '',
            description: 'Select file to update',
          })
          return
        }
        if (docUpdate.name !== fileToUpdate) {
          notification.info({
            message: '',
            description:
              'File name does not match. Make sure you are updating the same file',
          })
          return
        }
        const formData = new FormData()
        formData.append('file', `${docUpdate}`)
        setUpdating(true)
        updateKbList({ file_name: fileToUpdate }).then((res) => {
          if (res.error) {
            setDocUpdate(null) // Clear file input
            setUpdating(false)
            close()
            notification.error({
              message: '',
              description: res.message,
            })
            return
          }
          uploadFileToS3UsingPreSignedUr(res.message, docUpdate)
            .then(() => {
              loadTableKbData()
              setDocUpdate(null) // Clear file input
              setUpdating(false)
              close()
              setTimeout(() => {}, 2000)
            })
            .catch((error) => {
              notification.error({
                message: '',
                description: error,
              })
            })
        })
      }

      useEffect(() => {
        loadTableKbData()
      }, [refreshTableData])
    

  return (
    <>
      <Modal
        zIndex="99999"
        opened={opened}
        size={'lg'}
        centered
        onClose={close}
        title={
          <Title order={5} style={{ color: '#000' }}>
            Update Knowledge Base{' '}
          </Title>
        }
      >
        <div className="fileUploadSection" style={{ width: '100%' }}>
          <label htmlFor="docUpdate" className="fileUploaderContainer">
            <IconFile size={40} style={{ marginRight: '20px' }} />
            <label>
              {!docUpdate
                ? 'Click to upload document for update'
                : docUpdate.name}
            </label>
            <input
              id="docUpdate"
              type="file"
              // accept=".pdf,.csv,.docx"
              accept=".pdf,.docx"
              className="fileUploader"
              placeholder="Click to attach File"
              onChange={handleDocUpdate}
            />
            <div>
              <small style={{ marginTop: '10px', fontWeight: 'bold' }}>
                *PDF DOCx
              </small>
            </div>
          </label>
        </div>
        <Button
          disabled={updating}
          onClick={updateDocument}
          className="btn"
          mt={30}
          variant="filled"
          color={primary_color}
        >
          {updating ? (
            <Spinner />
          ) : (
            <>
              {' '}
              <IconUpload />
              &nbsp; Update{' '}
            </>
          )}
        </Button>
      </Modal>


     <WarningModal confirmButtonText='Delete'
        loadDeleting={isDeleting}
        onCancel={() => {
          setWarningModal(!warningModal)
        }}
        onConfirm={() => {
          deleteFile()
        }}
        title={
          <Title order={5} style={{ color: '#000' }}>
            Warning
          </Title>
        }
        content={`Are you sure you want to delete ${fileToDelete}?`}
        openModal={warningModal}
        closeModal={() => {
          setWarningModal(!warningModal)
        }}
      />
    
      <Modal
        zIndex={9999}
        opened={textModalOpened}
        size={'lg'}
        centered
        onClose={() => setTextModalOpened(false)}
        title={
          <Title order={5} style={{ color: '#000' }}>
            Raw Text
          </Title>
        }
      >
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
         {loadingText ? <>
            <Skeleton height={18} radius="sm" />
        <Skeleton height={18} mt={10} radius="sm" />
        <Skeleton height={18} mt={10} width="70%" radius="sm" />
         </>: <Text>{rawText}</Text>} 
        </div>
        <Button
          mt={20}
          variant="filled"
          color={primary_color}
          onClick={() => setTextModalOpened(false)}
        >
          Close
        </Button>
      </Modal>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            
            <Title order={3}>Current knowledge base</Title>
            <div onClick={()=> loadTableKbData()} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
            <Text mr={10}>Refresh</Text>
            {tableDataLoading ? <Loader size={20} color='#000' style={{ marginRight: 30}} /> :  <IconRefresh style={{ marginRight: 30}} />}
            </div>
            
            </div>

      <Table mt={20} striped highlightOnHover>
        <Table.Thead style={{ backgroundColor: primary_color, color: '#fff' }}>
          <Table.Tr>
            <Table.Th></Table.Th> {/* Placeholder for the icon column */}
            <Table.Th>Last Modified</Table.Th>
            <Table.Th>KB Name</Table.Th>
            {/* <Table.Th>Word Count</Table.Th> */}
            <Table.Th>Status</Table.Th>
            <Table.Th>Action</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{DocData}</Table.Tbody>
      </Table>
    </>
  )
}

export default CollapsibleRowTable
