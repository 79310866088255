import { Title, Group, Text, Accordion, Button, Skeleton } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { notification } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import WarningModal from 'components/utils/modal'
import React, { useEffect, useState } from 'react'
import {  updateUnAnswered_Q, getUnAnswered_Q, deleteUnanswered_Q } from 'services'
import { primary_color } from 'styles/colors'
import { unansweredQ } from 'types/training'


export default function UnAnsweredQ() {
  const [questions, setQuestions] = useState<unansweredQ>()
  const [isLoading, setIsLoading] = useState(false)
  const  [answer, setAnswer] = useState('')
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deleteWarningModal, setDeleteWarningModal]= useState(false)
  const [questionToDelete, setQuestionToDelete] = useState({
    session_id: '',
    chat_id: ''
  })

  const getQuestions = async () => {
    setIsLoading(true)
    try {
      const data = await getUnAnswered_Q()
      if (data) {
        setQuestions(data)
      } 
    setIsLoading(false)
    } catch (err) {
    setIsLoading(false)
    }
  }

  const saveAnswer = async (inquiry: string, session_id: string, chat_id: string) => {
    if(answer === ''){
      notification.error({
        message: 'Empty field',
        description: 'Answer field can not be blank'
      })
      return
    }
    setSaving(true)
    try{
     const response = await updateUnAnswered_Q({inquiry: inquiry, kb: answer, session_id: session_id, chat_id: chat_id})
     notification.info({
      message: response
     })
    setSaving(false)
    getQuestions()
    }catch(err){
      setSaving(false)
      return err
    }
  }

  const openWarningModal = (session_id: string, chat_id: string)=> {
    setDeleteWarningModal(!deleteWarningModal)
    setQuestionToDelete(prevState => ({
      ...prevState,
      session_id: session_id,
      chat_id: chat_id
    }))
  }

  const deleteQuestion = async () => {
    setDeleting(true)
    try{
       const response = await deleteUnanswered_Q(questionToDelete)
       notification.info({
        message: response
       })
    setDeleting(false)
    setDeleteWarningModal(false)
    getQuestions()
    }catch(err){
    setDeleting(false)
    setDeleteWarningModal(false)
        return err
     }
  }

 
  useEffect(() => {
    getQuestions()
  }, [])

  return (
    <div>
      <WarningModal confirmButtonText='Delete'
        loadDeleting={deleting}
        onCancel={() => {
          setDeleteWarningModal(!deleteWarningModal)
        }}
        onConfirm={() => deleteQuestion()
        }
        title={<Title order={5} style={{ color: '#000'}}>Delete Question</Title>}
        content="Are you sure you want to delete this question?"
        openModal={deleteWarningModal}
        closeModal={() => {
          setDeleteWarningModal(!deleteWarningModal)
        }}
      />
      <Title order={3}>Unanswered Questions</Title>
      {isLoading ? <Skeleton h={40} mt={10}/> :  <Accordion mt={10} chevronPosition="right" variant="contained">
        {questions?.chat_history.map((item) => (
          <Accordion.Item bg={'#fff'} key={item.chat_id} value={item.chat_id} mb={5}>
            <Accordion.Control>
              <Group>
                <Text>{item.inquiry}</Text>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <TextArea value={answer}
               onChange={(event) => setAnswer(event.currentTarget.value)} style={{ marginTop: 20 }} placeholder="Provide answer" />
              <div style={{ display: 'flex', alignItems: 'center'}}>
              <Button onClick={() => saveAnswer(item.inquiry, item.session_id, item.chat_id, )} mt={10} color={primary_color}> {saving ? 'Please wait...' : 'Save answer'}</Button>
              <Button onClick={()=> openWarningModal(item.session_id, item.chat_id)} mt={10} ml={10} color='#000'> <IconTrash style={{ marginRight: 5, color: 'red'}}/> Delete question </Button>
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>}
     
    </div>
  )
}
