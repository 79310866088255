import React, { useState } from 'react'
import { Button, Loader } from '@mantine/core'
import { IconUpload, IconVideo } from '@tabler/icons-react'
import { notification } from 'antd'
import {
  uploadKnowledgeBaseVideoMaterial,
  uploadFileToS3UsingPreSignedUr
} from 'services'
import { primary_color } from 'styles/colors'
import { truncateText } from 'utils/trauncateText'

const UploadVideo: React.FC = () => {
  const [video, setVideo] = useState<File | null>(null)
  // const [formattedFileSize, setFormattedFileSize] = useState<string>('')
  const [uploading, setUploading] = useState(false)

  // Handle video file selection
  const handleVideoUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedVideo = e.target.files && e.target.files[0]
    if (selectedVideo) {
      const fileExtension = selectedVideo.name.split('.').pop()?.toLowerCase()

      // Validate video file extension
      const validExtensions = ['mp4', 'mov', 'avi', 'webm', 'mkv', 'flv', 'wmv']
      if (!fileExtension || !validExtensions.includes(fileExtension)) {
        notification.error({
          message: 'Invalid File',
          description: `Unsupported file type: ${fileExtension}. Please upload a video file with one of the following extensions: ${validExtensions.join(
            ', '
          )}.`,
        })
        return
      }

      setVideo(selectedVideo)

      // // Calculate file size
      // const sizeInKB = selectedVideo.size / 1024
      // const sizeInMB = sizeInKB / 1024

      // if (sizeInMB >= 1) {
      //   setFormattedFileSize(sizeInMB.toFixed(2) + ' MB')
      // } else {
      //   setFormattedFileSize(sizeInKB.toFixed(2) + ' KB')
      // }
    }
  }

  // Upload the selected video
  const uploadVideo = async () => {
    if (!video) {
      notification.info({
        message: 'No Video Selected',
        description: 'Please select a video file to upload.',
      })
      return
    }

    setUploading(true)
    try {
      const res = await uploadKnowledgeBaseVideoMaterial({file_name: video.name})
      if (res.error) {
        notification.error({
          message: 'Upload Failed',
          description: res.message,
        })
        return
      }

      await uploadFileToS3UsingPreSignedUr(res.message, video)
      notification.success({
        message: 'Upload Successful',
        description: 'Your video has been uploaded successfully. Please check back later',
      })
      setVideo(null) 
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error deleting file:', error)
        notification.error({
          message: 'Upload Failed',
          description: error.message,
        })
      } else {
        notification.error({
          message: 'Upload Failed',
          description: 'There was an error uploading your video, please try again later.',
        })
      }

    } finally {
      setUploading(false)
    }
  }

  return (
    <div>
      <div className="" style={{ marginBottom: '20px' }}>
        <label htmlFor="video" className="fileUploaderContainer">
          <IconVideo size={40} style={{ marginRight: '20px' }} />
          <label>{!video ? 'Click to select video' : truncateText(video.name)}</label>
          <input
            id="video"
            type="file"
            accept=".mp4,.mov,.avi,.webm,.mkv,.flv,.wmv"
            className="fileUploader"
            placeholder="Click to attach video"
            onChange={handleVideoUpload}
          />
          <div>
            <small style={{ marginTop: '10px', fontWeight: 'bold' }}>
              *MP4, MOV, AVI, WEBM, MKV, FLV, WMV
            </small>
          </div>
        </label>
      </div>
{/* 
      <div className="">
        <Alert
          variant="light"
          color={primary_color}
          style={{ width: '100%' }}
          title="Video Details"
          icon={<IconFile size={rem(18)} />}
        >
          {!video ? (
            <p style={{ display: 'flex' }}>
              <IconFileOff color="#8c8b8b" />
              &nbsp; No Video Uploaded
            </p>
          ) : (
            <>
              <p>
                <Title order={6}>File Name:</Title> {video?.name}
              </p>
              <p>
                <Title order={6}>File Size:</Title> {formattedFileSize}
              </p>
              <p>
                <Title order={6}>File Type:</Title> {video?.type}
              </p>
            </>
          )}
        </Alert>
      </div> */}

      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
      <Button
        disabled={uploading}
        onClick={uploadVideo}
        className="btn"
        variant="filled"
        color={uploading ? '#dedede' : primary_color}
        leftSection={  <IconUpload size={18} />}
      >
        {uploading ? <Loader size={18} color='#000' /> : 'Upload Video'}
      </Button>
      </div>
    </div>
  )
}

export default UploadVideo
