import { domain, resources } from 'config'
import { createSecretsAxiosInstance } from 'hooks'
import forge from 'node-forge'
import { jiraDataProps, JiraDataPropsWithSplitToken, publicKeyRes, salesforcePayload, slackDataProps, whatsappDataProps, WhatsappDataPropsWithSplitToken } from 'types/3p-type'

const secretInstance = createSecretsAxiosInstance()


async function getSecrets():Promise<string | null> {
  try {
    const path = `${domain.get_secrets}${resources.secrets}`
    const response = await secretInstance.get<publicKeyRes>(path)
    return response.data.pub_key
  } catch (error) {
    console.error(error)
    return null
  }
}

function encryptValueWithPublicKey(publicKeyPem: string, value: string): string {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem)
  const encryptedData = publicKey.encrypt(value, 'RSA-OAEP', {
    md: forge.md.sha256.create()
  })
  return forge.util.encode64(encryptedData)
}

function encryptDataWithPublicKey(publicKeyPem: string, data: object): object {
  const encryptedData: { [key: string]: string } = {}
  for (const [key, value] of Object.entries(data)) {
    encryptedData[key] = encryptValueWithPublicKey(publicKeyPem, String(value))
  }
  console.log(encryptedData)
  return encryptedData
}

async function storeJiraSecrets(jiraPayload: jiraDataProps) {
  try {
    const publicKey = await getSecrets()
    if (publicKey !== null) {
      // Split the JiraAPIToken into two parts
      const token = jiraPayload.JiraAPIToken
      const tokenPart1 = token.substring(0, Math.ceil(token.length / 2))
      const tokenPart2 = token.substring(Math.ceil(token.length / 2))

      // Create a new payload with the split token parts and without the original token
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { JiraAPIToken, ...rest } = jiraPayload
      const payloadWithSplitToken: JiraDataPropsWithSplitToken = {
        ...rest,
        JiraAPITokenPart1: tokenPart1,
        JiraAPITokenPart2: tokenPart2,
      }

      // Encrypt the new payload
      const encryptedPayload = encryptDataWithPublicKey(publicKey, payloadWithSplitToken)
      const path = `${domain.store_secrets}${resources.jira}`
      const response = await secretInstance.post(path, encryptedPayload)
      return response.data
    } else {
      throw Error('Sorry we ran into issues, we are looking into this')
    }
  } catch (error) {
    console.error(error)
    return error
  }
}
async function storeQWhatsAppSecrets(whatsappPayload: whatsappDataProps) {
  try {
    const publicKey = await getSecrets()
    if (publicKey !== null) {
      // Split the JiraAPIToken into two parts
      const token = whatsappPayload.access_token
      const access_token_part_1 = token.substring(0, Math.ceil(token.length / 2))
      const access_token_part_2 = token.substring(Math.ceil(token.length / 2))

      // Create a new payload with the split token parts and without the original token
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { access_token, ...rest } = whatsappPayload
      const payloadWithSplitToken: WhatsappDataPropsWithSplitToken = {
        ...rest,
        access_token_part_1,
        access_token_part_2
      }

      // Encrypt the new payload
      const encryptedPayload = encryptDataWithPublicKey(publicKey, payloadWithSplitToken)
      const path = `${domain.store_secrets}${resources.whatsapp}`
      const response = await secretInstance.post(path, encryptedPayload)
      return response.data
    } else {
      throw Error('Sorry we ran into issues, we are looking into this')
    }
  } catch (error) {
    console.error(error)
    return error
  }
}


async function storeSlackSecrets(slackPayload: slackDataProps) {
  try {
    const publicKey = await getSecrets()
    if (publicKey !== null) {
      const encryptedPayload = encryptDataWithPublicKey(publicKey, slackPayload)
      const path = `${domain.store_secrets}${resources.slack}`
      const response = await secretInstance.post(path, encryptedPayload)
      return response.data
    } else {
      throw Error('Sorry we ran into issues, we are looking into this')
    }
  } catch (error) {
    console.error(error)
    return error
  }
}

async function storeSalesforceSecrets(salesforcePayload: salesforcePayload) {
  try {
    const publicKey = await getSecrets()
    if (publicKey !== null) {
      const encryptedPayload = encryptDataWithPublicKey(publicKey, salesforcePayload)
      const path = `${domain.store_secrets}${resources.salesforce}`
      const response = await secretInstance.post(path, encryptedPayload)
      return response.data
    } else {
      throw Error('Sorry we ran into issues, we are looking into this')
    }
  } catch (error) {
    console.error(error)
    return error
  }
}

async function storeQSlackSecrets(qSlackPayload: {slack_token: string, signing_secret: string, api_app_id: string}): Promise<string> {
  try {
    const publicKey = await getSecrets()
    if (publicKey !== null) {
      const encryptedPayload = encryptDataWithPublicKey(publicKey, qSlackPayload)
      const path = `${domain.store_secrets}${resources.qSlacker}`
      const response = await secretInstance.post(path, encryptedPayload)
      return response.data.message
    } else {
      throw Error('Sorry we ran into issues, we are looking into this')
    }
  } catch (error) {
    if (error instanceof Error) {
      return error.message 
    }else{
      return 'Error saving slack app credentials, please try again later.'
    }
  }
}

async function storeQTeamsSecrets(qTeamsPayload: {appId: string, appPassword: string}) {
  try {
    const publicKey = await getSecrets()
    if (publicKey !== null) {
      const encryptedPayload = encryptDataWithPublicKey(publicKey, qTeamsPayload)
      const path = `${domain.store_secrets}${resources.qTeams}`
      const response = await secretInstance.post(path, encryptedPayload)
      return response.data.message
    } else {
      throw Error('Sorry we ran into issues, we are looking into this')
    }
  } catch (error) {
    if (error instanceof Error) {
      return error.message 
    }else{
      return 'Error saving teams incoming hook information, please try again later.'
    }
  }
}

export {
    getSecrets,
    storeJiraSecrets,
    storeSlackSecrets,
    storeSalesforceSecrets,
    storeQSlackSecrets,
    storeQTeamsSecrets,
    storeQWhatsAppSecrets
}
