import React, { useState } from 'react'
import {
  Title,
  Button,
  Alert,
  Text,
  Paper,
  Card,
} from '@mantine/core'
import {
  IconAlertCircle,
  IconCheck,
  IconFile,
  IconScanEye,
  IconUpload
} from '@tabler/icons-react'
import '../../../styles/training.css'
// import { KbFile } from 'types'

import Notify from '../../utils/Notification'
import UploadVideo from './UploadVideo'
import {
  uploadKnowledgeBaseMaterial,
  uploadFileToS3UsingPreSignedUr,
  scanWebsite,
  // getKbList,
} from 'services'
import { Select, notification } from 'antd'
import { primary_color } from 'styles/colors'
import { truncateText } from 'utils/trauncateText'
import CollapsibleRowTable from 'components/utils/TrainingTable'

export default function Training() {

  // interface Doc {
  //   last_modified?: string
  //   word_count?: string;
  //   id?: number;
  //   name?: string;
  //   status?: string;
  //   original_file_name?: string
  //   source?: string
  // }
  const [doc, setDoc] = useState<File | null>(null)
  // const [formattedFileSize, setFormattedFileSize] = useState<string>('')
  const [uploading, setUploading] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [tableDataRefresh, setTableDataRefresh] = useState(false)
  

  ///upload new documen
  const uploadDocument = async () => {
    if (!doc) {
      notification.info({
        message: '',
        description: 'Select file to upload',
      })
      return
    }
    // if (currentUploadedFiles.includes(doc.name)) {
    //   notification.info({
    //     message: '',
    //     description: 'File already exist. Upload a new file',
    //   })
    //   return
    // }
    const formData = new FormData()
    formData.append('file', `${doc}`)
    setUploading(true)
    setShowNotification(true)
 
    const res = await uploadKnowledgeBaseMaterial({
      file_name: doc.name,
    })
    if (res.error) {
      setShowNotification(false)
      setUploading(false)
      setDoc(null) // Clear file input

      notification.error({
        message: '',
        description: res.message,
      })
      return
    }
    await uploadFileToS3UsingPreSignedUr(res.message, doc)
      .then(() => {
        setTableDataRefresh(true)
        setUploading(false)
        setDoc(null) // Clear file input
        setTimeout(() => {
          setShowNotification(false)
          setTableDataRefresh(false)
        }, 2000)
      })
      .catch(() => {
        setUploading(false)
        setShowNotification(false)
      })
  }

  const handleDocUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const document = e.target.files && e.target.files[0]
    if (document) {
      setDoc(document)
      // const fileInfo: docInfoType = {
      //   name: document.name,
      //   size: document.size,
      //   type: document.type,
      // }

      // const sizeInKB: number = fileInfo.size / 1024
      // const sizeInMB: number = sizeInKB / 1024

      // if (sizeInMB >= 1) {
      //   setFormattedFileSize(sizeInMB.toFixed(2) + ' MB')
      // } else {
      //   setFormattedFileSize(sizeInKB.toFixed(2) + ' KB')
      // }
    }
  }
 
  const { Option } = Select
  const [scanning, setScanning] = useState(false)
  const [url, setUrl] = useState('')
  const [scope, setScope] = useState<'full' | 'single' | 'nested'>('full')

  async function ScrapeWebsite(url: string, scope: string) {
    if (url === '') {
      notification.error({
        message: '',
        description: 'Provide url',
      })
      return
    }
    setScanning(true)
    try {
      const response = await scanWebsite(url, scope)
      setScanning(false)
      notification.info({
        message: '',
        description: response,
      })
      //  return response
    } catch (err) {
      setScanning(false)
      if (err instanceof Error) {
        notification.error({
          message: '',
          description: err.message,
        })
      } else {
        notification.error({
          message: '',
          description: 'Error scraping the url',
        })
      }

      //  return err
    }
  }
  const scopeChange = (value: 'full' | 'single' | 'nested') => {
    setScope(value)
  }


  return (
    <>
     

      <Notify
        icon={!uploading ? <IconCheck color="white" /> : <> </>}
        loading={uploading}
        visible={showNotification}
        title="Uploading Document"
        content="Sit back and relax while we upload your document."
      />

      <div className="mainContainer">
        <Title order={3}>Knowledge Base (KB)</Title>
        <Alert
              mb={30}
              mt={5}
              icon={<IconAlertCircle size={16} />}
              color={primary_color}
            >
              Upload knowledge base to help train bibot to give responses that align
              with your preferences. By providing relevant information, you
              enable bibot to better assist you and your clients with accurate
              and helpful responses and shape bibot&apos;s understanding to
              ensure it provides the most accurate and helpful responses based
              on the information you value most. Limits apply based on your
              subscription plan.
              <br />
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                *Avoid uploading scanned documents or documents with tables
              </span>
            </Alert>

            <div className='kbUploadCards' style={{ marginTop: 20, display: 'flex', justifyContent: 'space-around',  width: '100%'}}>
            <Card w={'100%'} p={20} mr={5} ml={5} mb={10}>
            <Text style={{ fontSize: '14px'}}>
              Scan your website content to generate answers to your customer
              questions
            </Text>
            <div
              style={{
                // backgroundColor: '#fff',
                border: '1px solid #dedede',
                borderRadius: 6,
                height: 45,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                marginBlock: 10,
              }}
            >
              <input
                style={{
                  border: 'none',
                  height: 40,
                  paddingLeft: 10,
                  width: '100%',
                }}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter URL address"
              />
              <Select
                defaultValue="full"
                style={{
                  width: 100,
                  border: 'none',
                  boxShadow: 'none',
                  height: 42,
                }}
                onChange={scopeChange}
              >
                <Option value="full">Full</Option>
                <Option value="nested">Nested</Option>
                <Option value="single">Single</Option>
              </Select>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              disabled={scanning}
              onClick={() => ScrapeWebsite(url, scope)}
              variant="filled"
              color={primary_color}
              mt={20}
              leftSection={<IconScanEye />}
            >
              {' '}
              {scanning ? 'Scanning...' : 'Scan'}
            </Button>
              </div>
                 </Card>

                 <Card w={'100%'} p={20} mr={5} ml={5} mb={10}>
                  <div>
                  <label htmlFor="doc" className="fileUploaderContainer">
                <IconFile size={40} style={{ marginRight: '20px' }} />
                <label >{!doc ? 'Click to select document' : truncateText(doc.name)}</label>
                <input
                  id="doc"
                  type="file"
                  // accept=".pdf,.csv,.docx"
                  accept=".pdf,.docx"
                  className="fileUploader"
                  placeholder="Click to attach File"
                  onChange={handleDocUpload}
                />
                <div>
                  <small style={{ marginTop: '10px', fontWeight: 'bold' }}>
                    *PDF DOCx
                  </small>
                </div>
              </label>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                  <Button
            disabled={uploading}
            onClick={uploadDocument}
            className="btn"
            mt={30}
            variant="filled"
            color={uploading ? '#dedede' : primary_color}
            leftSection={  <IconUpload size={18} />}
          >
              Upload file
              </Button>
                  </div>
                 </Card>

                 <Card w={'100%'} p={20} mr={5} ml={5} mb={10}>
                 <UploadVideo /> 
                 </Card>
            </div>

    
        <div style={{ width: '100%'}}>
          <Paper p={25} className="mainContainer" style={{ marginTop: '10px' }}>
            <CollapsibleRowTable refreshTableData={tableDataRefresh}/>
          </Paper>
        </div>
      </div>
    </>
  )
}
