import {
  Authenticator,
  CheckboxField,
  TextField,
  SelectField,
  useAuthenticator,
  PhoneNumberField,
  Heading,
  useTheme,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import '../../../styles/RegisterPageStyle.css'
import { APP_DATA, ROUTES } from 'config'
import { UserContext } from 'context'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { primary_color } from 'styles/colors'
import loginBot from '../../../assets/registerPage/loginBot.svg'
import { Text, Title } from '@mantine/core'
import Loader from './Loader'
interface RegisterProps {
  initiatePage: 'signIn' | 'signUp' | 'forgotPassword' | undefined;
}
const RegisterPage: React.FC<RegisterProps> = ({ initiatePage }) => {
  // const [dialCode, setDialCode] = useState('+1')
  // const [phoneNumber, setPhoneNumber] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const navigate = useNavigate()
  const { state } = useContext(UserContext)

  useEffect(() => {
    if (state.user?.email_verified) {
      navigate(ROUTES.HOME)  
    }
  }, [state])
  
  const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
  const formFields = {
    signIn: {
      username: {
        label: 'Email',
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      username: {
        label: 'Email',
        placeholder: 'Enter your email',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: APP_DATA.APP_NAME,
      },
    },
  }
  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
       <div className='loginParennt'>
       <div className='loginSplitA' style={{backgroundColor: primary_color}}>
       <img src={loginBot} width={'60%'}/>
       <Title order={3} style={{ color: '#fff', fontSize: 38, fontWeight: 'bold', marginTop: 60}}>
        Bibot Admin Portal
       </Title>
       <Text style={{color: '#fff', textAlign: 'center'}} mt={20}>Easily Manage You Chatbot For A Better Customer  <br/>
       Delivery. Enjoy The Experience!!
       </Text>
       </div>

         <div className='loginSplitB'>
          <div
          className="page-background"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            textAlign: 'left',
          }}
        >
          <a href={ROUTES.HOME}>
            {/* <img src={logo} alt='' className='preAuditLogo-on-registerPage' /> */}
          </a>
          <Authenticator
            // Default to Sign Up screen
            formFields={formFields}
            initialState={initiatePage}
            // Customize `Authenticator.SignUp.FormFields`
            components={{
              SignUp: {
                FormFields() {
                  const { validationErrors } = useAuthenticator()
                  return (
                    <>
                      {/* Re-use default `Authenticator.SignUp.FormFields` */}
                      <Authenticator.SignUp.FormFields />
                      <div className="fieldContainer">
                        <TextField
                          name="given_name"
                          label="First Name"
                          required={true}
                          errorMessage={validationErrors.given_name as string}
                          hasError={!!validationErrors.given_name}
                        />
                        <TextField
                          name="family_name"
                          label="Last Name"
                          required={true}
                          errorMessage={validationErrors.family_name as string}
                          hasError={!!validationErrors.family_name}
                        />
                      </div>

                      <div className="fieldContainer">
                        <TextField
                          name="companyName"
                          label="Company Name"
                          required={true}
                          errorMessage={validationErrors.companyName as string}
                          hasError={!!validationErrors.companyName}
                        />
                        <SelectField
                          style={{ maxWidth: '200px' }}
                          name="role"
                          className="input-select-field"
                          label="Current Role"
                          errorMessage={validationErrors.role as string}
                          hasError={!!validationErrors.role}
                        >
                          <option value="">Select your role...</option>
                          {roles.map((role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          ))}
                        </SelectField>
                      </div>
                      <PhoneNumberField
                        defaultDialCode="+1"
                        name="phone_number"
                        label="Phone Number"
                        dialCodeName="country_code"
                        dialCodeLabel="Dial Code"
                        isRequired={true}
                        placeholder="234-567-8910"
                        errorMessage={validationErrors.phone_number as string}
                        hasError={!!validationErrors.phone_number}
                      />
                      <CheckboxField
                        name="acknowledgement"
                        value="yes"
                        label={
                          <span>
                            I agree with the{' '}
                            <a
                              href={`${process.env.PUBLIC_URL}/privacy.html`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms & Conditions
                            </a>
                          </span>
                        }
                        required={true}
                        errorMessage={
                          validationErrors.acknowledgement as string
                        }
                        hasError={!!validationErrors.acknowledgement}
                      />
                    </>
                  )
                },
              },
              SetupTotp: {
                Header() {
                  const { tokens } = useTheme()
                  return (
                    <Heading
                      padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                      level={3}
                    >
                      Setup You MFA Device
                    </Heading>
                  )
                },
              },
            }}
            services={{
              async validateCustomSignUp(formData) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const errors: any = {}

                // Validate acknowledgement for terms and conditions
                if (!formData.acknowledgement) {
                  errors.acknowledgement =
                    'You must agree to the Terms & Conditions'
                }

                // Validate the 'givenName' field for not being empty
                if (!formData.given_name) {
                  errors.given_name = 'First name is required'
                }

                // Validate the 'familyName' field for not being empty
                if (!formData.family_name) {
                  errors.family_name = 'Last name is required'
                }

                // Validate the 'companyName' field for not being empty
                if (!formData.companyName) {
                  errors.companyName = 'Company name is required'
                }
                // Validate the 'companyName' field for not being empty
                // if (!formData.country_code) {
                //   errors.country_code = 'Select a valid country code'
                // }
                // Validate the 'companyName' field for not being empty
                if (!formData.phone_number) {
                  errors.phone_number = 'Valid phone number is required'
                }

                // Validate the 'role' field for not being empty
                if (!formData.role) {
                  errors.role = 'You must select your current role'
                }

                if (!formData.username.match(emailPattern)) {
                  errors.username = 'Username is not a valid email'
                } else {
                  const domain = formData.username.split('@')[1]
                  if (
                    ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'aol.com', 'icloud.com', 'mail.com', 'zoho.com', 'yandex.com', 'protonmail.com' ].includes(domain.toLowerCase())
                  ) {
                    errors.username = 'Company email is required'
                  }
                }
                // Return all accumulated errors`
                return errors
              },
            }}
          ></Authenticator>
        </div>
          </div>
       </div>
      )}
    </>
  )
}

const roles = [
  'Business Analyst',
  'Chief Information Officer (CIO)',
  'Chief Information Security Officer (CISO)',
  'Chief Technology Officer (CTO)',
  'Cloud Engineer',
  'Cloud Security Architect',
  'Cloud Security Engineer',
  'Cloud Security Analyst',
  'Cybersecurity Engineer',
  'Data Analyst',
  'Data Scientist',
  'Database Administrator',
  'DevOps Engineer',
  'Ethical Hacker',
  'Forensic Analyst',
  'Incident Responder',
  'Information Security Analyst',
  'Information Security Officer',
  'Infrastructure Engineer',
  'IT Auditor',
  'IT Compliance Analyst',
  'IT Director',
  'IT Manager',
  'K8s Administrator',
  'Mobile Application Developer',
  'Network Administrator',
  'Other',
  'Penetration Tester',
  'Project Manager',
  'Risk Analyst',
  'Security Architect',
  'Security Consultant',
  'Security Engineer',
  'Security Operations Center (SOC) Analyst',
  'Site Reliability Engineer',
  'Software Engineer',
  'Systems Administrator',
  'Technical Support Specialist',
  'UX/UI Designer',
  'Web Developer',
]

export default RegisterPage
