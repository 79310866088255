import { domain, resources } from 'config'
import { createInferenceAxiosInstance } from 'hooks'
import { PreDefinedQuestions } from 'types'

const inferenceAxiosInstance = createInferenceAxiosInstance()

async function getPredefinedQuestions(parameters: {client_id: string}): Promise<PreDefinedQuestions[]|[]> {
  try {
    const path = `${domain.inference}${resources.predefinedQ}`
    const response = await inferenceAxiosInstance.get(path, { params: parameters })
    return response.data.predefined_questions
  } catch (error) {
    console.error('Error getting predefined questions:', error)
    return []
  }
}



// async function frequentkyAskedQ(): Promise<FAskedQProp[]> {
//   try {
//     // const path = `${domain.inference}${resources.predefinedQ}`
//     // const response = await inferenceAxiosInstance.get() ///Get frequentl asked questions
//     return frequesntlyAskedQData
//   } catch (error) {
//     console.error('Error getting predefined questions:', error)
//     return []
//   }
// }


export {
  getPredefinedQuestions,
}
