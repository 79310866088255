import { Paper, Title, Alert, Code } from '@mantine/core'
import React, { useContext, useState } from 'react'
import { UserContext } from 'context'
import { IconAlertCircle, IconCode, IconCopy, IconInfoCircle } from '@tabler/icons-react'
import { primary_color, secondary_color } from 'styles/colors'

// import { CodeHighlight } from '@mantine/code-highlight'

const exampleCode = `
import { BiBot } from 'bibot';

function App() {
  return (
    <div className="App">
        <RoutedComponents/>
        <BiBot clientId={'YOUR CLIENT ID HERE'} />
    </div>
  );
}

export default App;
`
export default function TypescriptIntegration() {
  const { state } = useContext(UserContext)
  const { user } = state
  const [copySuccess, setCopySuccess] = useState<string | null>(null)

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopySuccess(text)
        setTimeout(() => {
          setCopySuccess(null)
        }, 2000)
      })
      .catch(err => {
        console.error('Error copying text to clipboard:', err)
      })
  }

  return (
    <>
      <Paper
        withBorder
        p={20}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Title order={4} mb={20}>Integration Steps for React Typescript App</Title>
        <Alert mt={5} icon={<IconAlertCircle size={16} />} color={primary_color}>At the appropriate place, preferably at the App.tsx level, to ensure it is available on all your app pages.
        </Alert>
        {copySuccess && (
          <Alert mt={50} variant="light" color="green" title="Copied" icon={<IconInfoCircle />}>
            {`"${copySuccess}" has been copied successfully`}
          </Alert>
        )}
        <Paper
          bg={'#f8f8f8'}
          p={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 1:</strong> Run npm install</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Code style={{ fontSize: 14}} color={secondary_color} c="#000">npm install bibot</Code>
            <IconCopy style={{ cursor: 'pointer' }} onClick={() => copyText('npm install bibot')} />
          </Paper>
        </Paper>
        
        <Paper
          bg={'#f8f8f8'}
          p={10}
          mt={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 2:</strong> Import the BiBot</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Code style={{ fontSize: 14}} color={secondary_color} c="#000">import {'{ BiBot }'} from &apos;bibot&apos;</Code>
            <IconCopy style={{ cursor: 'pointer' }} onClick={() => copyText('import { BiBot } from \'bibot\'')} />
          </Paper>
        </Paper>
        
        <Paper
          bg={'#f8f8f8'}
          p={10}
          mt={10}
          style={{ width: '100%', color: '#000', display: 'flex', flexDirection: 'column', paddingInline: '30px', marginBottom: '10px' }}
        >
          <div><strong>Step 3:</strong> Implement the chat bubble with your clientId &quot;<strong>{user?.profile}</strong>&quot;</div>
          <Paper
            bg={secondary_color}
            p={10}
            mt={10}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Code style={{ fontSize: 14}} color={secondary_color} c="#000">{`<BiBot clientId={${user?.profile}} />`}</Code>
            <IconCopy style={{ cursor: 'pointer' }} onClick={() => copyText(`<BiBot clientId={'${user?.profile}'} />`)} />
          </Paper>
        </Paper>
        <Alert mt={5} icon={<IconCode size={16} />} color={primary_color}>
          Sample code:
          <Paper
            bg={'#191919'}
            p={10}
            mt={10}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Code block style={{ fontSize: 14}} color='#191919' c="#fff">{exampleCode}</Code>
          </Paper>
        </Alert>
        
      </Paper>
    </>
  )
}
