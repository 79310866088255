import { domain, resources } from 'config'
// import { chatData } from 'data/chathistory'
import { createTrainingAxiosInstance } from 'hooks'
import { delete_question, fileType, question } from 'types'
import { deleteUnansweredProp, unansweredQ, updateUnansweredProp } from 'types/training'

const trainingAxiosInstance = createTrainingAxiosInstance()

async function uploadKnowledgeBaseMaterial(data: {
  file_name: string;
}): Promise<{
  error: boolean;
  message: string;
}>{
  try {
    const path = `${domain.training}${resources.embed}`
    const response = await trainingAxiosInstance.post(path, data)
    return { error: false, message: response.data.pre_signed_url} 
  } catch (error) {
    if (error instanceof Error) {
      return { error: true, message: error.message}
    }else{
      return { error: true, message: 'Error uploading file'}
    }
  }
}

async function uploadKnowledgeBaseVideoMaterial(data: {
  file_name: string;
}): Promise<{
  error: boolean;
  message: string;
}>{
  try {
    const path = `${domain.training}${resources.transcribe}`
    const response = await trainingAxiosInstance.post(path, data)
    return { error: false, message: response.data.pre_signed_url} 
  } catch (error) {
    if (error instanceof Error) {
      return { error: true, message: error.message}
    }else{
      return { error: true, message: 'Error uploading file'}
    }
  }
}

async function uploadFileToS3UsingPreSignedUr(
  pre_signed_url: string,
  file: File
): Promise<string> {
  try {
    await fetch(pre_signed_url, {
      method: 'PUT',
      body: file,
    })
    return 'Successful uploaded knowledge base'
  } catch (err) {
    if (err instanceof Error) {
      return err.message
    }else{
      return'Error uploading file'
    }
  }
}

async function deleteKbList(parameters: {file_name: string}): Promise<string> {
  try {
    const path = `${domain.training}${resources.deleteKB}`
    const response = await trainingAxiosInstance.post(path, parameters )
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error deleting file:', error)
      return error.message
    } else {
      return 'Error deleting file'
    }
  }
}


async function scanWebsite(url: string, scope: string): Promise<string> {
  try {
    const path = `${domain.training}${resources.scrape}`
    const response = await trainingAxiosInstance.post(path, {
      url: url,
      scope: scope
    })
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
      return `${error.message}` 
    } else {
      return 'Error scanning your provided URL'
    }
  }
}


async function updateKbList(file_name: fileType): Promise<{
  error: boolean;
  message: string;
}> {
  try {
    const path = `${domain.training}${resources.updateKB}`
    const response = await trainingAxiosInstance.post(path, file_name )
    return { error: false, message: response.data.pre_signed_url} 
  } catch (error) {
    if (error instanceof Error) {
      return { error: true, message: error.message}
    }else{
      return { error: true, message: 'Error updating file'}
    }
  }
}


async function savePredefinedQuestions(questions: question) {
  try {
    const path = `${domain.training}${resources.predefinedQ}`
    const response = await trainingAxiosInstance.post(path, questions)
    return response.data
  } catch (error) {
    return error
  }
}
async function updatePredefinedQuestions(questions: question): Promise<string> {
  try {
    const path = `${domain.training}${resources.updatePredefinedQ}`
    const response = await trainingAxiosInstance.post(path, questions)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    } else {
      return 'Error updating predefined question'
    }
  }
}

async function deletePredefinedQuestions(question: delete_question): Promise<string> {
  try {
    const path = `${domain.training}${resources.deletePredefinedQ}`
    const response = await trainingAxiosInstance.post(path, question)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    } else {
      return 'Error deleting predefined question'
    }
  }
}


async function getUnAnswered_Q(): Promise<unansweredQ> {
  try {
    const path = `${domain.training}${resources.unanswered_q}`
    const response = await trainingAxiosInstance.get(path)
    return response.data
  } catch (error) {
    return {} as unansweredQ
  }
}


async function updateUnAnswered_Q(answer: updateUnansweredProp): Promise<string> {
  try {
    const path = `${domain.training}${resources.unanswered_q}`
    const response = await trainingAxiosInstance.post(path, answer)
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    } else {
      return 'Error updating unanswered question'
    }
  }
}


async function deleteUnanswered_Q({session_id, chat_id}: deleteUnansweredProp): Promise<string> {
  try {
    const path = `${domain.training}${resources.unanswered_q}`
    const response = await trainingAxiosInstance.delete(path, {
      data: {
        session_id,
        chat_id,
      },
    })
    return response.data.message
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    } else {
      return 'Error deleting unanswered question'
    }
  }
}

export {
  uploadKnowledgeBaseMaterial,
  uploadFileToS3UsingPreSignedUr,
  deleteKbList,
  savePredefinedQuestions,
  updatePredefinedQuestions, 
  deletePredefinedQuestions,
  updateKbList,
  scanWebsite,
  getUnAnswered_Q,
  updateUnAnswered_Q,
  deleteUnanswered_Q,
  uploadKnowledgeBaseVideoMaterial
}
