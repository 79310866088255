import {  Paper, Text } from '@mantine/core'
import  { useState }  from 'react'
import { primary_color } from 'styles/colors'

import TypescriptIntegration from './TypescriptIntegration'
import WordpressIntegration from './WordpressIntegration'
import { IconBrandTypescript, IconBrandWordpress, IconBrandTeams, IconBrandSlack, IconBrandWhatsapp } from '@tabler/icons-react'

import SlackAppIntegration from './SlackAppIntegration'
import TeamsBotFrameworkIntegration from './TeamsBotFrameworkIntegration'
import WhatsAppIntegration from './WhatsAppIntegration'
// Function to filter tabs based on the environment
const getTabs = () => {
  const allTabs = [
    { title: 'Typescript', icon: <IconBrandTypescript /> },
    { title: 'WordPress', icon: <IconBrandWordpress /> },
    { title: 'Microsoft Teams', icon: <IconBrandTeams /> },
    { title: 'Slack', icon: <IconBrandSlack /> },
    { title: 'WhatsApp', icon: <IconBrandWhatsapp /> }
  ]

  // Hide 'Microsoft Teams' in production or any other tab you want
  if (process.env.REACT_APP_ENV ==='prod') {
    return allTabs  //.filter(tab => tab.title !== 'WhatsApp')
  }
  
  // In development, return all tabs
  return allTabs
}
export default function AppIntegration() {
  const [currentTab, setCurrentTab]= useState('Typescript')

  const tabs = getTabs()

  const renderComponent = () => {
    switch (currentTab) {
      case 'Typescript':
        return <TypescriptIntegration />
      case 'WordPress':
        return <WordpressIntegration />
      case 'Microsoft Teams':
        return <TeamsBotFrameworkIntegration />
      case 'Slack':
        return <SlackAppIntegration />
      case 'WhatsApp':
        return <WhatsAppIntegration/>
      default:
        return <></>
    }
  }

  return (
   <>
        <Paper withBorder style={{ width: '100%', display: 'flex', height:'100%', alignItems: 'center', borderRadius: 8, justifyContent: 'space-between'}} pl={10} pr={10} bg={'transparent'} p={5} mt={1} mb={20}>
       <div style={{ display: 'flex'}}>
       {tabs.map((item, key)=> {
            return (
              <Text key={key} style={{ cursor: 'pointer', color: currentTab === item.title? primary_color : '#bababa', display: 'flex', alignItems: 'center' }} onClick={()=> setCurrentTab(`${item.title}`)} fw={'bold'} m={10}>{item.icon} &nbsp; {item.title}</Text>
            )
          })}
       </div>
        </Paper>

   {renderComponent()}
   </>
  )
}


